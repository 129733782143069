import { useState } from "react"
import { useParams } from "react-router-dom"
import { Badge, Button, Switch } from "@runners/ui"
import dayjs from "dayjs"
import DetailSection from "@/_components/detail-section"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Divider from "@/_components/layout/divider"
import useToast from "@/_hooks/use-toast"
import BusinessDetailInfo from "../_components/business-detail-info"
import BusinessFormInfo from "../_components/business-form-info"
import { useGetStoreQuery } from "../_hooks/use-get-query"
import { useActivateSellerMutation, useDeactivateSellerMutation } from "../_hooks/use-mutation"

export interface SellerDetailFormType {
  title: string
  owner_name: string
  business_number: string
  detail_address: string
  phone: string
  store_link: string
}

const AdminSellerDetailPage = () => {
  const { storeId: _storeId } = useParams()
  const storeId = Number(_storeId)
  const { data: storeDetail } = useGetStoreQuery({ storeId })

  const [isActive, setIsActive] = useState(true)

  const toast = useToast()
  const deactivateMutation = useActivateSellerMutation({
    onSuccess: () => {
      toast.success("비활성화 되었습니다.")
    },
  })
  const activateMutation = useDeactivateSellerMutation({
    onSuccess: () => {
      toast.success("활성화 되었습니다.")
    },
  })

  const handleSubmit = () => {
    if (!isActive) {
      deactivateMutation.mutate({ store_ids: [storeId] })
    } else {
      activateMutation.mutate({ store_ids: [storeId] })
    }
  }

  if (!storeDetail) return <></>

  return (
    <AdminPageLayout onUpdate={handleSubmit}>
      <BusinessFormInfo />

      <Divider />

      <BusinessDetailInfo />

      <Divider />

      <DetailSection
        title="상품 정보"
        values={{
          "등록한 상품": storeDetail?.product_count,
          "총 판매량": storeDetail?.product_sold_count,
        }}
      />

      <Divider />

      <DetailSection
        title="권한 설정"
        values={{
          "노출 여부": (
            <div className="flex w-full gap-4">
              <div className="flex min-w-[200px] items-center gap-4">
                <Switch size="24" checked={isActive} onChange={e => setIsActive(e.target.checked)} />
                <Badge color={isActive ? "primary" : "gray"}>{isActive ? "활성화" : "비활성화"}</Badge>
              </div>
              <Button size="40" className="px-6" type="button" color="primary" onClick={handleSubmit}>
                저장
              </Button>
            </div>
          ),
        }}
      />

      <Divider />

      <DetailSection
        title="생성 정보"
        values={{
          "등록한 상품": dayjs(storeDetail?.created).format("YYYY.MM.DD"),
        }}
      />

      <Divider />
    </AdminPageLayout>
  )
}

export default AdminSellerDetailPage
