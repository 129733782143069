import { useFormContext } from "react-hook-form"
import { Textarea } from "@runners/ui"
import AdminLabel from "../admin-label"

const AdminPostNotice = () => {
  const { register } = useFormContext()

  return (
    <div className="flex items-center gap-5">
      <AdminLabel>유의사항</AdminLabel>

      <div className="w-full">
        <Textarea rows={4} placeholder="값이 없으면 노출되지 않음" className="w-full" {...register("notice")} />
      </div>
    </div>
  )
}

export default AdminPostNotice
