import { useMutation } from "@tanstack/react-query"
import { MutationOptions } from "@/_api/common.type"
import productGroupApi from "@/_api/product-group"

export const usePostProductGroupMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productGroupApi.postProductGroup,
    ...options,
  })
}

export const usePatchProductGroupMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productGroupApi.patchProductGroup,
    ...options,
  })
}
