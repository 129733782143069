import dayjs from "dayjs"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { navigate } from "@/_constants/navigate"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetRecommendProductGroupListQuery } from "./_hooks/use-get-query"

const AdminRecommendProductPage = () => {
  const { Table } = usePaginationTable<any>()
  const {} = useTableSearch()

  const { data: recommendList, totalCount, isLoading } = useGetRecommendProductGroupListQuery()

  const dropdownList: TableDropdownList = []

  return (
    <AdminPageLayout totalCount={totalCount} create>
      {/* <SearchInput /> */}

      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={recommendList}
        tableDropdownList={dropdownList}
        title={["ID", "상태", "적용화면", "타이틀", "추천상품", "수정일"]}
        widths={[1, 1, 1, 2, 4, 2]}
        href={value => navigate.recommendProductDetail(value.id)}
        values={{
          ID: v => v.id,
          상태: TableActivateCurry(),
          적용화면: v => v.type,
          타이틀: v => (
            <div className="flex flex-col font-bold">
              <p className="" style={{ color: v.text_color }}>
                {v.tag}
              </p>
              <p className="">{v.title}</p>
            </div>
          ),
          추천상품: v => v.product_titles,
          수정일: v => dayjs(v.modified).format("YYYY.MM.DD"),
        }}
      />
    </AdminPageLayout>
  )
}

export default AdminRecommendProductPage
