import { useEffect, useState } from "react"
import { Button } from "@runners/ui"
import Divider from "@/_components/layout/divider"
import AdminPostContents from "@/_pages/service/post/_components/fields/admin-post-contents"
import AdminPostActive from "./fields/admin-post-active"
import AdminPostLabels from "./fields/admin-post-labels"
import AdminPostNotice from "./fields/admin-post-notice"
import AdminPostSubTitle from "./fields/admin-post-sub-title"
import AdminPostTags from "./fields/admin-post-tags"
import AdminPostThumbnail from "./fields/admin-post-thumbnail"
import AdminPostTitle from "./fields/admin-post-title"

const PostForm = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsReady(true), 200)
  }, [])

  return (
    <div className="max-w-[1200px]">
      <AdminPostThumbnail />
      <Divider />
      <div className="flex flex-col gap-5">
        {/* <AdminPostDuringDate /> */}
        <AdminPostTitle />
        <AdminPostSubTitle />
      </div>

      <Divider />

      {isReady && <AdminPostContents />}

      <Divider />
      <div className="flex flex-col gap-5">
        <AdminPostTags />
        <AdminPostNotice />
        <AdminPostLabels />
        <AdminPostActive />
      </div>
      <Divider />

      {/* <AdminPostFilter /> */}

      <div className="mt-10 flex items-center justify-end">
        <Button type="submit" size="48" className="flex-1">
          등록
        </Button>
      </div>
    </div>
  )
}

export default PostForm
