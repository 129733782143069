import addressBooksQueryKey from "@/_api/_query-key/address-books"
import emailQueryKey from "@/_api/_query-key/email"
import addressBooksApi from "@/_api/address-books"
import { AdminEmailAddressBokksGroupListParams } from "@/_api/address-books.type"
import { emailApi } from "@/_api/email"
import { AdminEmailSendParams } from "@/_api/email.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetEmailAddressBooksGroupList = (params: AdminEmailAddressBokksGroupListParams) =>
  useInfiniteData({
    queryKey: addressBooksQueryKey.addressBooksGroupList.all(),
    queryFn: ({ pageParam = 1 }) => addressBooksApi.getEmailAddressBooksGroupList({ ...params, page: pageParam }),
  })

export const useGetSendEmailList = (params: AdminEmailSendParams) =>
  useInfiniteData({
    queryKey: emailQueryKey.emailSendList.params(params),
    queryFn: () => emailApi.getSendEmailList(params),
  })
