import { useMutation } from "@tanstack/react-query"
import settlementApi from "@/_api/settlement"
import { MutationOptionsType } from "@/_types/react-query.type"

export const usePatchSettlementGroupMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: settlementApi.patchSettlementGroup,
    ...options,
  })
}
