import { useMemo } from "react"

// 센스 웹에서 사용하는 이미지 캐싱 width 사이즈
// const imageDeviceSizes = [24, 45, 128, 375, 512, 768, 1280, 2560]

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string
  /**
   * xs: 45
   * sm: 375
   * md: 768
   * lg: 1280
   * xl: 2560
   */
  size: "45" | "375" | "768" | "1280" | "2560"
}

const _getStringSrc = (src: any | string) => {
  if (!src) {
    console.warn("src가 없습니다.")
    return ""
  }

  if (typeof src === "string") return src

  console.warn("이미지는 타입이 string 이어야 합니다.")
  return src?.src
}

const SENSE_CLOUDFRONT_URL = import.meta.env.VITE_SENSE_CLOUDFRONT_URL ?? "엑스를 눌러 조의를 표합니다"

/**
 * cloud front 캐싱 이미지
 */
const Image = (props: ImageProps) => {
  const { size = "md", src = "", ...restProps } = props

  const resizable = src?.includes?.(SENSE_CLOUDFRONT_URL) || src?.includes?.("cf.")

  const srcQueryString: string = useMemo(() => {
    if (!resizable) return _getStringSrc(src)

    switch (size) {
      case "45":
        return _getStringSrc(src) + `?w=45`
      case "375":
        return _getStringSrc(src) + `?w=375`
      case "768":
        return _getStringSrc(src) + `?w=768`
      case "1280":
        return _getStringSrc(src) + `?w=1280`
      case "2560":
        return _getStringSrc(src) + `?w=2560`

      default:
        return _getStringSrc(src)
    }
  }, [size, src])

  return <img src={srcQueryString} {...restProps} />
}

export default Image
