"use client"

import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useClickOutside } from "@runners/hooks"
import { calcPosition } from "@runners/lib/calc-position"
import { cn } from "@/_styles/cn"
import { defaultOptions, useRelativePortalState } from "@/_zustand/admin-relative-portal"

interface RelativePortalProps {}

const PORTAL_ID = "portal"

/**
 * RelativeModal Provider
 * 스크롤 관리는 useScrollOverflowHiddenCalcPadding hook을 사용해서 제어해주세요
 */
const RelativePortal = (_props: RelativePortalProps) => {
  const ELEMENT = useMemo(() => globalThis?.document.getElementById(PORTAL_ID), [])
  const { targetRef, Component, componentProps, clear, options } = useRelativePortalState()

  /**
   * 페이지 이동시 force close
   */
  const { pathname } = window.location
  const pathRef = useRef(pathname)
  useEffect(() => {
    if (pathRef.current !== pathname) {
      clear()
    }
  }, [pathname, clear])

  useEffect(() => {
    if (!Component) return
    if (!ELEMENT) return

    // document.body 스크롤 막기
    const originalOverflow = document.body.style.overflow
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = originalOverflow
    }
  }, [ELEMENT, Component])

  /**
   * 애니메이션이 적용되는 close 함수
   */
  const [transitionOut, setTransitionOut] = useState(false)
  const close = useCallback(() => {
    setTransitionOut(true)
    setTimeout(() => {
      setTransitionOut(false)
      clear()
    }, 180) // 200ms 했을때 깜빡임이 생겨서 180으로 설정
  }, [clear])

  const parentRef = useRef<HTMLDivElement>(null)
  const sizeCalcRef = useRef<HTMLDivElement>(null)

  useClickOutside({ inRef: options?.clickOutsideContainTarget ? sizeCalcRef : parentRef, handler: close })

  useEffect(() => {
    if (!sizeCalcRef.current) return
    if (!targetRef) return

    const targetRect = targetRef.getBoundingClientRect()
    const popupRect = sizeCalcRef.current.getBoundingClientRect()

    if (targetRect.width === 0 || targetRect.height === 0) {
      clear()
      return
    }

    const position = options?.position || defaultOptions.position
    const targetGap = options?.gap || defaultOptions.gap

    const { top, left } = calcPosition(position, targetRect, targetGap, popupRect)
    sizeCalcRef.current.style.left = `-${left}px`
    sizeCalcRef.current.style.top = `${top}px`
  }, [targetRef, clear, options])

  if (!ELEMENT) return null
  if (!targetRef) return null
  if (!Component) return null

  const { top, left, width, height } = targetRef.getBoundingClientRect()

  return createPortal(
    <div ref={parentRef} className="fixed z-20" style={{ top, left, width, height }}>
      <div
        className={cn("absolute animate-fade-in-200", {
          "animate-fade-out-200": transitionOut,
        })}
        ref={sizeCalcRef}
      >
        <Component close={close} {...componentProps} />
      </div>
    </div>,
    ELEMENT,
  )
}

export default RelativePortal
