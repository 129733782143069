export const navigate = {
  home: () => "/",
  signIn: () => "/sign-in",

  /** ------------------------------------------------------------------------------
   * 
   * 서비스 관리
   * 
   ------------------------------------------------------------------------------ */
  /**
   * 회원 관리
   */
  user: () => "/service/user",
  userDetail: (id: string | number) => `/service/user/${id}`,

  /**
   * 이벤트 관리
   */
  event: () => "/service/event",
  // eventCreate: () => "/service/event/create",
  eventDetail: (id: string | number) => `/service/event/${id}`,

  /**
   * 커뮤니티 관리
   */
  community: () => "/service/community",
  communityDetail: (id: string | number) => `/service/community/${id}`,

  /**
   * 라벨 관리
   */
  label: () => "/service/label",
  labelCreate: () => "/service/label/create",
  labelDetail: (id: string | number) => `/service/label/${id}`,

  /**
   * 콘텐츠 관리
   */
  post: () => "/service/post",
  postCreate: () => "/service/post/create",
  postDetail: (id: string | number) => `/service/post/${id}`,

  /**
   * 배너 관리
   */
  banner: () => "/service/banner",
  bannerCreate: () => "/service/banner/create",
  bannerDetail: (id: string | number) => `/service/banner/${id}`,

  /**
   * 추천 관리
   */
  recommend: () => "/service/recommend",

  /**
   * 알림 관리
   */
  notification: () => "/service/notification",

  /** ------------------------------------------------------------------------------
   * 
   * 스토어 관리
   * 
   ------------------------------------------------------------------------------ */
  /**
   * 기획전 관리
   */
  campaign: () => "/store/campaign",
  campaignCreate: () => "/store/campaign/create",
  campaignDetail: (id: string | number) => `/store/campaign/${id}`,

  /**
   * 셀러 관리
   */
  seller: () => "/store/seller",
  sellerDetail: (id: string | number) => `/store/seller/${id}`,

  /**
   * 상품 관리
   */
  product: () => "/store/product",
  productCreate: () => "/store/product/create",
  productDetail: (id: string | number) => `/store/product/${id}`,

  /**
   * 상품그룹 관리
   */
  productGroup: () => "/store/p-group",
  productGroupCreate: () => "/store/p-group/create",
  productGroupDetail: (id: string | number) => `/store/p-group/${id}`,

  /**
   * 추천상품 관리
   */
  recommendProduct: () => "/store/recommend-p",
  recommendProductCreate: () => "/store/recommend-p/create",
  recommendProductDetail: (id: string | number) => `/store/recommend-p/${id}`,

  /**
   * 결제 관리
   */
  payment: () => "/store/payment",

  /**
   * 후기 관리
   */
  review: () => "/store/review",

  /**
   * 신고 관리
   */
  report: () => "/store/report",

  /** ------------------------------------------------------------------------------
   * 
   * 메일관리
   * 
   ------------------------------------------------------------------------------ */

  /**
   * 템플릿 관리
   */
  template: () => "/email/template",
  templateDetail: (id: string | number) => `/email/template/${id}`,

  /**
   * 이메일 발송 관리
   */
  send: () => "/email/send",

  /**
   * 주소록 관리
   */
  addressBook: () => "/email/address-book",
  addressBookDetail: (id: string | number) => `/email/address-book/${id}`,
}
