import { AdminPostListParams } from "../post.type"

const postQueryKey = {
  post: () => ["post"],

  adminPost: () => ["admin", "post"],
  postList: {
    all: () => [...postQueryKey.adminPost(), "list"],
    params: ({ ...params }: AdminPostListParams) => [...postQueryKey.postList.all(), { ...params }],
  },
  postDetail: ({ postId }: { postId: number }) => [...postQueryKey.adminPost(), postId],
}

export default postQueryKey
