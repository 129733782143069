import { toLocalePrice } from "@runners/lib/price"
import { AdminProductListDto } from "@/_api/product.type"
import Image from "@/_components/image"

interface ProductListItemProps {
  product: AdminProductListDto
  isSelected?: boolean
  onClick?: () => void
}

const ProductListItem = (props: ProductListItemProps) => {
  const { product, isSelected, onClick } = props
  return (
    <div className="flex flex-col gap-1">
      <div onClick={onClick} className="relative cursor-pointer">
        <input type="checkbox" className="absolute right-4 top-4" checked={isSelected} onChange={() => {}} />
        <Image size="1280" className="aspect-square w-[156px]" src={product.image_url ?? ""} />
      </div>

      <p className="m-0 line-clamp-2 truncate text-14 font-bold">{product.title}</p>
      <p className="m-0 text-12">{product.store_title}</p>
      <div className="h-2 w-full" />
      <p className="text-14 font-semibold">{toLocalePrice(product.origin_price, "원")}</p>
    </div>
  )
}

export default ProductListItem
