import { useMutation } from "@tanstack/react-query"
import addressBooksApi from "@/_api/address-books"

export const usePostAddressBokk = () =>
  useMutation({
    mutationFn: addressBooksApi.postEmailAddressBook,
  })

export const usePatchAddressBook = () =>
  useMutation({
    mutationFn: addressBooksApi.patchEmailAddressBook,
  })

export const useDeleteAddressBook = () =>
  useMutation({
    mutationFn: addressBooksApi.deleteEmailAddressBook,
  })
