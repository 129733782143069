import { useMutation } from "@tanstack/react-query"
import storeApi from "@/_api/store"
import { MutationOptionsType } from "@/_types/react-query.type"

export const useDeleteStoreMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: storeApi.deleteStore,
    ...options,
  })
}

export const useUpdateStoreMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: storeApi.patchStore,
    ...options,
  })
}

export const useActivateSellerMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: storeApi.postStoreActivate,
    ...options,
  })
}

export const useDeactivateSellerMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: storeApi.postStoreDeactivate,
    ...options,
  })
}
