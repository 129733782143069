import { AdminBannerListParams } from "../banner.type"

const bannerQueryKey = {
  banner: () => ["banner"],
  adminBanner: () => ["admin", "banner"],

  bannerList: (params: AdminBannerListParams) => [...bannerQueryKey.adminBanner(), "list", params],
  detail: ({ bannerId }: { bannerId: number }) => [...bannerQueryKey.adminBanner(), "detail", bannerId],
}

export default bannerQueryKey
