import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import postQueryKey from "@/_api/_query-key/post"
import { AdminPostListParams, PostListDto } from "@/_api/post.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetPostListQuery } from "./_hooks/use-get-query"
import { usePostActivateMutation, usePostDeactivateMutation } from "./_hooks/use-mutation"

const PostPage = () => {
  const { Table, page, pageSize } = usePaginationTable<PostListDto>()
  const { SearchInput, search } = useTableSearch()

  /**
   * 선택 활성화/비활성화
   */
  const queryClient = useQueryClient()
  const { mutate: activate } = usePostActivateMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: postQueryKey.postList.all() })
    },
  })
  const { mutate: deactivate } = usePostDeactivateMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: postQueryKey.postList.all() })
    },
  })

  const tableDropdownList: TableDropdownList = [
    {
      label: "선택 비활성화",
      fn: ids => {
        deactivate({ post_ids: ids })
      },
    },
    {
      label: "선택 활성화",
      fn: ids => {
        activate({ post_ids: ids })
      },
    },
  ]

  /**
   * 테이블 그리기 위한 data fetch
   */
  const params: AdminPostListParams = {
    page,
    page_size: pageSize,
    search,
  }

  const { data: postList, totalCount, isLoading } = useGetPostListQuery(params)

  return (
    <AdminPageLayout totalCount={totalCount} create>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 제목, 이메일, 내용"
        placeholder="검색어 입력"
      />
      <p className="text-14">
        현재 검색어: <span className="font-bold">{search}</span>
      </p>
      <Table
        isLoading={isLoading}
        totalCount={totalCount}
        data={postList}
        activeCheck
        href={value => {
          return `/service/post/${value?.id}`
        }}
        className="min-w-[1600px]"
        title={["ID", "상태", "이미지", "작성자", "제목", "조회수", "좋아요", "댓글", "생성일", "마지막 수정일"]}
        widths={[2, 2, 2, 3, 4, 2, 2, 2, 3, 3]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: TableActivateCurry(),
          이미지: value => {
            return (
              <div className="flex items-center justify-center">
                <Image size="375" className="size-10" src={value?.thumbnail_media_url} />
              </div>
            )
          },
          작성자: value => {
            return (
              <div>
                <p>{value?.user?.username}</p>
                <p className="text-12 text-gray-600">{value?.user?.email}</p>
              </div>
            )
          },
          제목: value => {
            return (
              <div>
                <p className="text-14">{value?.title}</p>
                <p className="text-12 text-gray-600">{value?.sub_title}</p>
              </div>
            )
          },
          조회수: value => {
            return value?.visit_count
          },
          좋아요: value => {
            return value?.like_count
          },
          댓글: value => {
            return value?.comment_count
          },
          생성일: value => {
            return dayjs(value?.created).format("YY.MM.DD")
          },
          "마지막 수정일": value => {
            return dayjs(value?.modified).format("YY.MM.DD")
          },
        }}
        tableDropdownList={tableDropdownList}
      />

      <div className="h-10" />
    </AdminPageLayout>
  )
}

export default PostPage
