import api, { METHOD } from "."
import { PatchRecommendProductGroupPayload, PostRecommendProductGroupPayload } from "./recommend-product.type"

const recommendProductApi = {
  getRecommendProductGroup: async ({ recommendProductId }: { recommendProductId: number }) => {
    const { data } = await api(`/admin/recommend-product-group/${recommendProductId}`, {
      method: METHOD.GET,
    })
    return data
  },

  getRecommendProductGroupList: async () => {
    const { data } = await api(`/admin/recommend-product-groups`, {
      method: METHOD.GET,
    })
    return data
  },

  patchRecommendProductGroup: async ({
    recommendProductId,
    payload,
  }: {
    recommendProductId: number
    payload: PatchRecommendProductGroupPayload
  }) => {
    const { data } = await api(`/admin/recommend-product-group/${recommendProductId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  postRecommendProductGroup: async (payload: PostRecommendProductGroupPayload) => {
    const { data } = await api(`/admin/recommend-product-group`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },
}

export default recommendProductApi
