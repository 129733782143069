import { PropsWithChildren } from "react"
import RequiredDot from "@/_components/required-dot"

interface AdminLabelProps extends PropsWithChildren {
  required?: boolean
}

const AdminLabel = (props: AdminLabelProps) => {
  const { required, children } = props
  return (
    <p className="w-[240px] shrink-0 text-14 font-bold">
      {children}
      {required && <RequiredDot />}
    </p>
  )
}

export default AdminLabel
