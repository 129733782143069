import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { RankingPostPayload } from "@/_api/ranking-post.type"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import RankingPostForm from "../_components/ranking-post-form"
import { usePostRankingPostMutation } from "../_hooks/use-mutation"

const AdminRankingPostCreatePage = () => {
  const methods = useForm<RankingPostPayload>({
    defaultValues: {
      title: "",
      thumbnail_media_url: "",
      products: [],
      tags: [],
      is_active: true,
    },
  })

  const nav = useNavigate()
  const toast = useToast()

  const createPostMutation = usePostRankingPostMutation({
    onSuccess: () => {
      toast.success("작성완료")
      nav("/service/ranking-post", { replace: true })
    },
    onError: (e: any) => {
      console.error(`error: `, e)
      toast.error("에러!!")
    },
  })

  const onSubmit: SubmitHandler<RankingPostPayload> = data => {
    const payload = {
      ...data,
      products: data.products?.map(v => ({ id: v.id, order: v.order || 0 })),
    }

    createPostMutation.mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RankingPostForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminRankingPostCreatePage
