import dayjs from "dayjs"
import { AdminEmailSendResponse } from "@/_api/email.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useGetSendEmailList } from "./create/_hooks/queries"

interface AdminEmailSendPageProps {}

const mockEmailList: AdminEmailSendResponse[] = Array.from({ length: 10 }).map((_, index) => ({
  sender: `user${index}@example.com`,
  title: `string${index}`,
  sending_status: "COMPLETED",
  total_count: 350,
  sent_at: "2024-10-11T02:19:34.323Z",
  created: "2024-10-11T02:19:34.323Z",
}))

const _getSendingStatusString = (status: AdminEmailSendResponse["sending_status"]) => {
  switch (status) {
    case "COMPLETED":
      return "발송완료"
    case "PENDING":
      return "발송 대기중"
    default:
      return "-"
  }
}

const AdminEmailSendPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminEmailSendResponse>()

  const {
    data: sendEmailList,
    totalCount,
    isLoading,
  } = useGetSendEmailList({
    page,
    page_size: pageSize,
  })

  return (
    <div>
      <AdminPageLayout create>
        <Table
          data={sendEmailList}
          widths={[1, 3, 3, 1, 1]}
          isLoading={isLoading}
          title={["발송현황", "발송갯수", "발송자", "제목", "등록일", "발송완료일"]}
          values={{
            발송현황: v => _getSendingStatusString(v.sending_status),
            발송갯수: v => v.total_count,
            발송자: v => v.sender,
            제목: v => v.title,
            등록일: v => (v.created ? dayjs(v.created).format("YY.MM.DD") : "-"),
            발송완료일: v => (v.sent_at ? dayjs(v.sent_at).format("YY.MM.DD") : "-"),
          }}
          totalCount={totalCount}
        />
      </AdminPageLayout>
    </div>
  )
}

export default AdminEmailSendPage
