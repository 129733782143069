import { useMutation } from "@tanstack/react-query"
import bannerApi from "@/_api/banner"
import { MutationOptions } from "@/_api/common.type"

export const usePostBannerMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: bannerApi.postBanner,
    ...options,
  })
}

export const usePatchBannerMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: bannerApi.patchBanner,
    ...options,
  })
}

export const useBannerActivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: bannerApi.postBannerActivate,
    ...options,
  })
}

export const useBannerDeactivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: bannerApi.postBannerDeactivate,
    ...options,
  })
}
