import React, { useMemo, useState, useCallback } from "react"
import { Input } from "@runners/ui"
import { cn } from "@/_styles/cn"

const useSearchValue = () => {
  const [search, setSearch] = useState("")

  const handleSubmit = useCallback((value: string) => {
    setSearch(value)
  }, [])

  return {
    search,
    handleSubmit,
  }
}

export const useTableSearch = () => {
  const { search, handleSubmit } = useSearchValue()
  const SearchInput = useMemo(() => {
    return (props: TableSearchInputProps) => <TableSearchInput {...props} handleSubmit={handleSubmit} />
  }, [handleSubmit])

  return {
    search,
    SearchInput,
  }
}

interface TableSearchInputProps {
  className?: string
  label?: string
  placeholder?: string
  description?: string
  handleSubmit?: (value: string) => void
}

const TableSearchInput = React.memo((props: TableSearchInputProps) => {
  const { description = "", label = "", placeholder = "", className, handleSubmit = () => {} } = props

  const [search, setSearch] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
  }

  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <p className="text-12">{label}</p>
      <Input
        value={search}
        onChange={handleChange}
        placeholder={placeholder}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.stopPropagation()
            handleSubmit(search)
          }
        }}
      />
      <span className="text-[9px]">{description}</span>
    </div>
  )
})
