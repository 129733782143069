import { useQuery } from "@tanstack/react-query"
import recommendProductQueryKey from "@/_api/_query-key/recommend-product"
import recommendProductApi from "@/_api/recommend-product"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetRecommendProductGroupListQuery = () => {
  return useInfiniteData({
    queryKey: recommendProductQueryKey.recommendProductList({}),
    queryFn: recommendProductApi.getRecommendProductGroupList,
  })
}

export const useGetRecommendProductGroupQuery = ({ recommendProductId }: { recommendProductId: number }) => {
  return useQuery({
    queryKey: recommendProductQueryKey.detail({ recommendProductId }),
    queryFn: () => recommendProductApi.getRecommendProductGroup({ recommendProductId }),
    select: response => response.data,
  })
}
