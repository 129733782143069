import api from "."

/** ------------------------------------------------------------------------------
 * 
 * G. 미디어
 * 
 ------------------------------------------------------------------------------ */

const mediaApi = {
  /**
   * 파일 객체 조회
   */
  getFileId: async ({ id }: { id: string }) => {
    const { data } = await api.get(`/file/${id}`)
    return data
  },
  /**
   * GIF 객체 조회
   */
  getGifId: async ({ id }: { id: string }) => {
    const { data } = await api.get(`/gif/${id}`)
    return data
  },
  /**
   * 이미지 객체 조회
   */
  getImageId: async ({ id }: { id: string }) => {
    const { data } = await api.get(`/image/${id}`)
    return data
  },
  /**
   * 비디오 객체 조회
   */
  getVideoId: async ({ id }: { id: string }) => {
    const { data } = await api.get(`/video/${id}`)
    return data
  },
  /**
   * 파일 생성
   */
  postFile: async ({ formData }: { formData: FormData }) => {
    const { data } = await api.post(`/file`, formData, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })

    return data
  },
  /**
   * GIF 생성
   */
  postGif: async ({ formData }: { formData: FormData }) => {
    const { data } = await api.post(`/gif`, formData, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })

    return data
  },
  /**
   * 이미지 생성
   */
  postImage: async ({ formData }: { formData: FormData }) => {
    const { data } = await api.post(`/image`, formData, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })

    return data
  },

  /**
   * 다중 이미지 생성
   */
  postImages: async ({ formData }: { formData: FormData }) => {
    const { data } = await api.post(`/images`, formData, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })

    return data
  },

  /**
   * 비디오 생성
   */
  postVideo: async ({ formData }: { formData: FormData }) => {
    const { data } = await api.post(`/video`, formData, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })

    return data
  },
}

export default mediaApi
