import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { CHAR_BLANK } from "@runners/lib/constants"
import { Button } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import bannerQueryKey from "@/_api/_query-key/banner"
import DetailSection from "@/_components/detail-section"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Divider from "@/_components/layout/divider"
import useToast from "@/_hooks/use-toast"
import BannerForm, { AdminBannerFormType } from "../_components/banner-form"
import { BANNER_DISPLAY_TYPE, BANNER_TYPE, BannerDisplayType, BannerType } from "../_constants"
import { useGetBannerQuery } from "../_hooks/use-get-query"
import { usePatchBannerMutation } from "../_hooks/use-mutation"

const AdminBannerDetailPage = () => {
  const { bannerId: _bannerId } = useParams()
  const bannerId = Number(_bannerId)

  const { data } = useGetBannerQuery({ bannerId })
  const [isReady, setIsReady] = useState(false)

  /**
   * init
   */
  useEffect(() => {
    if (!data) return

    const bannerType = BANNER_TYPE.find(v => v.value === data.type)
    const bannerDisplayType = BANNER_DISPLAY_TYPE.find(v => v.value === data.display_type)

    methods.reset({
      thumbnail_image_url: data.thumbnail_image_url,
      mobile_thumbnail_image_url: data.mobile_thumbnail_image_url,
      link: data.link,
      type: bannerType,
      display_type: bannerDisplayType,
      order: `${data.order}`,
      is_active: data.is_active,
    })

    setIsReady(true)
  }, [data])

  // const nav = useNavigate()
  const toast = useToast()
  const qc = useQueryClient()
  const methods = useForm<AdminBannerFormType>()

  const { mutate: update } = usePatchBannerMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: bannerQueryKey.detail({ bannerId }) })
      toast.success("수정 되었습니다.")
    },
    onError: (e: any) => {
      console.error(`e`, e)
      alert("error!")
    },
  })

  const onSubmit: SubmitHandler<AdminBannerFormType> = data => {
    update({
      bannerId: bannerId,
      payload: {
        ...data,
        type: data.type.value as BannerType,
        display_type: data.display_type.value as BannerDisplayType,
        order: Number(data.order),
      },
    })
  }

  return (
    <AdminPageLayout onUpdate={methods.handleSubmit(onSubmit)}>
      {isReady && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BannerForm />

            <div className="h-10" />
            <Button type="submit" className="w-full" size="48">
              수정
            </Button>
          </form>
        </FormProvider>
      )}

      <Divider />

      <DetailSection
        title="작성자 정보 / 등록일"
        values={{
          이름: data?.user?.username || "-",
          이메일: data?.user?.email || "-",
          " ": CHAR_BLANK,
          등록일: dayjs(data?.created).format("YY.MM.DD"),
          수정일: dayjs(data?.modified).format("YY.MM.DD"),
        }}
      />

      <Divider />
    </AdminPageLayout>
  )
}

export default AdminBannerDetailPage
