import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"
import { useQuery } from "@tanstack/react-query"
import rankingPostQueryKey from "@/_api/_query-key/ranking-post"
import rankingPostApi from "@/_api/ranking-post"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetRankingPostListQuery = (params: DjangoSearchOrderingParams) => {
  return useInfiniteData({
    queryKey: rankingPostQueryKey.rankingPostList.params(params),
    queryFn: () => rankingPostApi.getRankingPostList(params),
  })
}

export const useGetRankingPostQuery = ({ postId }: { postId: number }) => {
  return useQuery({
    queryKey: rankingPostQueryKey.rankingPostDetail({ postId }),
    queryFn: () => rankingPostApi.getRankingPost({ postId }),
    select: response => response.data,
  })
}
