import { useFormContext } from "react-hook-form"
import { Input } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"

const AdminCampaignPeriod = () => {
  const { register } = useFormContext()

  return (
    <>
      <AdminFormField label="시작일자" required>
        <Input type="datetime-local" className="min-w-[600px]" {...register("start_date")} />
      </AdminFormField>

      <AdminFormField label="종료일자" required>
        <Input type="datetime-local" className="min-w-[600px]" {...register("end_date")} />
      </AdminFormField>
    </>
  )
}

export default AdminCampaignPeriod
