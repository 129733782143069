import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"

const settlementQueryKey = {
  adminSettlement: () => ["admin", "settlement"],

  settlementList: (params: DjangoSearchOrderingParams) => [
    ...settlementQueryKey.adminSettlement(),
    "list",
    { ...params },
  ],
}

export default settlementQueryKey
