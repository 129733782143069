import { InboxOutlined, CarryOutOutlined, MailOutlined } from "@ant-design/icons"

export interface MenuItem {
  name: string
  url: string
  icon?: JSX.Element
  children?: MenuItem[]
}

export const TAB_MENU: MenuItem[] = [
  {
    name: "서비스 관리",
    url: "/service",
    icon: <CarryOutOutlined />,
    children: [
      {
        name: "회원 관리",
        url: "/service/user",
      },
      // {
      //   name: "권한 관리",
      //   url: "/service/permission",
      // },
      {
        name: "이벤트 관리",
        url: "/service/event",
      },
      {
        name: "커뮤니티 관리",
        url: "/service/community",
      },
      {
        name: "라벨 관리",
        url: "/service/label",
      },
      {
        name: "콘텐츠 관리",
        url: "/service/post",
      },
      {
        name: "랭킹콘텐츠 관리",
        url: "/service/ranking-post",
      },
      {
        name: "배너 관리",
        url: "/service/banner",
      },
      {
        name: "추천 관리",
        url: "/service/recommend",
      },
      {
        name: "알림 관리",
        url: "/service/notification",
      },
    ],
  },
  {
    name: "스토어 관리",
    url: "store",
    icon: <InboxOutlined />,
    children: [
      {
        name: "기획전 관리",
        url: "/store/campaign",
      },
      {
        name: "셀러 관리",
        url: "/store/seller",
      },
      {
        name: "상품 관리",
        url: "/store/product",
      },
      {
        name: "상품그룹 관리",
        url: "/store/p-group",
      },
      {
        name: "추천상품 관리",
        url: "/store/recommend-p",
      },
      {
        name: "결제 관리",
        url: "/store/payment",
      },
      {
        name: "정산 관리",
        url: "/store/settlement",
      },
      {
        name: "후기 관리",
        url: "/store/review",
      },
      {
        name: "신고 관리",
        url: "/store/report",
      },
    ],
  },
  {
    name: "메일 관리",
    url: "email",
    icon: <MailOutlined />,
    children: [
      {
        name: "템플릿 관리",
        url: "/email/template",
      },
      {
        name: "메일 발송 관리",
        url: "/email/send",
      },
      {
        name: "주소록 관리",
        url: "/email/address-book",
      },
    ],
  },
]

export const findMenuItemByUrl = (menu: MenuItem[], url: string): MenuItem | undefined => {
  for (const item of menu) {
    if (item.url === url) {
      return item
    }
    if (item.children) {
      const found = findMenuItemByUrl(item.children, url)
      if (found) {
        return found
      }
    }
  }
  return undefined
}
