import { useState } from "react"
import { Toaster } from "react-hot-toast"
import { RouterProvider } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ModalProvider from "./_components/modal/admin-modal-provider"
import RelativePortal from "./_components/modal/admin-relative-modal"
import "./_styles/codex.css"
import AdminRouter from "./admin-router"

function App() {
  const [queryClient] = useState(new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={AdminRouter} />
      <Toaster containerStyle={{ top: 100 }} />
      <ModalProvider />
      <RelativePortal />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
