import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { hashPassword } from "@runners/lib/hash"
import { Button, FormInput } from "@runners/ui"
import { AuthStorage } from "@/_constants/auth"
import { useErrorHandler } from "@/_hooks/use-error-handler"
import { useSignIn } from "@/_pages/sign-in/hooks"
import { setCookie } from "@/_utils/cookie"

type LoginFormItem = {
  email: string
  password: string
}

const LoginForm = () => {
  const router = useNavigate()
  const methods = useForm<LoginFormItem>({ mode: "onTouched" })

  const { mutate } = useSignIn({
    onError: err => {
      alert(getErrorMessage(err))
    },

    onSuccess: ({ data }) => {
      const {
        token: { access_token, refresh_token },
      } = data

      setCookie(AuthStorage.ACCESS_TOKEN, access_token)
      setCookie(AuthStorage.REFRESH_TOKEN, refresh_token)

      router(0)
    },
  })
  const { getErrorMessage } = useErrorHandler()

  const onSubmit: SubmitHandler<LoginFormItem> = async data => {
    // eslint-disable-next-line no-console
    const hash = await hashPassword(data.password)
    mutate({
      ...data,
      password: hash,
    })
  }

  return (
    <form className="mt-6 flex w-full flex-col gap-3" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="w-full">
        <p className="text-14">이메일</p>
        <FormInput
          {...methods.register("email", {
            required: "이메일을 입력해 주세요.",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
              message: "올바른 양식의 이메일 주소를 입력해주세요.",
            },
          })}
          className="mt-1 w-full"
          errors={methods.formState.errors}
        />
      </div>
      <div className="w-full">
        <p className="text-14">비밀번호</p>
        <FormInput {...methods.register("password")} className="mt-1 w-full" type="password" />
      </div>

      <Button type="submit" className="mt-4" size="48">
        로그인
      </Button>
    </form>
  )
}

export default LoginForm
