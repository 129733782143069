import { useFormContext } from "react-hook-form"
import { Badge, Switch } from "@runners/ui"
import AdminLabel from "../admin-label"

const AdminPostActive = () => {
  const { register, watch } = useFormContext()

  const isActive = watch("is_active") || false
  const watchToggle = isActive ? "활성화" : "비활성화"

  return (
    <div className="flex items-center gap-5">
      <AdminLabel required>활성화 여부</AdminLabel>

      <div className="w-full">
        <div className="flex items-center gap-4">
          <Switch size="24" {...register("is_active")} checked={isActive} />
          <Badge color={isActive ? "primary" : "gray"}>{watchToggle}</Badge>
        </div>
      </div>
    </div>
  )
}

export default AdminPostActive
