import { useCallback, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

interface UseQueryPaginationOptions {
  page?: number
  pageSize?: number
}

export const useQueryPagination = ({ page = 1, pageSize = 10 }: UseQueryPaginationOptions = {}) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    let shouldUpdate = false

    if (!searchParams.has("page")) {
      searchParams.set("page", page.toString())
      shouldUpdate = true
    }

    if (!searchParams.has("page_size")) {
      searchParams.set("page_size", pageSize.toString())
      shouldUpdate = true
    }

    if (shouldUpdate) {
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    }
  }, [navigate, location, page, pageSize])

  const setPage = useCallback(
    (newPage: number) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set("page", newPage.toString())
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location],
  )

  const setPageSize = useCallback(
    (newPageSize: number) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set("page_size", newPageSize.toString())
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate, location],
  )

  return { setPage, setPageSize }
}
