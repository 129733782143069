import { useFormContext } from "react-hook-form"
import { Input, Textarea } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import ImageMultiple from "@/_components/react-hook-form/image-multiple"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"

const AdminCampaignSeoInfo = () => {
  const { register, watch } = useFormContext()

  const description = watch("description") || ""
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-24 font-bold">기획전 배너 정보</h3>

      <AdminFormField label="기획전 제목" required>
        <Input className="min-w-[600px]" {...register("title")} />
      </AdminFormField>

      <AdminFormField label="기획전 설명" required>
        <Textarea rows={3} className="min-w-[600px]" {...register("description")} />
        {description.length}/140
      </AdminFormField>

      <ThumbnailInput name="seo_thumbnail_image_url" label="SEO 썸네일 이미지" className="h-[157px] w-[300px]" />
      <ImageMultiple name="detail_images_data" label="상단 배너 이미지" />
    </div>
  )
}

export default AdminCampaignSeoInfo
