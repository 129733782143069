import { AdminEmailSendParams, AdminEmailTemplateListParams } from "../email.type"

const emailQueryKey = {
  emailtemplate: ["emailTemplate"],

  emailTemplateList: {
    all: () => ["emailTemplate", "list"],
    params: (params: AdminEmailTemplateListParams) => ["emailTemplate", "list", params],
  },

  emailTemplateDetail: {
    id: (id: number) => ["emailTemplate", "detail", id],
  },

  emailSendList: {
    all: () => ["emailSend", "list"],
    params: (params: AdminEmailSendParams) => ["emailSend", "list", params],
  },
}

export default emailQueryKey
