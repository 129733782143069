import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import rankingPostQueryKey from "@/_api/_query-key/ranking-post"
import { RankingPostPayload } from "@/_api/ranking-post.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { useGetRankingPostQuery } from "@/_pages/service/ranking-post/_hooks/use-get-query"
import {
  useDeleteRankingPostMutation,
  usePatchRankingPostMutation,
} from "@/_pages/service/ranking-post/_hooks/use-mutation"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import RankingPostForm from "../_components/ranking-post-form"

const AdminRankingPostDetailPage = () => {
  const { postId: _postId } = useParams()
  const postId = Number(_postId)
  const { data: postDetail } = useGetRankingPostQuery({ postId })

  const methods = useForm<RankingPostPayload>()

  /**
   * init
   */
  useEffect(() => {
    if (!postDetail) return

    methods.reset({
      title: postDetail.title,
      thumbnail_media_url: postDetail.thumbnail_media_url,
      is_active: postDetail.is_active,
      tags: postDetail.tags,
      products: postDetail.products,
    })
  }, [postDetail])

  /**
   * patch
   */
  const toast = useToast()
  const queryClient = useQueryClient()

  const updatePostMutation = usePatchRankingPostMutation({
    onSuccess: () => {
      toast.success("수정 완료!")
      queryClient.invalidateQueries({ queryKey: rankingPostQueryKey.rankingPostDetail({ postId }) })
    },
    onError: (e: any) => {
      console.error(`error: `, e)
      toast.error("에러!!")
    },
  })

  const onSubmit: SubmitHandler<RankingPostPayload> = data => {
    const payload = {
      ...data,
      products: data.products?.map(v => ({ id: v.id, order: v.order || 0 })),
    }

    updatePostMutation.mutate({ postId, payload })
  }

  /**
   * delete
   */
  const deletePostMutation = useDeleteRankingPostMutation({
    onSuccess: () => {
      toast.success("삭제 완료!")
      history.back()
    },
  })
  const modalStack = useModalStack()

  const handleDelete = () => {
    modalStack.open({
      key: "admin-post-detail-delete",
      Component: Alert,
      componentProps: {
        title: "정말 삭제하시겠습니까?",
        onConfirm: () => {
          deletePostMutation.mutate({ postId })
        },
      },
    })
  }

  if (!postDetail) return <></>

  return (
    <AdminPageLayout onDelete={handleDelete} onUpdate={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RankingPostForm />
        </form>
      </FormProvider>
    </AdminPageLayout>
  )
}

export default AdminRankingPostDetailPage
