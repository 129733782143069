import dayjs from "dayjs"
import { AdminAddressBookListResponse } from "@/_api/address-books.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { navigate } from "@/_constants/navigate"
import { useGetAddressBookList } from "./_hooks/queries"

const AdminAddressBookPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminAddressBookListResponse>()

  const { data: addressBookList, totalCount, isLoading } = useGetAddressBookList({ page, page_size: pageSize })

  return (
    <div>
      <AdminPageLayout create>
        <Table
          className="min-w-[1600px]"
          totalCount={totalCount}
          data={addressBookList}
          isLoading={isLoading}
          title={["id", "이메일", "쇼핑물", "품목", "업체명", "대표자명", "휴대전화", "URL", "등록일"]}
          values={{
            id: v => v.id,
            URL: v => v.url,
            이메일: v => v.email,
            쇼핑물: v => v.marketplace,
            품목: v => v.product_category,
            대표자명: v => v.owner_name,
            등록일: v => (v.created ? dayjs(v.created).format("YY.MM.DD") : "-"),
            휴대전화: v => v.mobile,
            업체명: v => v.title,
          }}
          href={value => navigate.addressBookDetail(value.id)}
        />
      </AdminPageLayout>
    </div>
  )
}

export default AdminAddressBookPage
