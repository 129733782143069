import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"
import { DjangoListResponse, DjangoResponse } from "@runners/interface/api/django-response"
import {
  RankingPostActivateDto,
  RankingPostDto,
  RankingPostListDto,
  RankingPostPayload,
} from "@/_api/ranking-post.type"
import api, { METHOD } from "."

const rankingPostApi = {
  /**
   * 랭킹 포스트 객체 조회
   */
  getRankingPost: async ({ postId }: { postId: number }) => {
    const { data } = await api.get<DjangoResponse<RankingPostDto>>(`/admin/ranking-post/${postId}`)
    return data
  },

  /**
   * 랭킹 포스트 리스트 조회
   */
  getRankingPostList: async (params: DjangoSearchOrderingParams) => {
    const { data } = await api<DjangoListResponse<RankingPostListDto>>(`/admin/ranking-posts`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 랭킹 포스트 생성
   */
  postRankingPost: async (payload: RankingPostPayload) => {
    const { data } = await api(`/admin/ranking-post`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 랭킹 포스트 수정
   */
  patchRankingPost: async ({ postId, payload }: { postId: number; payload: Partial<RankingPostPayload> }) => {
    const { data } = await api(`/admin/ranking-post/${postId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 랭킹 포스트 선택 활성화
   */
  postRankingPostActivate: async (payload: RankingPostActivateDto) => {
    const { data } = await api(`/admin/ranking-posts/activate`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 랭킹 포스트 선택 비활성화
   */
  postRankingPostDeactivate: async (payload: RankingPostActivateDto) => {
    const { data } = await api(`/admin/ranking-posts/deactivate`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 랭킹 포스트 객체 삭제
   */
  deleteRankingPost: async ({ postId }: { postId: number }) => {
    const { data } = await api(`/admin/ranking-post/${postId}`, {
      method: METHOD.DELETE,
    })
    return data
  },
}

export default rankingPostApi
