import { useLayoutEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Button } from "@runners/ui"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import { useFormTemporarily } from "@/_hooks/use-form-temporarily"
import useToast from "@/_hooks/use-toast"
import PostForm from "../_components/post-form"
import { usePostPostMutation } from "../_hooks/use-mutation"

interface ItemProps {
  label: string
  value: string | number
}

export interface AdminPostForm {
  thumbnail_media_url: string
  start_date: string
  end_date: string
  title: string
  sub_title: string
  content: string
  notice: string
  city?: ItemProps // payload 담을때는 number
  sub_city?: ItemProps // payload 담을때는 number
  contact_category?: ItemProps // payload 담을때는 number
  labels: ItemProps[] // payload에 담을때는 number
  tags: string[]
  is_active: boolean
}

const AdminPostCreatePage = () => {
  const methods = useForm<AdminPostForm>({
    defaultValues: {
      content: "",
    },
  })

  const nav = useNavigate()
  const toast = useToast()
  const createPostMutation = usePostPostMutation({
    onSuccess: () => {
      toast.success("작성완료")
      nav("/service/post", { replace: true })
    },
    onError: (e: any) => {
      console.error(`error: `, e)
      toast.error("에러!!")
    },
  })

  const onSubmit: SubmitHandler<AdminPostForm> = data => {
    const payload = {
      ...data,
      start_date: data.start_date === "" ? null : data.start_date,
      end_date: data.end_date === "" ? null : data.end_date,
      city: Number(data.city?.value) || null,
      sub_city: Number(data.sub_city?.value) || null,
      contact_category: Number(data.contact_category?.value) || null,
      labels: (data.labels || []).map(v => Number(v.value)) as number[],
    }

    createPostMutation.mutate(payload)
  }

  /**
   * 임시저장
   */
  const [postTemp, setTemp, reset] = useFormTemporarily("post-temp-data")
  const [isReady, setIsReady] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      if (Object.keys(postTemp).length !== 0) {
        const userConfirmed = window.confirm("임시 저장된 데이터가 있습니다. 불러오시겠습니까?")
        if (userConfirmed) {
          methods.reset(postTemp)
          reset()
          setIsReady(true)
        }
      }

      setIsReady(true)
    }, 100)
  }, [])

  if (!isReady) return <></>

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center justify-end">
          <Button
            size="40"
            color="gray"
            outline
            className="active:scale-[0.98]"
            onClick={() => setTemp(methods.watch())}
          >
            임시저장
          </Button>
        </div>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PostForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminPostCreatePage
