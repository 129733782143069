import { toLocalePrice } from "@runners/lib/price"
import Badge, { BadgeColor } from "@runners/ui/src/badge"
import dayjs from "dayjs"
import { SettlementGroupDto, SettlementStatus } from "@/_api/settlement.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useAdminHackStore } from "@/_hooks/use-admin-hack-store"
import { useTableSearch } from "@/_hooks/use-table-search"
import SettlementSelect from "./_components/settlement-select"
import { useGetSettlementListQuery } from "./_hooks/use-get-query"

const AdminSettlementPage = () => {
  const { Table, page, pageSize } = usePaginationTable<SettlementGroupDto>()

  const { SearchInput, search } = useTableSearch()

  const {
    data = [],
    isLoading,
    totalCount,
  } = useGetSettlementListQuery({
    page_size: pageSize,
    page,
    search,
  })

  const { onClick } = useAdminHackStore()

  return (
    <AdminPageLayout totalCount={totalCount}>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 상호명"
        placeholder="필터링할 검색어 입력"
      />
      <Table
        totalCount={totalCount}
        data={data}
        isLoading={isLoading}
        title={[
          "ID",
          "상호명",
          "등급",
          "스토어 이동",
          "판매량",
          "정산금액합계",
          "정산현황",
          "정산예정일",
          "담당자 이메일",
          "담당자 전화번호",
          "비고",
        ]}
        widths={[1, 2, 2, 2, 2, 2, 2, 2.5, 2.5, 2, 5, 2]}
        values={{
          ID: v => v.id,
          상호명: v => v.store.title,
          등급: v => v.store.commission_info,
          "스토어 이동": value => {
            return (
              <div
                className="cursor-pointer text-blue-600 underline underline-offset-4 hover:text-blue-800"
                onClick={onClick(value.store.id)}
              >
                이동
              </div>
            )
          },
          판매량: v => `${v.count}건`,
          정산금액합계: v => toLocalePrice(v.total_settlement_amount),
          정산현황: v => <StatusBadge status={v.status} />,
          정산예정일: v => dayjs(v.expected_date).format("YYYY-MM-DD"),
          "담당자 이메일": v => v.store.seller_email || "",
          "담당자 전화번호": v => v.store.seller_phone || "",
          비고: v => <SettlementSelect rowData={v} />,
        }}
      />
    </AdminPageLayout>
  )
}

export default AdminSettlementPage

/**
 * 정산상태 뱃지
 */
const StatusBadge = ({ status }: { status: SettlementStatus }) => {
  let color: BadgeColor
  let text = ""

  switch (status) {
    case SettlementStatus.PENDING:
      color = "gray"
      text = "정산대기중"
      break
    case SettlementStatus.COMPLETED:
      color = "blue"
      text = "정산완료"
      break
    case SettlementStatus.FAILED:
      color = "red"
      text = "정산실패"
      break
  }

  return (
    <div className="flex items-center justify-center">
      <Badge color={color}>{text}</Badge>
    </div>
  )
}
