import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import labelQueryKey from "@/_api/_query-key/label"
import DetailSection from "@/_components/detail-section"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Divider from "@/_components/layout/divider"
import Alert from "@/_components/modal/alert"
import { useInvalidate } from "@/_hooks/use-invalidate"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import AdminLabelForm from "../_components/admin-label-form"
import { useGetLabelQuery } from "../_hooks/use-get-query"
import { useDeleteLabelListMutation } from "../_hooks/use-mutation"
import { LabelFormType } from "../create/admin-label-create-page"

const AdminLabelDetailPage = () => {
  const { labelId: _labelId } = useParams()
  const labelId = Number(_labelId)
  const { data } = useGetLabelQuery({ labelId })

  const methods = useForm<LabelFormType>({
    defaultValues: {
      title: "",
      is_active: false,
    },
  })

  const modalStack = useModalStack()

  const nav = useNavigate()
  const { invalidate } = useInvalidate()
  const { mutate } = useDeleteLabelListMutation({
    onSuccess: async () => {
      await invalidate(labelQueryKey.labelList({}))
      nav("/service/label", { replace: true })
    },
  })

  // 라벨 API 수정필요
  const handleDelete = () => {
    modalStack.open({
      key: "admin-label-detail-delete",
      Component: Alert,
      componentProps: {
        title: "정말 삭제하시겠습니까?",
        onConfirm: () => {
          mutate({ label_ids: [labelId] })
        },
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <AdminPageLayout onDelete={handleDelete}>
        {data && <AdminLabelForm labelId={labelId} initialValues={data} />}

        <Divider />

        <DetailSection
          title="게시글 연관 정보"
          values={{
            게시글: data?.post_count || 0,
            "게시글 총 조회수": data?.total_post_visit_count || 0,
          }}
        />

        <Divider />

        <DetailSection
          title="작성 정보"
          values={{
            이메일: data?.user?.email || "",
            닉네임: data?.user?.username || "",
            "라벨 생성일": dayjs(data?.created).format("YY.MM.DD") || "",
            "최종 수정일": dayjs(data?.modified).format("YY.MM.DD") || "",
          }}
        />

        <Divider />
      </AdminPageLayout>
    </FormProvider>
  )
}

export default AdminLabelDetailPage
