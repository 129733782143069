import { hasAccessToken } from "@/_utils/cookie"

interface DashboardProps {}

const Dashboard = (props: DashboardProps) => {
  const {} = props

  const isLogin = hasAccessToken()
  if (!isLogin) return <></>

  return <div>Dashboard</div>
}

export default Dashboard
