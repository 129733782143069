import { useState } from "react"

export const useFormTemporarily = (
  key: string,
): [values: any, setLocalStorageSave: (value: any) => void, resetTemp: () => void] => {
  const [tempValues, setTempValues] = useState(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : {}
    } catch (error) {
      console.error(error)
    }
  })

  const setLocalStorageSave = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(tempValues) : value
      setTempValues(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
    }
  }

  const resetTemp = () => {
    setTempValues({})
    localStorage.removeItem(key)
  }

  return [tempValues, setLocalStorageSave, resetTemp]
}
