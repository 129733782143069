import { useQuery } from "@tanstack/react-query"
import campaignQueryKey from "@/_api/_query-key/campaign"
import campaignApi from "@/_api/campaign"
import { AdminCampaignListParams } from "@/_api/campaign.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetCampaignListQuery = (params: AdminCampaignListParams) => {
  return useInfiniteData({
    queryKey: campaignQueryKey.campaignList(params),
    queryFn: () => campaignApi.getCampaignList(params),
  })
}

export const useGetCampaignQuery = ({ campaignId }: { campaignId: number }) => {
  return useQuery({
    queryKey: campaignQueryKey.campaignDetail({ campaignId }),
    queryFn: () => campaignApi.getCampaign({ campaignId }),
    select: response => response.data,
  })
}
