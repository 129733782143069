import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"

export interface PostAdminCampaignPayload {
  start_date: string
  end_date: string
  store_title: string
  display_type: CampaignDisplayType
  title: string
  description: string
  order: number
  thumbnail_image_url: string
  mobile_thumbnail_image_url: string
  seo_thumbnail_image_url: string
  detail_images_data: string[]
  products: number[]
  is_active: boolean
  is_main_display: boolean
  main_order: number
}

export interface PatchAdminCampaignPayload {
  campaignId: number
  payload: Partial<PostAdminCampaignPayload>
}

export interface AdminCampaignListParams extends DjangoSearchOrderingParams {
  is_active?: boolean
}

export enum CampaignDisplayType {
  "쇼핑-메인-B-01" = "SHOP_HOME_B_01",
  "쇼핑-메인-B-02" = "SHOP_HOME_B_02",
}

export interface AdminCampaignDto {
  id: number
  start_date: string
  end_date: string
  store_title: string
  display_type: CampaignDisplayType
  title: string
  description: string
  order: number
  link: string
  thumbnail_image_url: string
  mobile_thumbnail_image_url: string
  seo_thumbnail_image_url: string
  detail_images_data: string[]
  is_active: boolean
  created: string
  modified: string
  products: {
    id: number
    thumbnail_image_url: string
    title: string
    store_title: string
    product_category: {
      id: number
      title: string
    }
    product_sub_category: {
      id: number
      title: string
      product_category: number
    }
    discount_price: number
    origin_price: number
    created: string
    modified: string
  }[]
  user: {
    id: number
    username: string
    email: string
    phone: string
  }
  is_main_display: boolean
  main_order: number | null
}

export interface AdminCampaignListDto {
  id: number
  display_type: CampaignDisplayType
  thumbnail_image_url: string
  title: string
  store_title: string
  link: string
  start_date: string
  end_date: string
  is_active: boolean
}
