import { Link, useNavigate } from "react-router-dom"
import { Button } from "@runners/ui"
import { AuthStorage } from "@/_constants/auth"
import { navigate } from "@/_constants/navigate"
import useUserInfo from "@/_hooks/use-user-info"
import { removeCookie } from "@/_utils/cookie"
import { SenseLogo } from "@/assets"

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const {} = props

  const nav = useNavigate()

  const { data: userInfo } = useUserInfo()

  const handleClickLogout = () => {
    if (confirm("로그아웃하시겠습니까?")) {
      removeCookie(AuthStorage.ACCESS_TOKEN)
      nav(0)
    }
  }

  return (
    <header className="flex h-[64px] items-center justify-between gap-4 bg-white px-6">
      <div className="flex cursor-pointer items-end gap-2" onClick={() => nav(navigate.home())}>
        <SenseLogo className="h-6 shrink-0 text-primary" />
        <p className="text-12 font-bold text-primary">관리자</p>
      </div>

      <div className="flex gap-4">
        <div className="flex items-center gap-2">
          <Link to={import.meta.env.VITE_SENSE_WEB_URL} target="_blank" className="text-12 hover:underline">
            웹으로 이동
          </Link>
          <Link to={import.meta.env.VITE_SENSE_STORE_URL} target="_blank" className="text-12 hover:underline">
            스토어로 이동
          </Link>
        </div>

        <div className="">{userInfo?.email}</div>

        <Button size="32" color="gray" className="" onClick={handleClickLogout}>
          로그아웃
        </Button>
      </div>
    </header>
  )
}

export default Header
