import { useMutation } from "@tanstack/react-query"
import { MutationOptions } from "@/_api/common.type"
import productApi from "@/_api/product"

export const usePostProductMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productApi.postProduct,
    ...options,
  })
}

export const usePatchProductMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productApi.patchProduct,
    ...options,
  })
}

export const useProductActivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productApi.postProductActivate,
    ...options,
  })
}

export const useProductDeactivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productApi.postProductDeactivate,
    ...options,
  })
}

export const useProductStatusMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: productApi.postProductStatus,
    ...options,
  })
}
