import { DjangoListResponse } from "@runners/interface/api/django-response"
import api, { METHOD } from "."
import { AdminEventListDto, AdminEventListParams } from "./event.type"

const eventApi = {
  getAdminEventList: async (params: AdminEventListParams) => {
    const { data } = await api<DjangoListResponse<AdminEventListDto>>(`/admin/events`, {
      method: METHOD.GET,
      params,
    })

    return data
  },
}

export default eventApi
