import { useMemo, useState } from "react"
import { Button } from "@runners/ui"
import { cn } from "@/_styles/cn"

export interface TableDropdownItem {
  label: string
  fn: (ids: number[]) => void
  isPlaceholder?: boolean
}

export type TableDropdownList = TableDropdownItem[]

interface TableDropdownProps<T> {
  items: TableDropdownItem[]
  selectedRows: T & { id: number }[]
  clear: () => void

  className?: string
}

const temp = { label: "선택", fn: () => {}, isPlaceholder: true }

const TableDropdown = <T,>(props: TableDropdownProps<T>) => {
  const { items, selectedRows, className, clear } = props

  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<TableDropdownItem>(temp)

  const dropdownList = useMemo(() => {
    if (!items) return []
    return [temp, ...items]
  }, [items])

  const handleSubmit = () => {
    if (!selected) return

    if (confirm(`${selected.label} 적용할까요?`)) {
      selected.fn(selectedRows.map(item => item.id))
      setSelected(temp)
      clear()
    }
  }

  return (
    <div className={cn("ml-10 flex items-center gap-3", className)}>
      <div className="relative">
        <div className="rounded-2 border border-gray-400">
          <div
            data-test-id="select-button"
            className={cn("w-full min-w-[160px] p-2 text-left", {
              "text-gray-600": selected.isPlaceholder,
            })}
            onClick={() => setIsOpen(!isOpen)}
          >
            {selected.label}
          </div>
        </div>

        {isOpen && (
          <div className="absolute bottom-10 right-0 w-full rounded-2 border border-gray-400 bg-white">
            {dropdownList.map((item, index) => {
              return (
                <button
                  key={index}
                  data-test-id={`dropdown-${item.label}-button`}
                  className={cn("w-full p-2 text-left", {
                    "text-gray-600": item.isPlaceholder,
                  })}
                  onClick={() => {
                    setSelected(item)
                    setIsOpen(false)
                  }}
                >
                  {item.label}
                </button>
              )
            })}
          </div>
        )}
      </div>

      <Button
        size="40"
        color="primary"
        data-test-id="dropdown-submit-button"
        disabled={selected.label === "선택"}
        onClick={handleSubmit}
      >
        실행
      </Button>
    </div>
  )
}

export default TableDropdown
