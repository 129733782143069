import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Input } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import emailQueryKey from "@/_api/_query-key/email"
import { AdminEmailTemplatePayload } from "@/_api/email.type"
import AdminCreateTitle from "@/_components/admin-create-title"
import { navigate } from "@/_constants/navigate"
import EmailEditorComponent from "../_components/email-editor-component"
import { useCreateEmailTemplate } from "../_hooks/template-mutations"

const AdminEmailTemplateCreatePage = () => {
  const methods = useForm()

  const { register, setValue, handleSubmit } = methods

  const { mutate: createMutate } = useCreateEmailTemplate()

  const nav = useNavigate()

  const qc = useQueryClient()

  const onSubmit = (payload: FieldValues) => {
    if (!payload.title || !payload.content) return

    createMutate(payload as AdminEmailTemplatePayload, {
      onSuccess: () => {
        qc.invalidateQueries({ queryKey: emailQueryKey.emailTemplateList.all() })
        nav(navigate.template())
      },
    })
  }

  const handleEditorChange = (data: string) => {
    setValue("content", data)
  }

  return (
    <FormProvider {...methods}>
      <div className="bg-gray-100">
        <AdminCreateTitle onSubmit={handleSubmit(onSubmit)} />
        <div className="flex flex-col items-center">
          <div className="mb-4 w-full max-w-[720px]">
            <p className="mb-2 text-20 font-bold">템플릿 이름</p>
            <Input className="w-full" {...register("title")} placeholder="템플릿 이름을 입력해 주세요." />
          </div>
          <EmailEditorComponent onChangeEditor={handleEditorChange} initialValues="" />
        </div>
      </div>
    </FormProvider>
  )
}

export default AdminEmailTemplateCreatePage
