import { useLocation } from "react-router-dom"
import { Button } from "@runners/ui"
import { TAB_MENU } from "@/_constants/router-tab-menu"

interface AdminCreateTitleProps {
  onSubmit: () => void
}

/**
 * 생성용 타이틀 컴포넌트
 */
const AdminCreateTitle = (props: AdminCreateTitleProps) => {
  const { onSubmit } = props

  const { pathname } = useLocation()

  const title = _getName(pathname)

  return (
    <div className="bg-gray-200 p-6">
      <p>breadcrumb</p>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h1 className="text-30 font-bold">{title}</h1>
        </div>

        <Button className="px-5" color="primary" size="40" onClick={onSubmit}>
          {_getName(pathname, "등록")}
        </Button>
      </div>
    </div>
  )
}

export default AdminCreateTitle

const _getName = (url: string, suffix: string = "생성"): string => {
  try {
    if (url.endsWith("/create")) {
      const parentUrl = url.substring(0, url.lastIndexOf("/create"))
      for (const menu of TAB_MENU) {
        if (menu.children) {
          for (const item of menu.children) {
            if (item.url === parentUrl) {
              return `${item.name.split(" ")[0]} ${suffix}`
            }
          }
        }
      }
    }
    return ""
  } catch {
    return ""
  }
}
