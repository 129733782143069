import { StoreProductEditor } from "@runners/editor"

interface EmailEditorComponentProps {
  initialValues?: string
  onChangeEditor?: (content: string) => void
}

const EmailEditorComponent = (props: EmailEditorComponentProps) => {
  const { initialValues, onChangeEditor } = props

  return <StoreProductEditor initialValues={initialValues} onChangeEditor={onChangeEditor} />
}

export default EmailEditorComponent
