import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { FormInput } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"
import addressBooksQueryKey from "@/_api/_query-key/address-books"
import { AdminAddressBookPayload } from "@/_api/address-books.type"
import DetailSection from "@/_components/detail-section"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import { navigate } from "@/_constants/navigate"
import { useFormInvalid } from "@/_hooks/use-invalid"
import useToast from "@/_hooks/use-toast"
import { parseErrorDetails } from "@/_utils/parse"
import { usePostAddressBokk } from "../_hooks/address-book-mutations"

const validate = {
  required: "한글자 이상 입력해주세요",
}

const AdminAddressBookCreatePage = () => {
  const methods = useForm()

  const { handleSubmit, register } = methods

  const { mutate: createAddressBook } = usePostAddressBokk()

  const qc = useQueryClient()
  const nav = useNavigate()
  const toast = useToast()
  const onInvalid = useFormInvalid({
    onError: error => {
      toast.error(error.message!)
    },
  })

  const onSubmit = (data: FieldValues) => {
    createAddressBook(data as AdminAddressBookPayload, {
      onSuccess: () => {
        qc.invalidateQueries({ queryKey: addressBooksQueryKey.addressBookList.all() })
        nav(navigate.addressBook())
      },
      onError: error => {
        if (isAxiosError(error)) {
          const errors = parseErrorDetails(error.response?.data?.details)

          for (const key of Object.keys(errors)) {
            toast.error(errors[key] ?? "")
          }
        }
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <DetailSection
          title="주소록 정보"
          values={{
            이메일: <FormInput className="min-w-[400px]" {...register("email", validate)} />,
            쇼핑물: <FormInput className="min-w-[400px]" {...register("marketplace", validate)} />,
            품목: <FormInput className="min-w-[400px]" {...register("product_category", validate)} />,
            업체명: <FormInput className="min-w-[400px]" {...register("title", validate)} />,
            대표자명: <FormInput className="min-w-[400px]" {...register("owner_name", validate)} />,
            휴대전화: <FormInput className="min-w-[400px]" {...register("mobile", validate)} />,
            URL: <FormInput className="min-w-[400px]" {...register("url", validate)} />,
            주소: <FormInput className="min-w-[400px]" {...register("address", validate)} />,
            FAX: <FormInput className="min-w-[400px]" {...register("fax", validate)} />,
            유선전화: <FormInput className="min-w-[400px]" {...register("landline", validate)} />,
          }}
        />
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminAddressBookCreatePage
