import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { cn } from "@runners/lib/utils"
import { Button, Input } from "@runners/ui"
import { AdminEmailAddressBooksGroupResponse } from "@/_api/address-books.type"
import { AdminEmailSendPayload } from "@/_api/email.type"
import AdminCreateTitle from "@/_components/admin-create-title"
import { navigate } from "@/_constants/navigate"
import useToast from "@/_hooks/use-toast"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import { usePostEmailSend } from "./_hooks/send-mutations"
import GroupSelectModal from "./modal/group-select-modal"
import TemplateSelectModal from "./modal/template-select-modal"
import { useGetTemplate } from "../../template/_hooks/queries"

const AdminEmailSendCreatePage = () => {
  const [seletedTemplateId, setSelectedTemplateId] = useState<number | undefined>(undefined)
  const [selectedGroupList, setSelectedGroupList] = useState<AdminEmailAddressBooksGroupResponse[]>([])

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false)
  const titleRef = useRef<HTMLInputElement>(null)

  const toast = useToast()

  const { data: preview, isError } = useGetTemplate(seletedTemplateId ?? 1)

  const { mutate: sendEmail } = usePostEmailSend()

  const nav = useNavigate()

  const handleOpenTemplatePreview = () => {
    setIsOpenPreview(prev => !prev)
  }

  const modalStack = useModalStack()

  const openSelectTemplate = () => {
    modalStack.open({
      Component: TemplateSelectModal,
      componentProps: {
        handleSelect: selected => setSelectedTemplateId(selected.id),
      },
      key: "template-select-modal",
    })
  }

  const openSelectGroup = () => {
    if (!seletedTemplateId) {
      toast.warning("템플릿을 먼저 선택해 주세요.")
      return
    }

    modalStack.open({
      Component: GroupSelectModal,
      componentProps: {
        handleSelect: selected => setSelectedGroupList(selected),
        templateId: seletedTemplateId,
      },
      key: "template-select-modal",
    })
  }

  const handleSubmit = () => {
    if (!seletedTemplateId || !titleRef.current || selectedGroupList.length === 0) return
    if (titleRef.current.value === "") return

    const payload: AdminEmailSendPayload = {
      subject: titleRef.current.value,
      template_id: seletedTemplateId,
      group_ids: selectedGroupList.map(group => group.id),
    }

    sendEmail(payload, {
      onSuccess: () => {
        nav(navigate.send())
      },
    })
  }

  return (
    <div>
      <AdminCreateTitle onSubmit={handleSubmit} />
      <div className="m-10 flex flex-col gap-5">
        {/* Template */}
        <div>
          <div className="flex justify-between">
            <Button size="48" onClick={openSelectTemplate}>
              템플릿 선택하기
            </Button>
            <Button
              size="48"
              className={cn("text-18 font-semibold", {
                hidden: !seletedTemplateId,
              })}
              onClick={handleOpenTemplatePreview}
            >
              템플릿 미리보기
            </Button>
          </div>

          {seletedTemplateId && <p className="mt-5">선택된 템플릿 : {preview?.title}</p>}

          {preview && !isError && isOpenPreview && (
            <div className="my-4 border border-gray-500 p-5">
              <div dangerouslySetInnerHTML={{ __html: preview.content || "" }} />
            </div>
          )}
        </div>

        {/* 메일 제목과 대상 선택 */}
        <div>
          <Input className="w-full" placeholder="email 제목" ref={titleRef} />
        </div>

        {/* 수위 업체 선택 */}
        <div className="flex justify-between">
          <div>
            <p className="mb-2 text-18 font-semibold">선택된 그룹</p>
            <div className="flex flex-col gap-2">
              {selectedGroupList.length !== 0 && selectedGroupList.map(v => <p>{`Group ${v.id} (${v.count})`}</p>)}
            </div>
          </div>
          <Button className="" size="48" onClick={openSelectGroup}>
            수취업체 선택하기
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AdminEmailSendCreatePage
