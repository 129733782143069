import axios from "axios"
import qs from "qs"
import { AuthStorage } from "@/_constants/auth"
import { getAccessToken, removeCookie } from "@/_utils/cookie"

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
}

const API_VERSION = "v1"

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/${API_VERSION}`,
  timeout: 1000 * 10,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: "comma" })
  },
})

api.interceptors.request.use(config => {
  const accessToken = getAccessToken()
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error) {
      if (error.response.status === 401) {
        removeCookie(AuthStorage.ACCESS_TOKEN)
      }
    }

    return Promise.reject(error)
  },
)

export default api
