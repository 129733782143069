import { useState } from "react"
import { Button } from "@runners/ui"
import { AdminEmailAddressBooksGroupResponse } from "@/_api/address-books.type"
import CommonTable from "@/_components/table/common-table"
import withModalStackProps from "@/_hoc/with-modal-stack-props"
import ModalHeader from "./modal-header"
import { useGetEmailAddressBooksGroupList } from "../_hooks/queries"

interface GroupSelectModalProps {
  handleSelect: (selectedList: AdminEmailAddressBooksGroupResponse[]) => void
  templateId: number
}

/** ------------------------------------------------------------------------------
 * 
 * 추후 여러개 선택이 가능하도록 변경될 수 있어서 배열로 처리되고 있습니다.
 * 
 ------------------------------------------------------------------------------ */
const GroupSelectModal = withModalStackProps<GroupSelectModalProps>(props => {
  const { close, handleSelect, templateId } = props
  const [selectedGroupList, setSelectedGroupList] = useState<AdminEmailAddressBooksGroupResponse[]>([])

  const { data: groupList, observerRef } = useGetEmailAddressBooksGroupList({ template_id: templateId })

  // /**
  //  * 여러개 가능 할 때 사용
  //  */
  // const handleSelectGroup = (selected: AdminEmailAddressBooksGroupResponse) => {
  //   if (selectedGroupList.some(group => group.id === selected.id)) {
  //     const filter = selectedGroupList.filter(group => group.id !== selected.id)
  //     setSelectedGroupList(filter)
  //   } else {
  //     setSelectedGroupList(prev => [...prev, selected])
  //   }
  // }

  const handleSelectGroup = (selected: AdminEmailAddressBooksGroupResponse) => {
    setSelectedGroupList([selected])
  }

  const handleSubmit = () => {
    handleSelect(selectedGroupList)
    close()
  }

  return (
    <div className="flex size-[640px] flex-col justify-between bg-white">
      <div>
        <ModalHeader title="수취업체 선택" close={close} />
      </div>
      <div className="h-[480px] overflow-y-scroll p-5">
        <CommonTable
          widths={[1, 4]}
          data={groupList}
          pageSize={10}
          selectedRows={selectedGroupList}
          setSelectedRows={setSelectedGroupList}
          title={["id", "그룹 이름"]}
          values={{
            id: v => v.id,
            "그룹 이름": v => <p>{`Group ${v.id} (${v.count})`}</p>,
          }}
          tableDropdownList={["1" as any]}
          onClickRow={handleSelectGroup}
        />
        <div className="h-5" ref={observerRef} />
      </div>
      <div className="flex w-full flex-row gap-4 p-4">
        <Button size="48" className="w-full" color={"gray"} onClick={close}>
          취소
        </Button>
        <Button size="48" className="w-full" onClick={handleSubmit}>
          선택 완료
        </Button>
      </div>
    </div>
  )
})

export default GroupSelectModal
