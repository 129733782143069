import { DjangoListResponse, DjangoResponse } from "@runners/interface/api/django-response"
import api, { METHOD } from "."
import { AdminPostListParams, PostDto, PostListDto, PostPostPayload } from "./post.type"

const postApi = {
  /**
   * 포스트 삭제
   */
  deletePost: async ({ postId }: { postId: number }) => {
    const { data } = await api(`/admin/post/${postId}`, {
      method: METHOD.DELETE,
    })
    return data
  },

  /**
   * 포스트 조회
   */
  getPost: async ({ postId }: { postId: number }) => {
    const { data } = await api.get<DjangoResponse<PostDto>>(`/admin/post/${postId}`)
    return data
  },

  /**
   * 포스트 리스트 조회
   */
  getPostList: async (params: AdminPostListParams) => {
    const { data } = await api<DjangoListResponse<PostListDto>>(`/admin/posts`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 포스트 수정
   */
  patchPost: async ({ postId, payload }: { postId: number; payload: Partial<PostPostPayload> }) => {
    const { data } = await api(`/admin/post/${postId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 포스트 생성
   */
  postPost: async (payload: PostPostPayload) => {
    const { data } = await api(`/admin/post`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 포스트 선택 활성화
   */
  postPostActivate: async ({ post_ids }: { post_ids: number[] }) => {
    const { data } = await api(`/admin/post/activate`, {
      method: METHOD.POST,
      data: { post_ids },
    })
    return data
  },

  /**
   * 포스트 선택 비활성화
   */
  postPostDeactivate: async ({ post_ids }: { post_ids: number[] }) => {
    const { data } = await api(`/admin/post/deactivate`, {
      method: METHOD.POST,
      data: { post_ids },
    })
    return data
  },
}

export default postApi
