import React, { useEffect, useState } from "react"
import { useDebounce } from "@runners/hooks"
import { toLocalePrice } from "@runners/lib/price"
import { Button, Input } from "@runners/ui"
import { AdminProductListDto } from "@/_api/product.type"
import Image from "@/_components/image"
import CommonTable from "@/_components/table/common-table"
import withModalStackProps from "@/_hoc/with-modal-stack-props"
import { cn } from "@/_styles/cn"
import ProductListItem from "./product-list-item"
import { useGetProductListQuery } from "../../_hooks/use-get-query"

interface AddProductModalProps {
  type: "grid" | "table"
  selectedList: AdminProductListDto[]
  setSelectedList: (list: AdminProductListDto[]) => void
}

const AddProductModal = withModalStackProps<AddProductModalProps>(props => {
  const { close, type, selectedList, setSelectedList } = props

  const [innerList, setInnerList] = useState<AdminProductListDto[]>([])

  const [search, setSearch] = useState("")
  const debounced = useDebounce(search, 300)

  const { data: productList, observerRef } = useGetProductListQuery({
    search: debounced,
  })

  useEffect(() => {
    setInnerList(selectedList)
  }, [selectedList])

  const onSubmit = () => {
    if (type === "table") {
      // 기존 상품 데이터 유지 및 중복 추가된 상품 제거
      const filteredList = innerList.filter(v => !selectedList.some(s => s.id === v.id))
      setSelectedList([...selectedList, ...filteredList])
    } else {
      setSelectedList(innerList)
    }

    close()
  }

  /**
   * isSelected 토글 함수
   * 동기화를 위해 setState를 두번 실행합니다
   */
  const onClickProduct = (product: any) => {
    if (innerList.some(v => v.id === product.id)) {
      const filter = innerList.filter(v => v.id !== product.id)
      setInnerList(filter)
    } else {
      setInnerList(prev => [...prev, product])
    }
  }

  return (
    <div
      className={cn("grow bg-white p-4", {
        "w-[570px]": type === "grid",
        "w-[90vw]": type === "table",
      })}
    >
      <h3 className="py-4 text-24 font-bold">상품 추가</h3>

      <div className="flex items-center justify-between">
        <Input value={search} placeholder="상품 검색" onChange={e => setSearch(e.target.value)} />

        <div>{debounced}: 현재 검색어</div>
      </div>
      <div>현재 선택된 상품 개수: {innerList.length}</div>

      <div className="h-5" />

      {type === "grid" && (
        <div className="grid max-h-[400px] w-full grid-cols-3 gap-4 overflow-hidden overflow-y-auto pb-4">
          {productList.map(product => {
            return (
              <ProductListItem
                key={product.id}
                isSelected={innerList.some(v => v.id === product.id)}
                product={product}
                onClick={() => onClickProduct(product)}
              />
            )
          })}
          <div ref={observerRef} />
        </div>
      )}

      {type === "table" && (
        <div className="max-h-[400px] w-full overflow-hidden overflow-y-auto pb-4">
          <CommonTable
            data={productList}
            widths={[2, 2, 4, 4, 2, 3, 3, 3]}
            title={["ID", "이미지", "제품 이름", "스토어", "카테고리", "가격", "등록일", "최종수정일"]}
            pageSize={10}
            values={{
              ID: v => v.id,
              이미지: v => <Image src={v.image_url} size="375" className="size-[50px]" />,
              "제품 이름": v => v.title,
              스토어: v => v.store_title || "_",
              카테고리: v => v?.product_sub_category?.title || "-",
              가격: v => toLocalePrice(v.origin_price, "원"),
              등록일: v => v.created,
              최종수정일: v => v.modified,
            }}
            selectedRows={innerList}
            setSelectedRows={setInnerList}
            tableDropdownList={["1" as any]}
            onClickRow={onClickProduct}
          />

          <div className="h-5" ref={observerRef} />
        </div>
      )}

      <footer className="flex items-center justify-end gap-4 border-t border-gray-300 py-4">
        <Button color="white" size="40" onClick={close}>
          취소
        </Button>
        <Button size="40" className="px-7" onClick={onSubmit}>
          저장
        </Button>
      </footer>
    </div>
  )
})

export default AddProductModal
