import { PropsWithChildren } from "react"
import AdminLabel from "@/_pages/service/post/_components/admin-label"

interface AdminFieldProps extends PropsWithChildren {
  label: string
  required?: boolean
}

/**
 * 폼(생성, 수정)에서 자주 사용되는 디자인 컴포넌트
 */
const AdminField = (props: AdminFieldProps) => {
  const { label, required, children } = props
  return (
    <div className="flex items-center gap-5">
      <AdminLabel required={required}>{label}</AdminLabel>

      <div>{children}</div>
    </div>
  )
}

export default AdminField
