import { Button } from "@runners/ui"
import Divider from "@/_components/layout/divider"
import AdminCampaignProducts from "@/_pages/store/campaign/_components/fields/admin-campaign-products"
import AdminPostActive from "../../post/_components/fields/admin-post-active"
import AdminPostTags from "../../post/_components/fields/admin-post-tags"
import AdminPostThumbnail from "../../post/_components/fields/admin-post-thumbnail"
import AdminPostTitle from "../../post/_components/fields/admin-post-title"

const RankingPostForm = () => {
  return (
    <div className="max-w-[1200px]">
      <div className="flex flex-col gap-5">
        <AdminPostTitle />
        <AdminPostThumbnail />
        <AdminPostTags />
      </div>

      <Divider />

      <AdminCampaignProducts type="table" isRanking />

      <Divider />

      <div className="flex flex-col gap-5">
        <AdminPostActive />
      </div>

      <Divider />

      <div className="mt-10 flex items-center justify-end">
        <Button type="submit" size="48" className="flex-1">
          등록
        </Button>
      </div>
    </div>
  )
}

export default RankingPostForm
