import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"
import settlementQueryKey from "@/_api/_query-key/settlement"
import settlementApi from "@/_api/settlement"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetSettlementListQuery = (params: DjangoSearchOrderingParams) => {
  return useInfiniteData({
    queryKey: settlementQueryKey.settlementList(params),
    queryFn: () => settlementApi.getSettlementGroupList(params),
  })
}
