import { Controller, useFormContext } from "react-hook-form"
import ReactSelect from "react-select"
import { Button, Input } from "@runners/ui"
import { CampaignDisplayType } from "@/_api/campaign.type"
import { AdminProductListDto } from "@/_api/product.type"
import AdminFormField from "@/_components/admin-form-field"
import Divider from "@/_components/layout/divider"
import ActiveControl from "@/_components/react-hook-form/active-control"
import AdminCampaignPeriod from "./fields/admin-campaign-period"
import AdminCampaignProducts from "./fields/admin-campaign-products"
import AdminCampaignSeoInfo from "./fields/admin-campaign-seo-info"
import AdminCampaignThumbnails from "./fields/admin-campaign-thumbnails"

type ItemProps = {
  label: string
  value: string
}

export type AdminCampaignFormType = {
  start_date: string
  end_date: string
  store_title: string
  display_type: ItemProps | undefined
  thumbnail_image_url: string
  mobile_thumbnail_image_url: string
  title: string
  description: string
  seo_thumbnail_image_url: string
  detail_images_data: string[]
  products: AdminProductListDto[]
  order: string
  is_active: boolean
  is_main_display: boolean
  main_order: string
}

const CampaignForm = () => {
  const { register } = useFormContext()

  return (
    <div>
      <div className="flex flex-col gap-4">
        <h3 className="text-24 font-bold">기획전 정보</h3>
        <AdminCampaignPeriod />

        <AdminFormField label="상호명" required>
          <Input {...register("store_title")} className="min-w-[600px]" placeholder="자체진행일시 자체진행으로 표기" />
        </AdminFormField>

        <AdminFormField label="유형" required>
          <Controller
            name="display_type"
            render={({ field }) => (
              <ReactSelect
                {...field}
                className="min-w-[600px]"
                options={Object.entries(CampaignDisplayType).map(([key, value]) => ({
                  label: key,
                  value,
                }))}
              />
            )}
          />
        </AdminFormField>

        <ActiveControl name="is_main_display" label="홈화면 노출 여부" />
      </div>
      <Divider />

      <AdminCampaignThumbnails />

      <Divider />

      <AdminCampaignSeoInfo />

      <Divider />
      <AdminCampaignProducts type="table" />

      <Divider />
      <div className="flex flex-col gap-4">
        <ActiveControl name="is_active" />
        <AdminFormField label="우선도">
          <Input {...register("order")} className="min-w-[300px]" placeholder="999" type="number" />
        </AdminFormField>
        <AdminFormField label="홈화면 우선도">
          <Input {...register("main_order")} className="min-w-[300px]" placeholder="999" type="number" />
        </AdminFormField>
      </div>
      <div className="h-20" />

      <Button type="submit" size="48" className="w-full">
        저장
      </Button>
      <div className="h-40" />
    </div>
  )
}

export default CampaignForm
