import { TAB_MENU } from "@/_constants/router-tab-menu"
import { getAccessToken } from "@/_utils/cookie"
import DepthDropdown from "../depth-dropdown"

interface SideNavProps {}

/**
 *
 */
const SideNav = (props: SideNavProps) => {
  const {} = props

  const isLogin = getAccessToken()

  if (!isLogin) return <></>

  return (
    <div className="flex h-full min-h-screen shrink-0 flex-col border-r border-gray-300 bg-white p-2">
      {TAB_MENU.map(menu => {
        const renderIcon = (item: any) => item.icon || null
        return (
          <DepthDropdown
            key={menu.name}
            item={menu}
            renderIcon={renderIcon}
            renderName={(item: any) => item.name}
            renderChildren={(item: any) => item.children || []}
          />
        )
      })}
    </div>
  )
}

export default SideNav
