import userApi from "@/_api/user"

export const useAdminHackStore = () => {
  const handleClickOpenStore = (storeId: number) => async (e: React.MouseEvent) => {
    if (!storeId) return

    e.preventDefault()
    try {
      e.stopPropagation()
      await userApi.patchAdminUserMe({ store: storeId })
      window.open(`${import.meta.env.VITE_SENSE_STORE_URL}/dashboard`)
    } catch (e) {
      console.error(`e`, e)
      alert("썸띵 롱")
    }
  }

  return { onClick: handleClickOpenStore }
}
