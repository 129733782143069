import { AdminLabelListParams } from "../label.type"

const labelQueryKey = {
  label: () => ["label"],

  adminLabel: () => ["admin", "label"],
  labelList: ({ ...params }: AdminLabelListParams) => [...labelQueryKey.adminLabel(), "list", { ...params }],
  labelDetail: ({ labelId }: { labelId: number }) => [...labelQueryKey.adminLabel(), "detail", labelId],
}

export default labelQueryKey
