import { PropsWithChildren } from "react"
import AdminLabel from "@/_pages/service/post/_components/admin-label"

interface AdminFormFieldProps extends PropsWithChildren {
  label: string
  required?: boolean
}

const AdminFormField = (props: AdminFormFieldProps) => {
  const { label, children, required } = props
  return (
    <div className="flex items-center gap-5">
      <AdminLabel required={required}>{label}</AdminLabel>
      {children}
    </div>
  )
}

export default AdminFormField
