import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import campaignQueryKey from "@/_api/_query-key/campaign"
import { AdminCampaignListDto, AdminCampaignListParams, CampaignDisplayType } from "@/_api/campaign.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetCampaignListQuery } from "./_hooks/use-get-query"
import { usePostCampaignActivateMutation, usePostCampaignDeactivateMutation } from "./_hooks/use-mutation"

const AdminCampaignPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminCampaignListDto>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminCampaignListParams = {
    page,
    page_size: pageSize,
    search,
  }

  const { data: campaignList, totalCount, isLoading } = useGetCampaignListQuery(params)

  /**
   * 하단 상태 선택
   */
  const queryClient = useQueryClient()
  const { mutate: activate } = usePostCampaignActivateMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.campaignList({}) })
    },
  })
  const { mutate: deactivate } = usePostCampaignDeactivateMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: campaignQueryKey.campaignList({}) })
    },
  })

  const tableDropdownList: TableDropdownList = [
    {
      label: "선택 비활성화",
      fn: ids => {
        deactivate({ campaign_ids: ids })
      },
    },
    {
      label: "선택 활성화",
      fn: ids => {
        activate({ campaign_ids: ids })
      },
    },
  ]

  return (
    <AdminPageLayout totalCount={totalCount} create>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 제목, 이메일, 내용"
        placeholder="검색어 입력"
      />
      <p className="text-14">
        현재 검색어: <span className="font-bold">{search}</span>
      </p>
      <Table
        isLoading={isLoading}
        data={campaignList}
        activeCheck
        totalCount={totalCount}
        tableDropdownList={tableDropdownList}
        href={value => {
          return `/store/campaign/${value?.id}`
        }}
        className="min-w-[1600px]"
        title={["ID", "상태", "유형", "배너", "기획전 제목", "상호명", "기획전 URL", "기간"]}
        widths={[2, 2, 4, 4, 4, 3, 4, 6]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: TableActivateCurry(),
          유형: value => {
            const displayType =
              Object.keys(CampaignDisplayType).find(
                key => CampaignDisplayType[key as keyof typeof CampaignDisplayType] === value.display_type,
              ) || ""
            return displayType
          },
          배너: value => {
            return (
              <div className="flex items-center justify-center">
                <Image size="375" className="size-full max-h-[50px]" src={value?.thumbnail_image_url} />
              </div>
            )
          },
          "기획전 제목": value => value?.title,
          상호명: value => value?.store_title,
          "기획전 URL": value => (
            <a
              href={`${import.meta.env.VITE_SENSE_WEB_URL}/store${value?.link}`}
              onClick={e => e.stopPropagation()}
              target="_blank"
              rel="noreferrer"
              className="line-clamp-1 break-all"
            >
              {value?.link}
            </a>
          ),
          기간: value => {
            return (
              <div>
                {dayjs(value?.start_date).format("YY.MM.DD HH시 mm분")} ~{" "}
                {dayjs(value?.end_date).format("YY.MM.DD HH시 mm분")}
              </div>
            )
          },
        }}
      />

      <div className="h-10" />
    </AdminPageLayout>
  )
}

export default AdminCampaignPage
