import { keepPreviousData, useQuery } from "@tanstack/react-query"
import labelQueryKey from "@/_api/_query-key/label"
import postQueryKey from "@/_api/_query-key/post"
import productQueryKey from "@/_api/_query-key/product"
import utilsQueryKey from "@/_api/_query-key/utils"
import labelApi from "@/_api/label"
import { AdminLabelListParams } from "@/_api/label.type"
import postApi from "@/_api/post"
import { AdminPostListParams } from "@/_api/post.type"
import productApi from "@/_api/product"
import utilsApi from "@/_api/util"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetPostListQuery = (params: AdminPostListParams) => {
  return useInfiniteData({
    queryKey: postQueryKey.postList.params(params),
    queryFn: () => postApi.getPostList(params),
  })
}

export const useGetPostQuery = ({ postId }: { postId: number }) => {
  return useQuery({
    queryKey: postQueryKey.postDetail({ postId }),
    queryFn: () => postApi.getPost({ postId }),
    select: response => response.data,
  })
}

export const useGetLabelListQuery = (params: AdminLabelListParams) => {
  return useInfiniteData({
    queryKey: labelQueryKey.labelList({ ...params }),
    queryFn: () => labelApi.getLabelList({ ...params }),
  })
}

/**
 * 콘텐츠 상품 엮기
 */
export const useGetProductListQuery = (params: AdminPostListParams) => {
  return useInfiniteData({
    queryKey: productQueryKey.productList(params),
    queryFn: ({ pageParam = 1 }) => productApi.getProductList({ ...params, page: pageParam }),
    queryOptions: {
      placeholderData: keepPreviousData,
    },
  })
}

export const useGetLinkPreviewQuery = ({ url }: { url: string }) => {
  return useQuery({
    queryKey: utilsQueryKey.linkPreview(url),
    queryFn: () => utilsApi.getLinkPreview(url),
    select: ({ data }) => data,
    enabled: !!url.trim(),
    retry: 0,
  })
}
