import { PropsWithChildren } from "react"
import AdminCreateTitle from "../admin-create-title"

interface AdminCreateLayoutProps extends PropsWithChildren {
  onSubmit: () => void
}

const AdminCreateLayout = (props: AdminCreateLayoutProps) => {
  const { children, onSubmit } = props
  return (
    <div className="">
      <AdminCreateTitle onSubmit={onSubmit} />
      <div className="h-10" />
      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">{children}</div>
      <div className="h-10" />
    </div>
  )
}

export default AdminCreateLayout
