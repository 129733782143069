import { Outlet } from "react-router-dom"
import { cn } from "@/_styles/cn"
import { getAccessToken } from "@/_utils/cookie"
import Footer from "./footer"
import Header from "./header"
import HeaderFooterWrapper from "./header-footer-wrapper"
import SideNav from "./side-nav"
import AuthGuard from "../auth-guard"

interface GlobalWrapperProps {}

const GlobalWrapper = (props: GlobalWrapperProps) => {
  const {} = props

  const isLogin = getAccessToken()

  return (
    <div className="min-h-screen">
      <Header />
      <HeaderFooterWrapper
        className={cn({
          "bg-gray-300": !isLogin,
        })}
      >
        <AuthGuard>
          <div
            className={cn("grid w-screen", {
              "grid-cols-[264px_1fr]": isLogin,
              "grid-cols-1": !isLogin,
            })}
          >
            <SideNav />
            <Outlet />
          </div>
        </AuthGuard>
      </HeaderFooterWrapper>
      <Footer />
    </div>
  )
}

export default GlobalWrapper
