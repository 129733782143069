import { AdminEmailTemplateListResponse } from "@/_api/email.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { navigate } from "@/_constants/navigate"
import { useGetTemplateList } from "./_hooks/queries"
import { useDeleteEmailTemplate } from "./_hooks/template-mutations"

const AdminEmailTemplatePage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminEmailTemplateListResponse>()

  const { mutate: deleteTemplateMutate } = useDeleteEmailTemplate()

  const tableDropdownList: TableDropdownList = [
    {
      label: "삭제",
      fn: idList => {
        deleteTemplateMutate(idList)
      },
    },
  ]

  const { data, totalCount, isLoading } = useGetTemplateList({
    page,
    page_size: pageSize,
  })

  return (
    <div>
      <AdminPageLayout create>
        <Table
          totalCount={totalCount}
          href={value => navigate.templateDetail(value.id)}
          widths={[1, 5]}
          data={data}
          values={{
            ID: v => v.id,
            이름: v => v.title,
          }}
          isLoading={isLoading}
          title={["ID", "이름"]}
          tableDropdownList={tableDropdownList}
        />
      </AdminPageLayout>
    </div>
  )
}

export default AdminEmailTemplatePage
