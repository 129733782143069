import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import DetailSection from "@/_components/detail-section"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Divider from "@/_components/layout/divider"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { AdminPostForm } from "@/_pages/service/post/create/admin-post-create-page"
import { createTotal } from "@/_utils/react-select"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import PostForm from "../_components/post-form"
import { useGetPostQuery } from "../_hooks/use-get-query"
import { useDeletePostMutation, usePatchPostMutation } from "../_hooks/use-mutation"

const AdminPostDetailPage = () => {
  const { postId: _postId } = useParams()
  const postId = Number(_postId)
  const { data: postDetail } = useGetPostQuery({ postId })

  const methods = useForm<AdminPostForm>()
  const [isReady, setIsReady] = useState(false)

  /**
   * init
   */
  useEffect(() => {
    if (!postDetail) return
    // if (cityList.length === 0) return

    methods.reset({
      title: postDetail.title,
      sub_title: postDetail.sub_title,
      thumbnail_media_url: postDetail.thumbnail_media_url,
      start_date: postDetail.start_date,
      end_date: postDetail.end_date,
      content: postDetail.content,
      is_active: postDetail.is_active,
      notice: postDetail.notice,
      labels: postDetail.labels.map(label => ({ label: label.title, value: label.id })),
      tags: postDetail.tags.map(tag => tag.title),

      city: postDetail.city == null ? createTotal() : { label: postDetail.city?.title, value: postDetail.city?.id },
      sub_city:
        postDetail.sub_city == null
          ? createTotal()
          : { label: postDetail.sub_city?.title, value: postDetail.sub_city?.id },
      contact_category:
        postDetail.contact_category == null
          ? createTotal()
          : { label: postDetail.contact_category?.title, value: postDetail.contact_category?.id },
    })

    setIsReady(true)
  }, [postDetail])

  /**
   * patch
   */
  const toast = useToast()
  const updatePostMutation = usePatchPostMutation({
    onSuccess: () => {
      toast.success("수정 완료!")
    },
    onError: (e: any) => {
      console.error(`error: `, e)
      toast.error("에러!!")
    },
  })

  const onSubmit: SubmitHandler<AdminPostForm> = data => {
    const payload = {
      ...data,
      start_date: data.start_date === "" ? null : data.start_date,
      end_date: data.end_date === "" ? null : data.end_date,
      city: Number(data.city?.value) || null,
      sub_city: Number(data.sub_city?.value) || null,
      contact_category: Number(data.contact_category?.value) || null,

      labels: (data.labels || []).map(v => Number(v.value)) as number[],
    }

    updatePostMutation.mutate({ postId, payload })
  }

  /**
   * delete
   */
  const deletePostMutation = useDeletePostMutation({
    onSuccess: () => {
      toast.success("삭제 완료!")
      history.back()
    },
  })
  const modalStack = useModalStack()

  const handleDelete = () => {
    modalStack.open({
      key: "admin-post-detail-delete",
      Component: Alert,
      componentProps: {
        title: "정말 삭제하시겠습니까?",
        onConfirm: () => {
          deletePostMutation.mutate({ postId })
        },
      },
    })
  }

  if (!postDetail || !isReady) return <></>

  return (
    <AdminPageLayout onDelete={handleDelete} onUpdate={methods.handleSubmit(onSubmit)}>
      <DetailSection
        title="게시글 정보"
        values={{
          아이디: postDetail.id,
          "게시글 제목": postDetail.title,
          작성일: dayjs(postDetail.created).format("YYYY.MM.DD HH:mm:ss"),
          작성자: postDetail.user.username + ` (${postDetail.user.email})`,
          조회수: postDetail.visit_count,
          좋아요: postDetail.like_count,
          댓글: postDetail.comment_count,
          공유: postDetail.share_count,
        }}
      />

      <Divider />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PostForm />
        </form>
      </FormProvider>
    </AdminPageLayout>
  )
}

export default AdminPostDetailPage
