import { useFormContext } from "react-hook-form"
import { CHAR_BLANK } from "@runners/lib/constants"
import { toLocalePrice } from "@runners/lib/price"
import { cn } from "@runners/lib/utils"
import { Button, Input } from "@runners/ui"
import dayjs from "dayjs"
import { AdminProductListDto } from "@/_api/product.type"
import Image from "@/_components/image"
import AddProductModal from "@/_pages/service/post/_components/modal/add-product-modal"
import { useModalStack } from "@/_zustand/admin-modal-stack"

interface AdminCampaignProductsProps {
  // 상품 추가 모달의 모양
  type?: "grid" | "table"

  // 우선순위 입력하는 컬럼 유무
  isRanking?: boolean
}

const AdminCampaignProducts = (props: AdminCampaignProductsProps) => {
  const { type = "table", isRanking } = props

  const { watch, setValue } = useFormContext()

  const currentProduct = watch("products") || []

  const modalStack = useModalStack()
  const openProductModal = () => {
    modalStack.open({
      key: "post-product-tag",
      Component: AddProductModal,
      componentProps: {
        type: type,
        selectedList: currentProduct,
        setSelectedList: (productList: AdminProductListDto[]) => {
          setValue("products", productList)
        },
      },
    })
  }

  const handleDeleteRow = (index: number) => {
    const list = [...currentProduct]
    list.splice(index, 1)
    setValue("products", list)
  }

  const handleRankingChange = (id: number, value: number) => {
    const list = currentProduct.map((v: AdminProductListDto) => (v.id === id ? { ...v, order: value } : v))

    setValue("products", list)
  }

  return (
    <div>
      <div className="mb-6 flex items-center gap-10">
        <h4 className="text-20 font-bold">상품 추가</h4>
        <Button
          color="white"
          size="40"
          outline
          type="button"
          className="hover:border-primary hover:text-primary"
          onClick={openProductModal}
        >
          상품 추가
        </Button>
      </div>

      <div className="flex flex-col gap-3 pt-4">
        <div
          className={cn("grid grid-cols-[1fr,2fr,4fr,2fr,2fr,1fr,2fr,2fr,1fr] gap-4 bg-gray-100 py-4 text-center", {
            "grid-cols-[1fr,2fr,4fr,2fr,2fr,1fr,2fr,2fr,2fr,1fr]": isRanking,
          })}
        >
          <div>ID</div>
          <div>이미지</div>
          <div>상품명</div>
          <div>스토어</div>
          <div>카테고리</div>
          <div>가격</div>
          <div>등록일</div>
          <div>최종수정일</div>
          {isRanking && <div>우선도</div>}
          <div>{CHAR_BLANK}</div>
        </div>

        {currentProduct.map((product: any, i: number) => (
          <div
            key={product.id}
            className={cn("grid grid-cols-[1fr,2fr,4fr,2fr,2fr,1fr,2fr,2fr,1fr] gap-4 py-3 text-center", {
              "grid-cols-[1fr,2fr,4fr,2fr,2fr,1fr,2fr,2fr,2fr,1fr]": isRanking,
            })}
          >
            <p>{product.id}</p>
            <Image className="mx-auto size-[50px]" src={product.image_url || product.thumbnail_image_url} size="375" />
            <p className="text-left">{product.title}</p>
            <p className="text-left">{product.store_title}</p>
            <p>{product.product_sub_category?.title}</p>
            <p className="text-left">{toLocalePrice(product.discount_price)}</p>
            <p>{dayjs(product.created).format("YY.MM.DD")}</p>
            <p>{dayjs(product.modified).format("YY.MM.DD")}</p>
            {isRanking && (
              <Input
                type="number"
                name="order"
                min={0}
                className="w-full"
                defaultValue={product.order || 0}
                onChange={e => handleRankingChange(product.id, Number(e.target.value))}
              />
            )}
            <div>
              <Button size="30" color="dark" className="whitespace-nowrap px-2" onClick={() => handleDeleteRow(i)}>
                삭제
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdminCampaignProducts
