import { useQuery } from "@tanstack/react-query"
import bannerQueryKey from "@/_api/_query-key/banner"
import bannerApi from "@/_api/banner"
import { AdminBannerListParams } from "@/_api/banner.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetBannerListQuery = (params: AdminBannerListParams) => {
  return useInfiniteData({
    queryFn: () => bannerApi.getBannerList(params),
    queryKey: bannerQueryKey.bannerList(params),
  })
}

export const useGetBannerQuery = ({ bannerId }: { bannerId: number }) => {
  return useQuery({
    queryFn: () => bannerApi.getBanner({ bannerId }),
    queryKey: bannerQueryKey.detail({ bannerId }),
    select: response => response.data,
  })
}
