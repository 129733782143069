import userQueryKey from "@/_api/_query-key/user"
import userApi from "@/_api/user"
import { AdminUserListParams } from "@/_api/user.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetUserList = (params: AdminUserListParams) => {
  return useInfiniteData({
    queryKey: userQueryKey.userList({ ...params }),
    queryFn: () => userApi.getUserList({ ...params }),
  })
}
