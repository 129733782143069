import { statusOptionList } from "@runners/lib/constants"
import { toLocalePrice } from "@runners/lib/price"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import productQueryKey from "@/_api/_query-key/product"
import { AdminProductListDto } from "@/_api/product.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import StoreStatusBadge from "./_components/store-status-badge"
import { useGetProductListQuery } from "./_hooks/use-get-query"
import {
  useProductActivateMutation,
  useProductDeactivateMutation,
  useProductStatusMutation,
} from "./_hooks/use-mutation"

const ProductPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminProductListDto>()
  const { SearchInput, search } = useTableSearch()

  const {
    data: productList,
    isLoading,
    totalCount,
  } = useGetProductListQuery({
    page_size: pageSize,
    page,
    search,
  })

  const qc = useQueryClient()

  const onSuccess = () => {
    qc.invalidateQueries({ queryKey: productQueryKey.productList({}) })
  }

  const onError = (e: any) => {
    console.error(e)
    alert("error!")
  }

  const { mutate: activate } = useProductActivateMutation({ onSuccess, onError })
  const { mutate: deactivate } = useProductDeactivateMutation({ onSuccess, onError })
  const { mutate: statusChange } = useProductStatusMutation({ onSuccess, onError })

  /**
   * 상품은 드롭다운이 좀 많습니다.
   */
  const dropdownList: TableDropdownList = [
    ...statusOptionList.map(v => ({
      label: `선택상품 ${v.label}`,
      fn: (ids: number[]) => statusChange({ product_ids: ids, status: v.value }),
    })),
    {
      label: "선택상품 활성화",
      fn: ids => activate({ product_ids: ids }),
    },
    {
      label: "선택 상품비활성화",
      fn: ids => deactivate({ product_ids: ids }),
    },
  ]

  return (
    <AdminPageLayout totalCount={totalCount}>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 몰?루"
        placeholder="필터링할 검색어 입력"
      />
      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={productList}
        tableDropdownList={dropdownList}
        // href={value => {
        //   return `/service/post/${value?.id}`
        // }}
        className="min-w-[1600px]"
        title={[
          "ID",
          "상태",
          "이미지",
          "제품명",
          "스토어",
          "카테고리",
          "가격",
          "판매수",
          "재고",
          "작성자",
          "등록일",
          "최종 수정일",
        ]}
        widths={[2, 2, 2, 5, 3, 3, 3, 2, 2, 3, 2, 2]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: value => {
            return (
              <div className="flex items-center justify-center">
                <StoreStatusBadge status={value?.status as (typeof statusOptionList)[number]["value"]} />
              </div>
            )
          },
          이미지: value => {
            return <Image src={value?.image_url} size="768" />
          },
          제품명: value => value?.title,
          스토어: value => value?.store_title,
          카테고리: value => value?.product_category?.title || "-",
          가격: value => <p className="text-end">{toLocalePrice(value?.origin_price)}원</p>,
          판매수: value => value?.sold_count,
          재고: value => value?.stock_count,
          작성자: value => value?.user_email,
          등록일: value => {
            return dayjs(value?.created).format("YY.MM.DD")
          },
          "최종 수정일": value => dayjs(value?.modified).format("YY.MM.DD"),
        }}
      />
    </AdminPageLayout>
  )
}

export default ProductPage
