import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { cn } from "@/_styles/cn"
import { ModalType, useModalStack } from "@/_zustand/admin-modal-stack"

const ModalComponent = <T,>({ modal }: { modal: ModalType<T> }) => {
  const {
    //
    key: _key,
    Component,
    componentProps,
    disableBackdropClick = false,
    backdropTransparent,
    bottomDrawer,
    disableEsc,
  } = modal

  const [transition, setTransition] = useState(false)

  const close = async () => {
    history.back()
  }

  const handleClickBackdrop = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.currentTarget !== e.target) return
      if (disableBackdropClick) return
      close()
    },
    [disableBackdropClick, close],
  )

  useEffect(() => {
    window.history.pushState(null, "", window.location.href)
    setTransition(true)
  }, [])

  useEffect(() => {
    if (!disableEsc) {
      const handler = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          e.preventDefault()
          close()
        }
      }
      window.addEventListener("keydown", handler)
      return () => {
        window.removeEventListener("keydown", handler)
      }
    }
  }, [disableEsc])

  return (
    <div
      className={cn(
        "fixed inset-0 z-20 flex flex-col items-center justify-center bg-black/40 transition-all duration-[200ms]",
        { "bg-transparent": backdropTransparent || !transition },
        { "justify-end": bottomDrawer },
      )}
      onClick={handleClickBackdrop}
    >
      <div
        className={cn("transition-all duration-[200ms]", {
          "opacity-0 -translate-y-[5%]": !transition,
          "translate-y-full": bottomDrawer && !transition,
        })}
      >
        <Component close={close} {...(componentProps as T)} />
      </div>
    </div>
  )
}

const ModalProvider = () => {
  const { modalList, pop } = useModalStack()

  useEffect(() => {
    if (modalList.length > 0) {
      if (document.body.style.paddingRight !== "0px") return
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
      document.body.style.paddingRight = `${scrollBarWidth}px`
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.paddingRight = "0px"
      document.body.style.overflow = ""
    }
  }, [modalList])

  useEffect(() => {
    const handlePopState = () => {
      pop()
    }

    window.addEventListener("popstate", handlePopState)
    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [])

  return createPortal(
    modalList.map(modal => <ModalComponent key={modal.key} modal={modal} />),
    document.body,
  )
}

export default ModalProvider
