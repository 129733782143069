import React from "react"
import { cn } from "@/_styles/cn"

import styles from "./spinner.module.css"

interface SpinnerProps {
  className?: string
}

/**
 *
 */
const Spinner = ({ className }: SpinnerProps) => {
  return (
    <svg className={cn(styles.spinner, className)} viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke="#EEEEEE"></circle>
      <circle className={styles.path} cx="25" cy="25" r="20" stroke="#FF8A00" fill="none" strokeWidth="5"></circle>
    </svg>
  )
}

export default Spinner
