import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import RecommendProductForm, { RecommendProductFormType } from "../_components/recommend-product-form"
import { usePostRecommendProductGroupMutation } from "../_hooks/use-mutation"

const AdminCreateRecommendProductPage = () => {
  const methods = useForm<RecommendProductFormType>({})

  const toast = useToast()
  const nav = useNavigate()
  const { mutate } = usePostRecommendProductGroupMutation({
    onSuccess: () => {
      toast.success("등록 완료!")
      nav(-1)
    },
  })

  const onSubmit: SubmitHandler<RecommendProductFormType> = data => {
    const payload = {
      ...data,
      type: data.type.value,
      products: data.products.map(({ id, order }) => ({ id, order })),
    }

    mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RecommendProductForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminCreateRecommendProductPage
