import { useMutation } from "@tanstack/react-query"
import { MutationOptions } from "@/_api/common.type"
import labelApi from "@/_api/label"

export const useDeleteLabelListMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: labelApi.deleteLabelList,
    ...options,
  })
}

export const usePatchLabelMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: labelApi.patchLabel,
    ...options,
  })
}

export const usePostLabelMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: labelApi.postLabel,
    ...options,
  })
}

export const useActivateLabelListMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: labelApi.postLabelActivate,
    ...options,
  })
}

export const useDeactivateLabelListMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: labelApi.postLabelDeactivate,
    ...options,
  })
}
