import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"

const rankingPostQueryKey = {
  adminRankingPost: () => ["admin", "ranking-post"],
  rankingPostList: {
    all: () => [...rankingPostQueryKey.adminRankingPost(), "list"],
    params: ({ ...params }: DjangoSearchOrderingParams) => [
      ...rankingPostQueryKey.rankingPostList.all(),
      { ...params },
    ],
  },
  rankingPostDetail: ({ postId }: { postId: number }) => [...rankingPostQueryKey.adminRankingPost(), postId],
}

export default rankingPostQueryKey
