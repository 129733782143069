import { AdminAddressBookListParams } from "../address-books.type"

const addressBooksQueryKey = {
  addressBooksGroupList: {
    all: () => ["addressBooksGroup", "list"],
  },

  addressBookList: {
    all: () => ["addressBook", "list"],
    params: (params: AdminAddressBookListParams) => ["addressBook", "list", params],
  },

  addressBookdetail: (id: number) => ["addressBook", id],
}

export default addressBooksQueryKey
