import { useCallback } from "react"

export enum KeyEvent {
  ENTER = "Enter",
  ESC = "Escape",
  TAB = "Tab",
  SPACE = "Space",
  ARROW_UP = "ArrowUp",
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
}

interface useKeydownProps {
  // submit callback
  onSubmit: () => void

  // cancel callback
  onCancel?: () => void
}

/**
 * 인풋의 keyDown 이벤트를 모아놓은 훅
 */
export const useKeydown = ({ onSubmit, onCancel }: useKeydownProps) => {
  /**
   * 한국어 등의 composition 이벤트를 관리해주는 공용 함수
   */
  const _commonComposing = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.nativeEvent.isComposing) {
        const compositionendHandler = () => {
          onSubmit()
          e.target.removeEventListener("compositionend", compositionendHandler)
        }
        e.target.addEventListener("compositionend", compositionendHandler)
      } else {
        onSubmit()
      }
    },
    [onSubmit],
  )

  const onKeydownForTag = (e: React.KeyboardEvent<HTMLElement>) => {
    switch (e.key) {
      case KeyEvent.ENTER:
      case KeyEvent.TAB:
      case KeyEvent.SPACE:
        e.preventDefault()
        _commonComposing(e)
        break

      case KeyEvent.ESC:
        e.preventDefault()
        onCancel?.()
        break
    }
  }

  return {
    onKeydownForTag,
  }
}
