import React from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { MediaImageResponse } from "@/_api/media.type"
import { useUploadImageMultiple } from "@/_hooks/use-upload"
import AdminLabel from "@/_pages/service/post/_components/admin-label"
import { cn } from "@/_styles/cn"
import { CloseIcon } from "@/assets"
import Image from "../image"
import Spinner from "../spinner"

interface ImageMultipleProps {
  name: string
  label?: string
  className?: string
}

const ImageMultiple = (props: ImageMultipleProps) => {
  const { name, label = "썸네일", className } = props

  const { setValue } = useFormContext()
  const watch = useWatch()
  const currentWatch = watch[name] || []

  const { onChange, isPending } = useUploadImageMultiple({
    onSuccess: response => {
      setValue(name, [...currentWatch, ...response.data.map((v: MediaImageResponse) => v.url)])
      // setValue(name, response.data.url)
    },
  })

  const removeImage = (url: string) => {
    if (confirm("정말 지울거에오?")) {
      setValue(
        name,
        currentWatch.filter((v: string) => v !== url),
      )
    }
  }

  return (
    <div>
      <AdminLabel required>{label}</AdminLabel>
      <div className="h-2" />

      <div className={cn("flex flex-wrap items-center gap-4", className)}>
        {currentWatch.length > 0 &&
          currentWatch.map((v: string) => {
            return (
              <div key={v} className={cn("relative size-[104px]", className)}>
                <button
                  type="button"
                  onClick={() => removeImage(v)}
                  className="group absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full bg-white hover:scale-110"
                >
                  <CloseIcon className="size-4 text-gray-600 group-hover:text-gray-900" />
                </button>
                <Image className="size-full rounded-2 object-cover" size="2560" src={v} />
              </div>
            )
          })}

        <label
          className={cn(
            "relative block size-[104px] cursor-pointer rounded-2 border border-gray-300 hover:border-primary-700",
            className,
          )}
        >
          {isPending && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner />
            </div>
          )}
          <input name={name} multiple onChange={onChange} className="hidden" type="file" accept="image/*" />
        </label>
      </div>
    </div>
  )
}

export default ImageMultiple
