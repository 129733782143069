import { useFormContext } from "react-hook-form"
import { Textarea } from "@runners/ui"
import AdminLabel from "../admin-label"

interface AdminPostTitleProps {}

const AdminPostTitle = (props: AdminPostTitleProps) => {
  const {} = props

  const { register } = useFormContext()

  return (
    <div className="flex items-center gap-5">
      <AdminLabel required>제목</AdminLabel>

      <div>
        <Textarea className="w-[400px]" {...register("title")} rows={2} />
      </div>
    </div>
  )
}

export default AdminPostTitle
