import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { RecommendProductGroup } from "@/_api/recommend-product.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Spinner from "@/_components/spinner"
import useToast from "@/_hooks/use-toast"
import RecommendProductForm, { RecommendProductFormType } from "../_components/recommend-product-form"
import { useGetRecommendProductGroupQuery } from "../_hooks/use-get-query"
import { usePatchRecommendProductGroupMutation } from "../_hooks/use-mutation"

const AdminRecommendProductDetailPage = () => {
  const { recommendProductId: _recommendProductId } = useParams()
  const recommendProductId = Number(_recommendProductId)

  const { data } = useGetRecommendProductGroupQuery({ recommendProductId })

  const methods = useForm<RecommendProductFormType>()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!data) return

    methods.reset({
      title: data.title,
      tag: data.tag,
      is_active: data.is_active,
      products: data.products.map((v: any) => ({ ...v, image_url: v.thumbnail_image_url })),
      type: { label: "MAIN_HOME", value: RecommendProductGroup.MAIN_HOME },
      background_image_url: data.background_image_url,
      text_color: data.text_color,
    })

    setIsReady(true)
  }, [data])

  const toast = useToast()
  const { mutate } = usePatchRecommendProductGroupMutation({
    onSuccess: () => {
      toast.success("수정 성공")
    },
    onError: (e: any) => {
      toast.error("Error!")
      console.error(`error: `, e)
    },
  })

  const onSubmit: SubmitHandler<RecommendProductFormType> = data => {
    const payload = {
      tag: data.tag,
      title: data.title,
      background_image_url: data.background_image_url,
      text_color: data.text_color,
      is_active: data.is_active,
      products: data.products.map(({ id, order }) => ({ id, order })),
      type: data.type.value,
    }

    mutate({ recommendProductId, payload: payload })
  }

  if (!isReady)
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <Spinner />
      </div>
    )

  return (
    <AdminPageLayout onUpdate={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RecommendProductForm />
        </form>
      </FormProvider>
    </AdminPageLayout>
  )
}

export default AdminRecommendProductDetailPage
