import { useFormContext } from "react-hook-form"
import MultiSelect from "@/_components/multi-select"
import { useGetLabelListQuery } from "../../_hooks/use-get-query"
import AdminLabel from "../admin-label"

const AdminPostLabels = () => {
  const { setValue, watch } = useFormContext()
  const defaultValue = watch("labels")

  const { data: labelList } = useGetLabelListQuery({
    is_active: true,
  })

  return (
    <div className="flex items-center gap-5">
      <AdminLabel>라벨</AdminLabel>

      <MultiSelect
        className="w-full"
        defaultValue={defaultValue || []}
        options={labelList.map(v => ({ label: v.title, value: v.id }))}
        onChange={values => setValue("labels", values)}
      />
      <div className="h-20" />
    </div>
  )
}

export default AdminPostLabels
