import eventQueryKey from "@/_api/_query-key/event"
import eventApi from "@/_api/event"
import { AdminEventListParams } from "@/_api/event.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetEventList = (params: AdminEventListParams) => {
  return useInfiniteData({
    queryKey: eventQueryKey.eventList({ ...params }),
    queryFn: () => eventApi.getAdminEventList({ ...params }),
  })
}
