import { PropsWithChildren } from "react"
import SignIn from "@/_pages/sign-in/sign-in"
import { getAccessToken } from "@/_utils/cookie"

const AuthGuard = ({ children }: PropsWithChildren) => {
  const isLogin = getAccessToken()

  if (!isLogin) {
    return <SignIn />
  }

  return children
}

export default AuthGuard
