import { StoreListParams } from "../store.type"

const storeQueryKey = {
  store: () => ["store"],
  adminStore: () => ["admin", "store"],

  storeList: ({ ...params }: StoreListParams) => [...storeQueryKey.adminStore(), "list", { ...params }],
  storeDetail: ({ storeId }: { storeId: number }) => [...storeQueryKey.adminStore(), "detail", storeId],
}

export default storeQueryKey
