import { useMutation } from "@tanstack/react-query"
import campaignApi from "@/_api/campaign"
import { MutationOptions } from "@/_api/common.type"

export const usePostCampaignMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: campaignApi.postCampaign,
    ...options,
  })
}

export const usePatchCampaignMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: campaignApi.patchCampaign,
    ...options,
  })
}

export const usePostCampaignActivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: campaignApi.postCampaignActivate,
    ...options,
  })
}

export const usePostCampaignDeactivateMutation = (options: MutationOptions) => {
  return useMutation({
    mutationFn: campaignApi.postCampaignDeactivate,
    ...options,
  })
}
