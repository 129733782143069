import { Badge } from "@runners/ui"

interface TableActivateProps {
  isActive: boolean
}

/**
 * 공용 컴포넌트 PaginationTable 에서 사용하는 컴포넌트
 */
const TableActivate = (props: TableActivateProps) => {
  const { isActive } = props
  return (
    <div className="flex w-full justify-center">
      {isActive ? <Badge color="primary">활성화</Badge> : <Badge color="gray">비활성화</Badge>}
    </div>
  )
}

export default TableActivate

/**
 * 커링 기법 사용
 *
 * @example
 * ```
 * values={{
 *   ID: v => v.id,
 *   ...
 *   상태: TableActivateCurry(),
 * }}
 *
 * ```
 */
export const TableActivateCurry = () => (v: unknown & { is_active: boolean }) => (
  <TableActivate isActive={v.is_active} />
)
