import { useQuery } from "@tanstack/react-query"
import addressBooksQueryKey from "@/_api/_query-key/address-books"
import addressBooksApi from "@/_api/address-books"
import { AdminAddressBookListParams } from "@/_api/address-books.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetAddressBookList = (params: AdminAddressBookListParams) =>
  useInfiniteData({
    queryKey: addressBooksQueryKey.addressBookList.params(params),
    queryFn: () => addressBooksApi.getEmailAddressBookList(params),
  })

export const useGetAddressBook = (props: { id: number }) =>
  useQuery({
    queryKey: addressBooksQueryKey.addressBookdetail(props.id),
    queryFn: () => addressBooksApi.getEmailAddressBook(props),
    select: response => response.data,
  })
