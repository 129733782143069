import { useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Button, Input, Textarea } from "@runners/ui"
import DetailSection from "@/_components/detail-section"
import AdminField from "@/_components/layout/admin-field"
import Divider from "@/_components/layout/divider"
import ActiveControl from "@/_components/react-hook-form/active-control"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"
import AdminCampaignProducts from "../../campaign/_components/fields/admin-campaign-products"

const ProductGroupForm = () => {
  const { register } = useFormContext()

  const { productGroupId } = useParams()

  return (
    <div>
      <DetailSection
        title="상품 그룹 정보"
        values={{
          아이디: productGroupId ?? "-",
        }}
      />

      <div className="mt-6 flex flex-col gap-6">
        <ThumbnailInput name="thumbnail_image_url" className="size-20 rounded-full" />
        <AdminField label="그룹 이름" required>
          <Input {...register("name")} placeholder="기념일 선물" className="min-w-[450px]" />
        </AdminField>
        <AdminField label="타이틀" required>
          <Input {...register("title")} placeholder="소중한 사람을 위한 맞춤 선물" className="min-w-[450px]" />
        </AdminField>
        <AdminField label="서브 타이틀" required>
          <Textarea
            {...register("sub_title")}
            placeholder="감동과 취향을 전달할 수 있는 선물을 만나보세요"
            className="min-w-[450px] text-14"
            rows={2}
          />
        </AdminField>
      </div>

      <Divider />

      <div className="flex flex-col gap-8">
        <AdminField label="배너 색상" required>
          <Input {...register("banner_color")} type="color" className="w-20 cursor-pointer" />
        </AdminField>

        <AdminCampaignProducts type="table" />
        <ActiveControl name="is_active" />

        <AdminField label="우선도">
          <Input type="number" {...register("order")} placeholder="9999" className="min-w-[400px]" />
        </AdminField>
      </div>

      <Divider />

      <Button type="submit" size="48" className="w-full">
        저장
      </Button>
    </div>
  )
}

export default ProductGroupForm
