/**
 * 렌더링 포지션
 */
const POPUP_POSITION = {
  BOTTOM_CENTER: "BOTTOM_CENTER",
  TOP_CENTER: "TOP_CENTER",
  BOTTOM_LEFT: "BOTTOM_LEFT",
  TOP_LEFT: "TOP_LEFT",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
  TOP_RIGHT: "TOP_RIGHT",
}


/**
 * 오버플로우 상태를 계산합니다.
 * @param targetRect 타겟이 되는 DOM의 getBoundingClientRect() 값
 * @param popupSize 렌더링될 Portal 자식 컴포넌트의 getBoundingClientRect() 값
 * @param viewportHeight window.innerHeight
 * @param viewportWidth window.innerWidth
 * @returns 뷰포트 기준으로 모달이 짤리는지에 대한 여부
 */
const calculateOverflow = (
  targetRect: DOMRectReadOnly,
  popupSize: { width: number; height: number },
  viewportHeight: number,
  viewportWidth: number,
) => ({
  overflowBelow: targetRect.bottom + popupSize.height > viewportHeight,
  overflowAbove: targetRect.top < popupSize.height,
  overflowLeftWhenPositionCenter: targetRect.right < popupSize.width / 2,
  overflowRightWhenPositionCenter: targetRect.left + popupSize.width / 2 > viewportWidth,
  overflowRight: targetRect.left + popupSize.width > viewportWidth,
  overflowLeft: targetRect.right < 0,
})

/**
 * 모달의 top 위치를 계산합니다.
 * @param positionY 모달의 수직 위치 (TOP 또는 BOTTOM)
 * @param targetRect 타겟이 되는 DOM의 getBoundingClientRect() 값
 * @param popupSize 렌더링될 Portal 자식 컴포넌트의 getBoundingClientRect() 값
 * @param gap 타겟 요소와 모달 사이의 간격
 * @param overflow 오버플로우 상태
 * @returns 모달의 top 위치
 */
const calculateTopPosition = (
  positionY: string,
  targetRect: DOMRectReadOnly,
  popupSize: { height: number },
  gap: number,
  overflow: ReturnType<typeof calculateOverflow>,
) => {
  // TOP_ 포지션 모달값의 top계산 (top은 마이너스임)
  const bottomPositionValue = -(popupSize.height + gap)
  // BOTTOM_ 계산 (타겟 높이 + 간격)
  const topPositionValue = targetRect.height + gap

  // 모달 포지션이 BOTTOM
  if (positionY === "BOTTOM") {
    // 아래쪽으로 오버플로우가 발생하지 않으면 -top 값으로 아래쪽으로 배치
    if (!overflow.overflowBelow) {
      return topPositionValue
    }
    // 위쪽으로 오버플로우가 발생하지 않거나, 뷰포트의 높이보다 렌더링된 자식 컴포넌트가 크지 않아서 위로 올릴 수 있다면
    else if (!overflow.overflowAbove) {
      return bottomPositionValue
    }
    // 위아래 모두 짤릴때는 디폴트 포지션 반환
    else {
      return topPositionValue
    }
  }

  // 모달 포지션 TOP
  else {
    // 오버플로우가 발생하지 않으면 bottom 값으로 위로 배치
    if (!overflow.overflowAbove) {
      return bottomPositionValue
    }
    // 아래쪽으로 오버플로우가 발생하지 않거나, 자식 컴포넌트가 길어서 브라우저 위를 넘칠때 아래로 배치
    else if (!overflow.overflowBelow) {
      return topPositionValue
    }

    // 둘다 짤리면 지정한 포지션으로 반환
    else {
      return bottomPositionValue
    }
  }
}

/**
 * 모달의 left 위치를 계산합니다.
 * @param positionX 모달의 수평 위치 (LEFT, CENTER 또는 RIGHT)
 * @param targetRect 타겟이 되는 DOM의 getBoundingClientRect() 값
 * @param popupSize 렌더링될 Portal 자식 컴포넌트의 getBoundingClientRect() 값
 * @param overflow 오버플로우 상태
 * @returns 모달의 left 위치
 */
const calculateLeftPosition = (
  positionX: string,
  targetRect: DOMRectReadOnly,
  popupSize: { width: number },
  overflow: ReturnType<typeof calculateOverflow>,
) => {
  if (positionX === "CENTER") {
    if (overflow.overflowRightWhenPositionCenter) {
      return popupSize.width - targetRect.width
    } else if (overflow.overflowLeftWhenPositionCenter) {
      return 0
    } else {
      return popupSize.width / 2 - targetRect.width / 2
    }
  } else if (positionX === "RIGHT") {
    if (overflow.overflowRight) {
      return popupSize.width - targetRect.width
    } else {
      return 0
    }
  } else {
    if (overflow.overflowLeft) {
      return 0
    } else {
      return popupSize.width - targetRect.width
    }
  }
}

/**
 * 모달의 위치를 계산합니다.
 * @param position 모달의 위치 (POPUP_POSITION의 키값)
 * @param targetRect 타겟이 되는 DOM의 getBoundingClientRect() 값
 * @param gap 타겟 요소와 모달 사이의 간격
 * @param popupSize 렌더링될 Portal 자식 컴포넌트의 getBoundingClientRect() 값
 * @returns 모달의 top 및 left 위치
 */
export const calcPosition = (
  position: keyof typeof POPUP_POSITION,
  targetRect: DOMRectReadOnly,
  gap: number,
  popupSize: { width: number; height: number },
) => {
  if (!position) {
    return { top: 9999, left: 9999 }
  }

  const [positionY, positionX] = position.split("_")
  const overflow = calculateOverflow(targetRect, popupSize, window.innerHeight, window.innerWidth)

  const top = calculateTopPosition(positionY, targetRect, popupSize, gap, overflow)
  const left = calculateLeftPosition(positionX, targetRect, popupSize, overflow)

  return { top, left }
}

