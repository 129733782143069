import { DjangoResponse } from "@runners/interface/api/django-response"
import { LinkPreviewDto } from "@runners/interface/editor"
import api, { METHOD } from "."

const utilsApi = {
  getLinkPreview: async (url: string) => {
    const { data } = await api<DjangoResponse<LinkPreviewDto>>(`/utils/link-preview`, {
      method: METHOD.GET,
      params: { url },
    })
    return data
  },
}

export default utilsApi
