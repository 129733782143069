import { useMutation } from "@tanstack/react-query"
import { MutationOptions } from "@/_api/common.type"
import userApi from "@/_api/user"

export const useSignIn = (options: MutationOptions) => {
  return useMutation({
    mutationFn: userApi.postUserLogin,
    ...options,
  })
}
