import { useFormContext } from "react-hook-form"
import { Input } from "@runners/ui"
import AdminLabel from "../admin-label"

const AdminPostSubTitle = () => {
  const { register } = useFormContext()

  return (
    <div className="flex items-center gap-5">
      <AdminLabel required>소제목</AdminLabel>

      <div className="flex w-full">
        <Input className="flex-1" {...register("sub_title")} />
      </div>
    </div>
  )
}

export default AdminPostSubTitle
