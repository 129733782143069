import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import AdminLabelForm from "../_components/admin-label-form"
import { usePostLabelMutation } from "../_hooks/use-mutation"

export interface LabelFormType {
  title: string
  is_active: boolean
}

const AdminLabelCreatePage = () => {
  /**
   * 생성 form
   */
  const methods = useForm<LabelFormType>({
    defaultValues: {
      title: "",
      is_active: false,
    },
  })

  const nav = useNavigate()
  const toast = useToast()
  const { mutate } = usePostLabelMutation({
    onSuccess: () => {
      // console.log(`response`, response)
      // const { id } = response.data
      toast.success("라벨이 추가되었습니다.")
      nav(-1)
    },
    onError: e => {
      console.error("error:", e)
      toast.error("에러!")
    },
  })

  const onSubmit: SubmitHandler<LabelFormType> = data => {
    mutate({ payload: data })
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <AdminLabelForm />
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminLabelCreatePage
