import { PropsWithChildren } from "react"
import { cn } from "@/_styles/cn"

interface HeaderFooterWrapperProps extends PropsWithChildren {
  className?: string
}

/**
 * 어드민 헤더 64px
 * 바뀐 footer 116px
 */
const HeaderFooterWrapper = (props: HeaderFooterWrapperProps) => {
  const { children, className } = props
  return <div className={cn("min-h-[calc(100vh-64px-116px)]", className)}>{children}</div>
}

export default HeaderFooterWrapper
