import { useMutation } from "@tanstack/react-query"
import mediaApi from "@/_api/media"
import { MutationOptionsType } from "@/_types/react-query.type"

/**
 * 이미지 단일 업로드
 */
export const useUploadImage = (options: MutationOptionsType) => {
  const imageMutation = useMutation({
    mutationFn: mediaApi.postImage,
    ...options,
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (!files || files.length === 0) return

    const file = files[0]
    const formData = new FormData()
    formData.append("file", file)
    imageMutation.mutate({ formData })

    e.target.value = ""
  }

  return {
    ...imageMutation,
    onChange,
  }
}

/**
 * 이미지 여러장 업로드
 */
export const useUploadImageMultiple = (options: MutationOptionsType) => {
  const imageMutation = useMutation({
    mutationFn: mediaApi.postImages,
    ...options,
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (!files || files.length === 0) return

    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append("files", file)
    }
    imageMutation.mutate({ formData })

    e.target.value = ""
  }

  return {
    ...imageMutation,
    onChange,
  }
}
