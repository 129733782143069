import { create } from "zustand"

export interface ModalType<T> {
  key: string
  Component: (props: T) => JSX.Element | null
  componentProps?: Omit<T, "close">
  backdropTransparent?: boolean
  disableBackdropClick?: boolean
  bottomDrawer?: boolean
  disableEsc?: boolean
}

interface ModalStore {
  modalList: ModalType<unknown>[]
  open: <T>(modal: ModalType<T>) => void
  remove: (key: string) => void
  pop: () => void
  clear: () => void
}

const modalStore = create<ModalStore>(set => ({
  modalList: [],
  open: <T>(modal: ModalType<T>) => set(state => ({ modalList: [...state.modalList, modal] as ModalType<unknown>[] })),
  remove: (key: string) => set(state => ({ modalList: state.modalList.filter(modal => modal.key !== key) })),
  pop: () => set(state => ({ modalList: state.modalList.slice(0, -1) })),
  clear: () => set({ modalList: [] }),
}))

/**
 * RelativePortal 컴포넌트에서 사용되는 훅
 */
export const useModalStack = () =>
  modalStore(state => ({
    modalList: state.modalList,
    open: state.open,
    remove: state.remove,
    pop: state.pop,
    clear: state.clear,
  }))
