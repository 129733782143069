import { AdminCampaignListParams } from "../campaign.type"

const campaignQueryKey = {
  campaign: () => ["campaign"],
  adminCampaign: () => ["admin", "campaign"],

  campaignList: ({ ...params }: AdminCampaignListParams) => [
    ...campaignQueryKey.adminCampaign(),
    "list",
    { ...params },
  ],
  campaignDetail: ({ campaignId }: { campaignId: number }) => [
    ...campaignQueryKey.adminCampaign(),
    "detail",
    campaignId,
  ],
}

export default campaignQueryKey
