import { Button } from "@runners/ui"
import { cn } from "@/_styles/cn"

export interface AlertProps {
  title?: React.ReactNode
  content?: React.ReactNode
  className?: string
  cancelText?: string
  okText?: string
  submitOnly?: boolean
  messageOnly?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  close?: () => void
}

const Alert = ({
  title,
  content,
  className,
  cancelText,
  okText,
  submitOnly = false,
  messageOnly = false,
  onConfirm,
  onCancel,
  close,
}: AlertProps) => {
  const handleClickConfirm = () => {
    close?.()
    onConfirm?.()
  }

  const handleClickCancel = () => {
    close?.()
    onCancel?.()
  }
  return (
    <div
      className={cn("flex w-[314px] flex-col items-center gap-8 rounded-2 bg-white p-4 pt-6 shadow-popup", className)}
    >
      <div className="flex flex-col gap-3">
        {typeof title === "string" ? (
          <span className="line-clamp-2 text-center text-18 font-bold text-gray-900">
            {title.split("\n").map(text => {
              return (
                <span key={text}>
                  {text}
                  <br />
                </span>
              )
            })}
          </span>
        ) : (
          title
        )}
        {typeof content === "string" ? (
          <span className="whitespace-pre-wrap text-center text-14 font-medium text-gray-700">{content}</span>
        ) : (
          content
        )}
      </div>

      {!messageOnly && (
        <div className="flex w-full gap-2">
          {!submitOnly && (
            <Button size="40" color="gray" className="flex-1" onClick={handleClickCancel}>
              {cancelText || "취소"}
            </Button>
          )}
          <Button size="40" color="primary" className="flex-1" onClick={handleClickConfirm}>
            {okText || "확인"}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Alert
