import { useEffect } from "react"
import { IntersectionOptions, useInView } from "react-intersection-observer"
import { DjangoListResponse } from "@runners/interface/api/django-response"
import { QueryKey, UseInfiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query"

interface InfiniteDataFunction<TArguments extends object, TResponse> {
  (args: TArguments & { pageParam: number }): Promise<DjangoListResponse<TResponse>>
  params?: any
}

interface UseInfiniteDataProps<T> {
  queryKey: QueryKey
  queryFn: InfiniteDataFunction<any, T>
  queryOptions?: Omit<
    UseInfiniteQueryOptions<any, unknown, any, any, any>,
    "initialPageParam" | "getNextPageParam" | "queryKey" | "queryFn"
  >
  intersectionOptions?: IntersectionOptions
}

export const useInfiniteData = <T>({
  queryKey,
  queryFn,
  queryOptions,
  intersectionOptions,
}: UseInfiniteDataProps<T>) => {
  const { ref: observerRef, inView } = useInView(intersectionOptions)

  const infiniteQuery = useInfiniteQuery({
    queryKey,
    queryFn,
    ...queryOptions,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPage) => {
      if (!lastPage?.next) {
        return null
      }
      return allPage?.length + 1
    },
  })

  const { data, isFetching, hasNextPage, fetchNextPage } = infiniteQuery

  useEffect(() => {
    if (inView && !isFetching && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, isFetching, hasNextPage, fetchNextPage])

  const dataList: T[] = data?.pages?.flatMap((page: any) => page?.data) ?? []
  const totalCount = data?.pages?.[0]?.count ?? 0

  return { ...infiniteQuery, data: dataList, totalCount, observerRef }
}
