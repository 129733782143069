import React, { Dispatch, SetStateAction } from "react"
import { Link } from "react-router-dom"
import { CHAR_BLANK } from "@runners/lib/constants"
import { cn } from "@/_styles/cn"
import { TableDropdownItem } from "./table-dropdown"

interface CommonTableProps<TData, TKey extends string> {
  title: TKey[]
  values: { [key in TKey]: (data: TData) => string | number | JSX.Element }
  data: TData[]
  pageSize: number

  selectedRows: TData[]
  setSelectedRows: Dispatch<SetStateAction<TData[]>>

  isLoading?: boolean
  // optional
  widths?: number[]
  tableDropdownList?: TableDropdownItem[]
  activeCheck?: boolean
  href?: (data: TData) => string
  onClickRow?: (data: TData) => void
}

const CommonTable = <TData, TKey extends string>(props: CommonTableProps<TData, TKey>) => {
  const {
    isLoading,
    data,
    title,
    selectedRows,
    setSelectedRows,
    values,
    widths,
    activeCheck,
    tableDropdownList = [],
    pageSize,
    href,
    onClickRow = () => {},
  } = props

  /**
   * 셀 체크박스
   */
  const isSelectable = tableDropdownList.length > 0

  const toggleRow = (row: TData) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter(r => r !== row))
    } else {
      setSelectedRows([...selectedRows, row])
    }
  }

  const toggleAllRows = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([])
    } else {
      setSelectedRows([...data])
    }
  }

  return (
    <table className="mb-6 table w-full table-fixed whitespace-normal text-center align-middle">
      {widths && widths.length === title.length && (
        <colgroup>
          {isSelectable && <col width="1%" />}
          {widths.map((width, i) => (
            <col key={`${i}_col_${width}`} width={width + "%"} />
          ))}
        </colgroup>
      )}

      {/* head */}
      <thead>
        <tr className="rounded-t-2 bg-gray-100">
          {isSelectable && (
            <th className="align-middle">
              <input
                className="size-5"
                type="checkbox"
                checked={selectedRows.length === data.length}
                onChange={toggleAllRows}
              />
            </th>
          )}
          {title.map((v, i) => {
            return (
              <th
                className="relative whitespace-nowrap break-words p-4 text-14 before:absolute before:right-0 before:top-1/2 before:h-5 before:w-px before:-translate-y-1/2 before:bg-gray-300"
                key={`${v}_${i}`}
              >
                <p className="w-full">{v}</p>
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        {isLoading &&
          [...new Array(pageSize)].map((_, i) => (
            <tr key={i} className="">
              <td colSpan={title.length} className="skeleton-animation border-b border-gray-300 p-4 text-14">
                {CHAR_BLANK}
              </td>
            </tr>
          ))}

        {isLoading === false && (data == null || data.length === 0) && (
          <tr className="text-40">
            <td rowSpan={pageSize} colSpan={title.length} className="pt-10">
              ¯\_( ͡~ ͜ʖ ͡°)_/¯
              <br />
              <br />
              데이터가 없어요
            </td>
          </tr>
        )}

        {!isLoading &&
          !!data?.length &&
          data.map((data, i) => {
            // 아오 any
            const isChecked = selectedRows.some((row: any) => row.id === (data as any).id)

            return (
              <tr key={`${i}_tr`} className="border-b border-gray-300 hover:bg-gray-100">
                {isSelectable && (
                  <td className="p-4 align-middle text-14">
                    <input
                      data-test-id={`table-checkbox-${i + 1}`}
                      className="size-4"
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => toggleRow(data)}
                    />
                  </td>
                )}

                {title.map(title => {
                  const tdClassName = "whitespace-normal break-words text-14"
                  const valueFn = values[title as keyof typeof values] // 타입 단언을 사용
                  const value = valueFn ? valueFn(data) : null
                  const isActive = activeCheck ? (data as TData & { is_active: boolean }).is_active : true

                  if (href == null) {
                    return (
                      <td
                        key={`${i}_${title}`}
                        className={cn(tdClassName, "p-4", { "opacity-50": !isActive })}
                        onClick={() => onClickRow(data)}
                      >
                        <React.Fragment key={i}>{value ?? <></>}</React.Fragment>
                      </td>
                    )
                  }

                  return (
                    <td key={`${i}_${title}`} className={cn(tdClassName, { "opacity-50": !isActive })}>
                      <Link className="block p-4" to={href(data)}>
                        <React.Fragment key={i}>{value ?? <></>}</React.Fragment>
                      </Link>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        {/* data rendering */}
      </tbody>
    </table>
  )
}

export default CommonTable
