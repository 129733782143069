import { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Badge, Button, Input, Switch } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import labelQueryKey from "@/_api/_query-key/label"
import { AdminLabelListDto } from "@/_api/label.type"
import DetailSection from "@/_components/detail-section"
import Divider from "@/_components/layout/divider"
import useToast from "@/_hooks/use-toast"
import { usePatchLabelMutation } from "../_hooks/use-mutation"

interface AdminLabelFormType {
  labelId?: number
  initialValues?: AdminLabelListDto
}

const AdminLabelForm = (props: AdminLabelFormType) => {
  const { labelId = 0, initialValues } = props

  const isEdit = !!initialValues

  const { setValue, register } = useFormContext()

  const { title, is_active } = useWatch()

  /** ------------------------------------------------------------------------------
   * 
   * 수정용
   * 
   ------------------------------------------------------------------------------ */
  useEffect(() => {
    if (initialValues) {
      setValue("title", initialValues.title)
      setValue("is_active", initialValues.is_active)
    }
  }, [initialValues])

  // 수정시에만 사용됨. 생성은 create-page 윗단에서 onSubmit 사용
  const qc = useQueryClient()
  const toast = useToast()
  const { mutate: mutate } = usePatchLabelMutation({
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: labelQueryKey.labelDetail({ labelId }) })
      toast.success("수정되었습니다.")
    },
    onError: e => {
      console.error(`error`, e)
      toast.error("에러!")
    },
  })

  return (
    <div>
      <DetailSection
        title={isEdit ? "라벨 정보" : "라벨 생성"}
        values={{
          아이디: initialValues?.id || "생성중",
          라벨명: (
            <div className="flex w-full gap-4">
              <Input
                autoComplete="off"
                className="min-w-[400px]"
                placeholder="라벨을 등록하세요"
                {...register("title")}
              />
              {isEdit && (
                <Button
                  size="40"
                  className="px-6"
                  type="button"
                  color="primary"
                  onClick={() => {
                    mutate({ labelId, payload: { title } })
                  }}
                >
                  수정
                </Button>
              )}
            </div>
          ),
        }}
      />

      <Divider />

      <DetailSection
        title="권한 설정"
        values={{
          "노출 여부": (
            <div className="flex w-full gap-4">
              <div className="flex min-w-[200px] items-center gap-4">
                <Switch size="24" {...register("is_active")} checked={is_active} />
                <Badge color={is_active ? "primary" : "gray"}>{is_active ? "활성화" : "비활성화"}</Badge>
              </div>
              {isEdit && (
                <Button
                  size="40"
                  className="px-6"
                  type="button"
                  color="primary"
                  onClick={() => {
                    mutate({ labelId, payload: { is_active } })
                  }}
                >
                  저장
                </Button>
              )}
            </div>
          ),
        }}
      />
    </div>
  )
}

export default AdminLabelForm
