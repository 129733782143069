import ReactSelect, { MultiValue } from "react-select"

interface MultiSelectProps<T> {
  options: T[]
  onChange: (value: MultiValue<T>) => void
  defaultValue?: T[]
  className?: string
}

const MultiSelect = <T,>(props: MultiSelectProps<T>) => {
  const { options, onChange, defaultValue, className } = props

  if (!options || options.length === 0) return <></>

  return (
    <ReactSelect
      className={className}
      defaultValue={defaultValue}
      isMulti
      name="colors"
      options={options}
      onChange={onChange}
      classNamePrefix="select"
    />
  )
}

export default MultiSelect
