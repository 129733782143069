import { FieldError, FieldErrors } from "react-hook-form"

interface useFormInvalidProps {
  onError: (error: FieldError) => void
}

function isObject(obj: any): obj is object {
  return obj && typeof obj === "object"
}

function validateMessageObject(obj: { [key: string]: any }): obj is FieldError {
  if (!isObject(obj) || Array.isArray(obj)) {
    return false
  }

  const hasMessage = typeof obj.message === "string"
  const hasType = typeof obj.type === "string"

  return hasMessage && hasType
}

function searchErrorObject(obj: { [key: string]: any }): FieldError | null | undefined {
  if (!isObject(obj)) {
    return null
  }

  if (validateMessageObject(obj)) {
    return obj
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && isObject(obj[key])) {
      const result = searchErrorObject(obj[key])
      if (result !== null) {
        return result
      }
    }
  }

  return null
}

export const useFormInvalid = (props: useFormInvalidProps) => {
  const { onError } = props
  return (errors: FieldErrors) => {
    const firstError = searchErrorObject(errors)

    onError(firstError!!)
  }
}
