import { useMutation } from "@tanstack/react-query"
import rankingPostApi from "@/_api/ranking-post"
import { MutationOptionsType } from "@/_types/react-query.type"

export const usePostRankingPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: rankingPostApi.postRankingPost,
    ...options,
  })
}

export const usePatchRankingPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: rankingPostApi.patchRankingPost,
    ...options,
  })
}

export const useDeleteRankingPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: rankingPostApi.deleteRankingPost,
    ...options,
  })
}

export const usePostActivateRankingPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: rankingPostApi.postRankingPostActivate,
    ...options,
  })
}

export const usePostDeactivateRankingPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: rankingPostApi.postRankingPostDeactivate,
    ...options,
  })
}
