import { useMutation } from "@tanstack/react-query"
import { emailApi } from "@/_api/email"

export const useCreateEmailTemplate = () =>
  useMutation({
    mutationFn: emailApi.postCreateEmailTemplate,
  })

export const useDeleteEmailTemplate = () =>
  useMutation({
    mutationFn: emailApi.deleteEmailTemplate,
  })

export const usePatchEmailTemplate = () =>
  useMutation({
    mutationFn: emailApi.patchEmailTemplate,
  })
