import { useFormContext, useWatch } from "react-hook-form"
import { AdminPostEditor } from "@runners/editor"
import "@runners/editor/dist/style.css"
import { AdminComment, LinkPreviewDto } from "@runners/interface/editor"
import { AdminProductListDto } from "@/_api/product.type"
import AddCommentModal from "@/_pages/service/post/_components/modal/add-comment-modal"
import AddLinkModal from "@/_pages/service/post/_components/modal/add-link-modal"
import AddProductModal from "@/_pages/service/post/_components/modal/add-product-modal"
import { useModalStack } from "@/_zustand/admin-modal-stack"

const AdminPostContents = () => {
  const modalStack = useModalStack()

  const { setValue } = useFormContext()
  const { content } = useWatch()

  const onLinkPreview = () => {
    return new Promise<LinkPreviewDto>(resolve => {
      modalStack.open({
        Component: AddLinkModal,
        componentProps: {
          onSubmit: (data: LinkPreviewDto) => {
            resolve(data)
          },
        },
        key: "링크프리뷰모달",
        disableBackdropClick: true,
      })
    })
  }

  const onSelectProduct = () => {
    return new Promise<AdminProductListDto[]>(resolve => {
      modalStack.open({
        Component: AddProductModal,
        componentProps: {
          type: "grid",
          selectedList: [],
          setSelectedList: (productList: AdminProductListDto[]) => {
            resolve(productList)
          },
        },
        key: "상품추가모달",
      })
    })
  }

  const onInputComment = () => {
    return new Promise<AdminComment>(resolve => {
      modalStack.open({
        Component: AddCommentModal,
        componentProps: {
          onSubmit: (data: AdminComment) => {
            resolve(data)
          },
        },
        key: "커멘트 추가 모달",
        disableBackdropClick: true,
      })
    })
  }

  const onChangeEditor = (contents: string) => {
    setValue("content", contents)
  }

  return (
    <AdminPostEditor
      onLinkPreview={onLinkPreview}
      onSelectProduct={onSelectProduct}
      onInputComment={onInputComment}
      onChangeEditor={onChangeEditor}
      initialValues={content}
    />
  )
}

export default AdminPostContents
