import { AuthStorage } from "@/_constants/auth"

export const getCookie = (cName: string): string => {
  const name = `${cName}=`
  const ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const setCookie = (cName: string, cValue: string): void => {
  const expiration = new Date()
  expiration.setTime(new Date().getTime() + 3600000 * 24 * 30 * 3 /*7*/)
  document.cookie = `${cName}=${cValue};Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`
}

export const removeCookie = (cName: string): void => {
  const expiration = new Date()
  expiration.setTime(0)
  document.cookie = `${cName}=;Secure;SameSite=Strict;Path=/;Expires=${expiration.toUTCString()}`
}

/**
 * AccessToken 가져오기
 */
export const getAccessToken = () => {
  return getCookie(AuthStorage.ACCESS_TOKEN)
}

export const hasAccessToken = () => {
  const token = getAccessToken()
  return !!token && token !== "undefined" && token !== "null"
}
