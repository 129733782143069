/**
 * TODO:
 * - 1. pagination
 * - 2. 선택시 닫힘
 * - 3.
 */

import { AdminEmailTemplateResponse } from "@/_api/email.type"
import CommonTable from "@/_components/table/common-table"
import withModalStackProps from "@/_hoc/with-modal-stack-props"
import { useGetTemplateList } from "@/_pages/email/template/_hooks/queries"
import ModalHeader from "./modal-header"

interface TemplateSelectModalProps {
  handleSelect: (selected: AdminEmailTemplateResponse) => void
}

const TemplateSelectModal = withModalStackProps<TemplateSelectModalProps>(props => {
  const { handleSelect, close } = props
  const { data: templateList, observerRef, isLoading } = useGetTemplateList({})

  const handleSelectTemplate = (selected: AdminEmailTemplateResponse) => {
    handleSelect(selected)
    close()
  }

  return (
    <div className="size-[640px] bg-white">
      <ModalHeader close={close} title="템플릿 선택" />
      <div className="h-[480px] overflow-y-scroll p-5">
        <CommonTable
          widths={[1, 4]}
          selectedRows={[]}
          setSelectedRows={() => {}}
          pageSize={10}
          data={templateList}
          isLoading={isLoading}
          title={["id", "템플릿 이름"]}
          values={{
            id: v => v.id,
            "템플릿 이름": v => v.title,
          }}
          onClickRow={handleSelectTemplate}
        />

        <div className="h-5" ref={observerRef} />
      </div>
    </div>
  )
})

export default TemplateSelectModal
