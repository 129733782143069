import { useFormContext, useWatch } from "react-hook-form"
import { useUploadImage } from "@/_hooks/use-upload"
import AdminLabel from "@/_pages/service/post/_components/admin-label"
import { cn } from "@/_styles/cn"
import { CloseIcon } from "@/assets"
import Image from "../image"
import Spinner from "../spinner"

interface ThumbnailInputProps {
  name: string
  label?: string
  className?: string
}

const ThumbnailInput = (props: ThumbnailInputProps) => {
  const { name, label = "썸네일", className } = props

  const { setValue } = useFormContext()
  const watch = useWatch()
  const currentWatch = watch[name]

  const { onChange, isPending } = useUploadImage({
    onSuccess: response => {
      setValue(name, response.data.url)
    },
  })

  const removeImage = () => {
    if (confirm("정말 지울거에오?")) {
      setValue(name, null)
    }
  }

  return (
    <div>
      <AdminLabel required>{label}</AdminLabel>

      <div className={cn("relative mt-2 size-[330px] rounded-2", className)}>
        {currentWatch && (
          <>
            <button
              type="button"
              onClick={removeImage}
              className="group absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full bg-white hover:scale-110"
            >
              <CloseIcon className="size-4 text-gray-600 group-hover:text-gray-900" />
            </button>
            <Image className="size-full rounded-2 object-cover" size="2560" src={currentWatch} />
          </>
        )}

        {!currentWatch && (
          <label
            className={cn(
              "relative mt-2 block size-[330px] cursor-pointer rounded-2 border border-gray-300 hover:border-primary-700",
              className,
            )}
          >
            {isPending && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner />
              </div>
            )}
            <input name={name} onChange={onChange} className="hidden" type="file" accept="image/*" />
          </label>
        )}
      </div>
    </div>
  )
}

export default ThumbnailInput
