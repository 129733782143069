/**
 * 레이아웃 쉬프트를 방지용 BLANK
 */
export const CHAR_BLANK = "ㅤ"



/** ------------------------------------------------------------------------------
 * 
 * for store
 * 
 ------------------------------------------------------------------------------ */
export const statusOptionList = [
  { value: "PENDING", label: "승인대기" },
  { value: "HOLD", label: "승인보류" },
  { value: "ON_SALE", label: "판매중" },
  { value: "SUSPEND", label: "판매중지" },
  { value: "OUT_OF_STOCK", label: "일시품절" },
] as const