import productQueryKey from "@/_api/_query-key/product"
import productApi from "@/_api/product"
import { AdminProductListParams } from "@/_api/product.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetProductListQuery = (params: AdminProductListParams) => {
  return useInfiniteData({
    queryKey: productQueryKey.productList(params),
    queryFn: () => productApi.getProductList(params),
  })
}
