import { useQuery } from "@tanstack/react-query"
import labelQueryKey from "@/_api/_query-key/label"
import labelApi from "@/_api/label"
import { AdminLabelListParams } from "@/_api/label.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetLabelList = (params: AdminLabelListParams) => {
  return useInfiniteData({
    queryKey: labelQueryKey.labelList({ ...params }),
    queryFn: () => labelApi.getLabelList({ ...params }),
  })
}

export const useGetLabelQuery = ({ labelId }: { labelId: number }) => {
  return useQuery({
    queryKey: labelQueryKey.labelDetail({ labelId }),
    queryFn: () => labelApi.getLabel({ labelId }),
    select: response => response.data,
  })
}
