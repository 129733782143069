import { DjangoListResponse, DjangoResponse } from "@runners/interface/api/django-response"
import api, { METHOD } from "."
import {
  AdminCampaignDto,
  AdminCampaignListDto,
  AdminCampaignListParams,
  PatchAdminCampaignPayload,
  PostAdminCampaignPayload,
} from "./campaign.type"

const campaignApi = {
  /**
   * 기획전 리스트 조회
   */
  getCampaignList: async (params: AdminCampaignListParams) => {
    const { data } = await api<DjangoListResponse<AdminCampaignListDto>>(`/admin/campaigns`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 기획전 조회
   */
  getCampaign: async ({ campaignId }: { campaignId: number }) => {
    const { data } = await api<DjangoResponse<AdminCampaignDto>>(`/admin/campaign/${campaignId}`)
    return data
  },

  /**
   * 기획전 생성
   */
  postCampaign: async (payload: PostAdminCampaignPayload) => {
    const { data } = await api("/admin/campaign", {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 기획전 수정
   */
  patchCampaign: async ({ campaignId, payload }: PatchAdminCampaignPayload) => {
    const { data } = await api(`/admin/campaign/${campaignId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 기획전 선택 활성화
   */
  postCampaignActivate: async ({ campaign_ids }: { campaign_ids: number[] }) => {
    const { data } = await api(`/admin/campaign/activate`, {
      method: METHOD.POST,
      data: { campaign_ids },
    })
    return data
  },

  /**
   * 기획전 선택 비활성화
   */
  postCampaignDeactivate: async ({ campaign_ids }: { campaign_ids: number[] }) => {
    const { data } = await api(`/admin/campaign/deactivate`, {
      method: METHOD.POST,
      data: { campaign_ids },
    })
    return data
  },
}

export default campaignApi
