import { useFormContext, useWatch } from "react-hook-form"
import Image from "@/_components/image"
import Spinner from "@/_components/spinner"
import { useUploadImage } from "@/_hooks/use-upload"
import { CloseIcon } from "@/assets"
import AdminLabel from "../admin-label"

interface AdminPostThumbnailProps {}

const AdminPostThumbnail = (props: AdminPostThumbnailProps) => {
  const {} = props

  const { setValue } = useFormContext()

  const watch = useWatch()

  const { onChange, isPending } = useUploadImage({
    onSuccess: response => {
      setValue("thumbnail_media_url", response.data.url)
    },
  })

  const removeImage = () => {
    if (confirm("정말 지울거에오?")) {
      setValue("thumbnail_media_url", null)
    }
  }

  return (
    <div>
      <AdminLabel required>썸네일</AdminLabel>

      <div className="relative mt-2 h-[330px] w-full rounded-2">
        {watch?.thumbnail_media_url && (
          <>
            <button
              type="button"
              onClick={removeImage}
              className="group absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full bg-white hover:scale-110"
            >
              <CloseIcon className="size-4 text-gray-600 group-hover:text-gray-900" />
            </button>
            <Image className="size-full rounded-2 object-cover" size="2560" src={watch?.thumbnail_media_url} />
          </>
        )}

        {!watch?.thumbnail_media_url && (
          <label className="relative mt-2 block h-[330px] w-full cursor-pointer rounded-2 border border-gray-300 hover:border-primary-700">
            {isPending && (
              <div className="absolute inset-0">
                <Spinner />
              </div>
            )}
            <input name="thumbnail_media_url" onChange={onChange} className="hidden" type="file" accept="image/*" />
          </label>
        )}
      </div>
    </div>
  )
}

export default AdminPostThumbnail
