interface DetailSectionProps {
  title: string
  values: { [key: string]: number | string | JSX.Element }

  className?: string
}

/**
 * 공용 디테일 정보 노출
 */
const DetailSection = (props: DetailSectionProps) => {
  const { title, values, className } = props
  return (
    <div className={className}>
      <div className="mb-6 flex items-center justify-between">
        <SectionTitle title={title} />
      </div>

      <div className="flex flex-col gap-5">
        {Object.entries(values).map(([key, value]) => (
          <div key={key} className="flex items-center">
            <p className="mr-5 w-[240px] shrink-0 text-14 font-bold">{key}</p>

            {typeof value === "string" && <p className="grow text-14">{value}</p>}
            {typeof value !== "string" && value}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DetailSection

export const SectionTitle = (props: Pick<DetailSectionProps, "title">) => {
  const { title } = props
  return <h4 className="text-20 font-bold">{title}</h4>
}
