import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { AdminProductListDto } from "@/_api/product.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Divider from "@/_components/layout/divider"
import useToast from "@/_hooks/use-toast"
import ProductGroupForm from "../_components/product-group-form"
import { useGetProductGroupQuery } from "../_hooks/use-get-query"
import { usePatchProductGroupMutation } from "../_hooks/use-mutation"

type AdminProductGroupFormType = {
  thumbnail_image_url: string
  name: string
  title: string
  sub_title: string
  banner_color: string
  is_active: boolean
  order: number
  products: (AdminProductListDto & { image_url: string })[]
}

const AdminProductGroupDetailPage = () => {
  const { productGroupId: _productGroupId } = useParams()
  const productGroupId = Number(_productGroupId)

  const { data } = useGetProductGroupQuery({ productGroupId })
  const [isReady, setIsReady] = useState(false)

  const methods = useForm<AdminProductGroupFormType>()

  const toast = useToast()
  const { mutate } = usePatchProductGroupMutation({
    onSuccess: () => {
      toast.success("수정 되었습니다.")
      history.back()
    },
  })

  const onSubmit: SubmitHandler<AdminProductGroupFormType> = data => {
    const { products, ...rest } = data

    const payload = {
      ...rest,
      product_ids: (products || []).map(v => v.id),
    }

    mutate({ productGroupId, payload })
  }

  useEffect(() => {
    if (!data) return

    methods.reset({
      ...data,
      products: (data?.products || []).map(v => ({ ...v, image_url: v.thumbnail_image_url })),
    })

    setIsReady(true)
  }, [data])

  return (
    <AdminPageLayout onUpdate={methods.handleSubmit(onSubmit)}>
      {isReady && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ProductGroupForm />
          </form>
        </FormProvider>
      )}

      <Divider />

      {/* <DetailSection
        title="작성자 정보 / 등록일"
        values={{
          이름: data?.user?.username || "-",
          이메일: data?.user?.email || "-",
          " ": CHAR_BLANK,
          등록일: dayjs(data?.created).format("YY.MM.DD"),
          수정일: dayjs(data?.modified).format("YY.MM.DD"),
        }}
      /> */}
    </AdminPageLayout>
  )
}

export default AdminProductGroupDetailPage
