import { Card } from "@runners/ui"
import { SenseLogo } from "@/assets"
import LoginForm from "./components/login-form"

interface SignInProps {}

const SignIn = (props: SignInProps) => {
  const {} = props
  return (
    <div className="pb-9 pt-12">
      <Card className="w-[450px]">
        <div className="flex flex-col items-center justify-center">
          <SenseLogo className="h-14 text-primary" />
          <p className="mt-4 text-20 text-primary">관 리 자</p>

          <span className="mt-3 text-gray-600">관리자 외에는 접근할 수 없습니다.</span>

          <LoginForm />
        </div>
      </Card>
    </div>
  )
}

export default SignIn
