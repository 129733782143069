import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import bannerQueryKey from "@/_api/_query-key/banner"
import { AdminBannerListDto, AdminBannerListParams } from "@/_api/banner.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { navigate } from "@/_constants/navigate"
import { useTableSearch } from "@/_hooks/use-table-search"
import { BANNER_DISPLAY_TYPE, BANNER_TYPE } from "./_constants"
import { useGetBannerListQuery } from "./_hooks/use-get-query"
import { useBannerActivateMutation, useBannerDeactivateMutation } from "./_hooks/use-mutation"

const BannerPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminBannerListDto>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminBannerListParams = {
    page,
    page_size: pageSize,
    search,
  }

  const { data: campaignList, totalCount, isLoading } = useGetBannerListQuery(params)

  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: bannerQueryKey.bannerList({}) })
  }
  const onError = (e: any) => {
    console.error(e)
    alert("error!")
  }

  const { mutate: activate } = useBannerActivateMutation({ onError, onSuccess })
  const { mutate: deactivate } = useBannerDeactivateMutation({ onError, onSuccess })
  /**
   * 드롭다운
   */
  const tableDropdownList: TableDropdownList = [
    {
      label: "선택 비활성화",
      fn: ids => {
        deactivate({ banner_ids: ids })
      },
    },
    {
      label: "선택 활성화",
      fn: ids => {
        activate({ banner_ids: ids })
      },
    },
  ]

  return (
    <AdminPageLayout totalCount={totalCount} create>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 제목, 이메일, 내용"
        placeholder="검색어 입력"
      />
      <p className="text-14">
        현재 검색어: <span className="font-bold">{search}</span>
      </p>
      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={campaignList}
        activeCheck
        tableDropdownList={tableDropdownList}
        href={value => {
          return navigate.bannerDetail(value.id)
        }}
        className="min-w-[1600px]"
        title={[
          "ID",
          "상태",
          "우선도",
          "웹 이미지",
          "모바일 이미지",
          "유형",
          "적용화면",
          "작성자",
          "등록일",
          "최종 수정일",
        ]}
        widths={[2, 2, 2, 4, 4, 3, 3, 3, 3, 3]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: TableActivateCurry(),
          우선도: value => value?.order,
          "웹 이미지": value => {
            return (
              <div className="flex items-center justify-center">
                <Image size="375" className="h-auto max-w-[200px]" src={value?.thumbnail_image_url} alt="123" />
              </div>
            )
          },
          "모바일 이미지": value => {
            return (
              <div className="flex items-center justify-center">
                <Image size="375" className="size-10" src={value?.mobile_thumbnail_image_url} />
              </div>
            )
          },
          유형: value => {
            return BANNER_TYPE.find(v => v.value === value.type)?.label || ""
          },
          적용화면: value => BANNER_DISPLAY_TYPE.find(v => v.value === value.display_type)?.label || "",
          작성자: value => value?.user?.email,
          등록일: value => {
            return dayjs(value?.created).format("YY.MM.DD")
          },
          "최종 수정일": value => {
            return dayjs(value?.modified).format("YY.MM.DD")
          },
        }}
      />

      <div className="h-10" />
    </AdminPageLayout>
  )
}

export default BannerPage
