import { useParams } from "react-router-dom"
import { CHAR_BLANK } from "@runners/lib/constants"
import { Badge } from "@runners/ui"
import DetailSection from "@/_components/detail-section"
import Image from "@/_components/image"
import { useGetStoreQuery } from "../_hooks/use-get-query"

const BusinessDetailInfo = () => {
  const { storeId: _storeId } = useParams()
  const storeId = Number(_storeId)
  const { data: storeDetail } = useGetStoreQuery({ storeId })

  if (!storeDetail) return <></>

  return (
    <div className="flex gap-20">
      <DetailSection
        title="사업자 정보"
        values={{
          사업자등록번호: (
            <div className="flex items-center gap-2">
              <Badge color={storeDetail?.business_type === "INDIVIDUAL" ? "green" : "purple"}>
                {storeDetail?.business_type === "INDIVIDUAL" ? "개인" : "법인"}
              </Badge>
              <p className="">{storeDetail?.business_number || "-"}</p>
            </div>
          ),
          "사업자명(상호명)": storeDetail.title,
          "사업의 종류(업태)": storeDetail.industry_type,
          "사업의 종류(종목)": storeDetail.sub_industry_type,
          "사업장 주소": (
            <>
              {storeDetail?.zip_code ?? "-"} <br />
              {storeDetail?.address ?? "-"}, {storeDetail?.detail_address ?? "-"}
            </>
          ),
        }}
      />

      <DetailSection
        title={CHAR_BLANK}
        values={{
          통신판매신고증: (
            <>
              {storeDetail?.telecommunication_retail_image_url && (
                <a
                  target="_blank"
                  className="size-20"
                  href={`${storeDetail?.telecommunication_retail_image_url}?q=2560`}
                >
                  <Image
                    size="2560"
                    className="size-full object-cover"
                    src={storeDetail?.telecommunication_retail_image_url}
                  />
                </a>
              )}

              {!storeDetail?.telecommunication_retail_image_url && "-"}
            </>
          ),
          정산계좌: (
            <>
              {storeDetail?.account_owner_name ?? "-"} <br />
              {storeDetail?.account_number ?? "-"}
            </>
          ),
          통장사본: (
            <>
              {storeDetail?.bank_statement_copy_image_url && (
                <a target="_blank" className="size-20" href={`${storeDetail?.bank_statement_copy_image_url}?q=2560`}>
                  <Image
                    size="2560"
                    className="size-full object-cover"
                    src={storeDetail?.bank_statement_copy_image_url}
                  />
                </a>
              )}

              {!storeDetail?.bank_statement_copy_image_url && "-"}
            </>
          ),
          사업자등록증: (
            <>
              {storeDetail?.business_registration_image_url && (
                <a target="_blank" className="size-20" href={`${storeDetail?.business_registration_image_url}?q=2560`}>
                  <Image
                    size="2560"
                    className="size-full object-cover"
                    src={storeDetail?.business_registration_image_url}
                  />
                </a>
              )}
              {!storeDetail?.business_registration_image_url && "-"}
            </>
          ),
        }}
      />
    </div>
  )
}

export default BusinessDetailInfo
