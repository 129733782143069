import { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { CampaignDisplayType } from "@/_api/campaign.type"
import { AdminProductListDto } from "@/_api/product.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import useToast from "@/_hooks/use-toast"
import CampaignForm, { AdminCampaignFormType } from "../_components/campaign-form"
import { useGetCampaignQuery } from "../_hooks/use-get-query"
import { usePatchCampaignMutation } from "../_hooks/use-mutation"

const AdminCampaignDetailPage = () => {
  const { campaignId: _campaignId } = useParams()

  const campaignId = Number(_campaignId)

  const { data } = useGetCampaignQuery({ campaignId })
  const [isReady, setIsReady] = useState(false)

  const methods = useForm<AdminCampaignFormType>()

  /**
   * init
   */
  useEffect(() => {
    if (!data) return

    // react-select 형식으로 넣기위해 enum에서 label 가져오기
    const displayLabel = Object.keys(CampaignDisplayType).find(
      key => CampaignDisplayType[key as keyof typeof CampaignDisplayType] === data.display_type,
    )

    methods.reset({
      start_date: data.start_date,
      end_date: data.end_date,
      store_title: data.store_title,
      display_type: { label: displayLabel, value: data.display_type },
      thumbnail_image_url: data.thumbnail_image_url,
      mobile_thumbnail_image_url: data.mobile_thumbnail_image_url,

      title: data.title,
      description: data.description,
      seo_thumbnail_image_url: data.seo_thumbnail_image_url,
      detail_images_data: data.detail_images_data,
      // image 형식
      products: data.products.map(v => ({ ...v, image_url: v.thumbnail_image_url })),

      order: `${data.order}`,
      main_order: `${data.main_order}`,
      is_active: data.is_active,
      is_main_display: data.is_main_display,
    })

    setIsReady(true)
  }, [data])

  const toast = useToast()
  const nav = useNavigate()

  const { mutate } = usePatchCampaignMutation({
    onSuccess: () => {
      toast.success("수정 되었습니다.")
      nav(-1)
    },
  })

  const onSubmit: SubmitHandler<AdminCampaignFormType> = data => {
    if (!data.display_type) {
      return toast.warning("유형값이 없습니다")
    }

    const payload = {
      ...data,
      products: data.products.map((v: AdminProductListDto) => v.id),
      order: Number(data.order),
      display_type: data.display_type!.value as CampaignDisplayType,
      main_order: Number(data.main_order),
    }

    mutate({ campaignId: campaignId, payload: payload })
  }

  return (
    <AdminPageLayout onUpdate={methods.handleSubmit(onSubmit)}>
      {isReady && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CampaignForm />
          </form>
        </FormProvider>
      )}
    </AdminPageLayout>
  )
}

export default AdminCampaignDetailPage
