import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MenuItem } from "@/_constants/router-tab-menu"
import { cn } from "@/_styles/cn"
import { ChevronDownIcon } from "@/assets"

interface DepthDropdownProps<T> {
  item: T
  renderIcon: (item: T) => JSX.Element | null
  renderName: (item: T) => string
  renderChildren: (item: T) => T[]
}

const DepthDropdown = <T extends MenuItem>(props: DepthDropdownProps<T>) => {
  const { item, renderIcon, renderName, renderChildren } = props
  const children = renderChildren(item)

  const isParent = children && children.length > 0
  const [isOpen, setIsOpen] = useState(false)

  const nav = useNavigate()
  const { pathname } = useLocation()

  const handleClickMenu = () => {
    if (isParent) {
      setIsOpen(!isOpen)
    } else {
      nav(item.url)
    }
  }
  const isSelected = pathname.includes(item.url)

  return (
    <div className="group my-1">
      <button
        className={cn("flex w-full items-center justify-between rounded-2 px-4 py-3", {
          "text-primary": isSelected,
          "bg-primary-100": isSelected && !isParent,
          "text-gray-700 hover:bg-gray-200": !isSelected,
        })}
        onClick={handleClickMenu}
      >
        <div className="flex w-full items-center text-left text-14">
          {renderIcon(item)}
          <span className="ml-2">{renderName(item)}</span>
        </div>

        {isParent && (
          <ChevronDownIcon
            className={cn("size-4 transition-all duration-300", {
              "transform -scale-y-100": isOpen,
            })}
          />
        )}
      </button>
      {isParent && isOpen && (
        <div className="rounded-2 bg-white">
          {children.map((child, index) => (
            <DepthDropdown
              key={index}
              item={child}
              renderIcon={renderIcon}
              renderName={renderName}
              renderChildren={renderChildren}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default DepthDropdown
