import { AdminUserListParams } from "../user.type"

const userQueryKey = {
  user: () => ["user"],
  me: () => ["user", "me"],

  adminUser: () => ["admin", "user"],
  userList: ({ ...params }: AdminUserListParams) => [...userQueryKey.adminUser(), "list", { ...params }],
  userDetail: ({ id }: { id: string }) => [...userQueryKey.adminUser(), "detail", id],
}

export default userQueryKey
