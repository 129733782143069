import { useQuery } from "@tanstack/react-query"
import userQueryKey from "@/_api/_query-key/user"
import userApi from "@/_api/user"
import { getAccessToken } from "@/_utils/cookie"

const useUserInfo = () => {
  const token = getAccessToken()

  const query = useQuery({
    queryKey: userQueryKey.me(),
    queryFn: () => userApi.getUserMe(),
    select: response => response.data,
    enabled: !!token,
  })

  if (!token)
    return {
      ...query,
      data: null,
    }

  return { ...query }
}

export default useUserInfo
