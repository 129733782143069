import { AdminEventListParams } from "../event.type"

const eventQueryKey = {
  event: () => ["event"],
  adminEvent: () => ["admin", "event"],

  eventList: ({ ...params }: AdminEventListParams) => [...eventQueryKey.adminEvent(), "list", { ...params }],
  userDetail: ({ id }: { id: string }) => [...eventQueryKey.adminEvent(), "detail", id],
}

export default eventQueryKey
