import { DjangoListResponse, DjangoResponse } from "@runners/interface/api/django-response"
import api from "."
import {
  AdminAddressBookResponse,
  AdminAddressBookListParams,
  AdminAddressBookListResponse,
  AdminAddressBookPayload,
  AdminEmailAddressBokksGroupListParams,
  AdminEmailAddressBooksGroupResponse,
} from "./address-books.type"

const addressBooksApi = {
  /**
   * 이메일 수취 그룹 리스트
   */
  getEmailAddressBooksGroupList: async (params: AdminEmailAddressBokksGroupListParams) => {
    const { data } = await api.get<DjangoListResponse<AdminEmailAddressBooksGroupResponse>>(
      `/admin/address-books/group`,
      { params },
    )

    return data
  },

  /**
   * 주소록 리스트
   */
  getEmailAddressBookList: async (params: AdminAddressBookListParams) => {
    const { data } = await api.get<DjangoListResponse<AdminAddressBookListResponse>>(`/admin/address-books`, { params })

    return data
  },

  /**
   * 주소록 등록
   */
  postEmailAddressBook: async (payload: AdminAddressBookPayload) => {
    const { data } = await api.post(`/admin/address-book`, payload)

    return data
  },

  patchEmailAddressBook: async (props: { id: number; payload: AdminAddressBookPayload }) => {
    const { id, payload } = props
    const { data } = await api.patch(`/admin/address-book/${id}`, payload)

    return data
  },

  deleteEmailAddressBook: async (params: { address_book_ids: number[] }) => {
    const { data } = await api.delete(`/admin/address-books`, { params })

    return data
  },

  getEmailAddressBook: async (props: { id: number }) => {
    const { id } = props
    const { data } = await api.get<DjangoResponse<AdminAddressBookResponse>>(`/admin/address-book/${id}`)

    return data
  },
}

export default addressBooksApi
