import { useFormContext } from "react-hook-form"
import { Badge, Switch } from "@runners/ui"
import AdminLabel from "@/_pages/service/post/_components/admin-label"

interface ActiveControlProps {
  name?: string
  label?: string
}

const ActiveControl = (props: ActiveControlProps) => {
  const { name = "is_active", label = "활성화 여부" } = props

  const { register, watch } = useFormContext()
  const isActive = watch(name) || false

  const watchToggle = isActive ? "활성화" : "비활성화"

  return (
    <div className="flex items-center gap-5">
      <AdminLabel required>{label}</AdminLabel>

      <div className="w-full">
        <div className="flex items-center gap-4">
          <Switch size="24" {...register(name)} checked={isActive} />
          <Badge color={isActive ? "primary" : "gray"}>{watchToggle}</Badge>
        </div>
      </div>
    </div>
  )
}

export default ActiveControl
