import { isAxiosError } from "axios"

export const useErrorHandler = () => {
  const getErrorMessage = (error: any) => {
    if (isAxiosError(error)) {
      return error.response?.data?.details?.alerts?.[0] || error.response?.data?.message
    }
  }

  return {
    getErrorMessage,
  }
}
