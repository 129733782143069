/**
 * 추후 추가될 가능성 있음
 */
export enum RecommendProductGroup {
  MAIN_HOME = "MAIN_HOME",
}

export interface PostRecommendProductGroupPayload {
  tag: string
  title: string
  background_image_url: string
  type: RecommendProductGroup
  text_color?: string
  products: { id: number; order: number }[]
}

export type PatchRecommendProductGroupPayload = Partial<PostRecommendProductGroupPayload>
