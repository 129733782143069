import ReactSelect from "react-select"
import { SettlementGroupDto, SettlementStatus } from "@/_api/settlement.type"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import { usePatchSettlementGroupMutation } from "../_hooks/use-mutation"

interface SettlementSelectProps {
  rowData: SettlementGroupDto
}

/**
 * 정산상태를 바꾸는 비고란의 Select 컴포넌트
 */
const SettlementSelect = (props: SettlementSelectProps) => {
  const { rowData } = props

  // 옵션 생성
  const options = Object.values(SettlementStatus).map(status => ({
    value: status,
    label: _getStatusLabel(status),
  }))

  const modalStack = useModalStack()
  const toast = useToast()

  // 정산 그룹 수정 Mutation
  const patchSettlement = usePatchSettlementGroupMutation({
    onSuccess: () => {
      toast.success("정산 상태 변경 완료")
    },
    onError: () => {
      toast.error("정산 상태 변경에 실패했습니다.")
    },
  })

  // 선택 변경 핸들러
  const handleChange = (newValue: { value: SettlementStatus; label: string }) => {
    modalStack.open({
      Component: Alert,
      key: "confirm-alert-patch-settlement",
      disableEsc: true,
      componentProps: {
        content: "정산상태를 바꾸시겠습니까?",
        onConfirm: () => {
          patchSettlement.mutate({
            settlementGroupId: rowData.id,
            payload: { status: newValue.value },
          })
        },
      },
    })
  }

  return (
    <ReactSelect
      options={options}
      defaultValue={{ value: rowData.status, label: _getStatusLabel(rowData.status) }}
      onChange={newValue => {
        if (!newValue) return
        handleChange(newValue)
      }}
      placeholder="정산 상태 선택"
    />
  )
}

export default SettlementSelect

// 상태 라벨을 위한 함수
function _getStatusLabel(status: SettlementStatus) {
  switch (status) {
    case SettlementStatus.PENDING:
      return "정산대기중"
    case SettlementStatus.FAILED:
      return "정산실패"
    case SettlementStatus.COMPLETED:
      return "정산완료"
    default:
      return ""
  }
}
