import { useQuery } from "@tanstack/react-query"
import storeQueryKey from "@/_api/_query-key/store"
import storeApi from "@/_api/store"
import { StoreListParams } from "@/_api/store.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetStoreListQuery = (params: StoreListParams) => {
  return useInfiniteData({
    queryKey: storeQueryKey.storeList(params),
    queryFn: () => storeApi.getStoreList(params),
  })
}

export const useGetStoreQuery = ({ storeId }: { storeId: number }) => {
  return useQuery({
    queryKey: storeQueryKey.storeDetail({ storeId }),
    queryFn: () => storeApi.getStore({ storeId }),
    select: response => response.data,
  })
}
