import dayjs from "dayjs"
import { AdminUserListDto, AdminUserListParams } from "@/_api/user.type"
import AdminTitle from "@/_components/admin-title"
import Image from "@/_components/image"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetUserList } from "./hooks/use-get-query"

/**
 * 회원관리
 */
const UserPage = () => {
  const { page, Table, pageSize } = usePaginationTable<AdminUserListDto>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminUserListParams = {
    page,
    page_size: pageSize,
    search,
  }
  const { data: userList, totalCount, isLoading } = useGetUserList(params)

  return (
    <div className="">
      <AdminTitle count={totalCount} />
      <div className="h-10" />
      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">
        <SearchInput
          className="max-w-[500px]"
          label="검색 필터"
          description="가능한 필드: 이메일, 아이디, 닉네임, 휴대폰"
          placeholder="검색어 입력"
        />
        <p className="text-14">
          현재 검색어: <span className="font-bold">{search}</span>
        </p>
        <Table
          isLoading={isLoading}
          totalCount={totalCount}
          data={userList}
          className="min-w-[1600px]"
          title={[
            "ID",
            "구분",
            "이미지",
            "이름",
            "성별",
            "생년월일",
            "이메일",
            "휴대폰 번호",
            "생성한 이벤트",
            "가입일",
            "마지막 접속일",
            "마케팅 동의",
            "상태",
          ]}
          widths={[2, 2, 2, 4, 2, 3, 6, 4, 4, 3, 4, 3, 2]}
          values={{
            ID: value => {
              return value?.id
            },
            구분: value => {
              return value?.role
            },
            이미지: value => {
              if (value?.profile_image_url == null) return "-"
              return (
                <div className="flex items-center justify-center">
                  <Image size="375" className="size-8 rounded-full" src={value?.profile_image_url} />
                </div>
              )
            },
            이름: value => {
              return value?.username
            },
            성별: value => {
              if (value?.gender == null) return "-"
              return value?.gender === "MALE" ? "남자" : "여자"
            },
            생년월일: value => {
              if (value?.birthday == null) return "-"
              return dayjs(value?.birthday).format("YYYY.MM.DD")
            },
            이메일: value => {
              return value?.email
            },
            "휴대폰 번호": value => {
              return value?.phone
            },
            "생성한 이벤트": value => {
              return value?.event_count
            },
            가입일: value => {
              return dayjs(value?.created).format("YY.MM.DD")
            },
            "마지막 접속일": value => {
              return dayjs(value?.last_login).format("YY.MM.DD")
            },
            "마케팅 동의": value => {
              return value?.is_marketing_agreement ? "동의" : "비동의"
            },
            상태: value => {
              return value?.is_signup ? "회원" : "탈퇴"
            },
          }}
        />

        <div className="h-10" />
      </div>
    </div>
  )
}

export default UserPage
