import dayjs from "dayjs"
import { AdminEventListParams } from "@/_api/event.type"
import AdminTitle from "@/_components/admin-title"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetEventList } from "./hooks/use-get-query"

interface EventPageProps {}

const EventPage = (props: EventPageProps) => {
  const {} = props

  // TODO: any
  const { Table, page, pageSize } = usePaginationTable<any>()
  const { SearchInput, search } = useTableSearch()

  const params: AdminEventListParams = {
    page,
    page_size: pageSize,
    search,
  }

  const { data: eventList, totalCount, isLoading } = useGetEventList(params)

  return (
    <div className="">
      <AdminTitle count={totalCount} />
      <div className="h-10" />
      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">
        <SearchInput
          className="max-w-[500px]"
          label="검색 필터"
          description="가능한 필드: 이메일, 아이디, 닉네임, 휴대폰"
          placeholder="검색어 입력"
        />
        <p className="text-14">
          현재 검색어: <span className="font-bold">{search}</span>
        </p>
        <Table
          totalCount={totalCount}
          isLoading={isLoading}
          data={eventList}
          className="min-w-[1600px]"
          title={["ID", "유형", "대상", "종류", "이벤트명", "멤버", "날짜", "지역", "생성일"]}
          widths={[2, 3, 3, 5, 5, 5, 4, 4, 3]}
          values={{
            ID: value => {
              return value?.id
            },
            유형: value => {
              return (
                <div className="flex items-center justify-center">
                  <div
                    style={{
                      background: value?.event_category?.background_color,
                      color: value?.event_category?.text_color,
                    }}
                    className="w-fit items-center px-2"
                  >
                    {value?.event_category?.title}
                  </div>
                </div>
              )
            },
            대상: value => {
              return value?.contacts?.map((v: { id: number; name: string }) => v?.name)?.join(", ")
            },
            종류: value => {
              return value.recommend_categories?.map((v: any) => v.title)?.join(", ")
            },
            이벤트명: value => {
              return value?.title
            },
            멤버: value => {
              return value?.members?.length || 0
            },
            날짜: value => {
              return dayjs(value?.date).format("YY.MM.DD")
            },
            지역: value => {
              return value?.place?.address
            },
            생성일: value => {
              return dayjs(value?.created).format("YY.MM.DD")
            },
          }}
        />

        <div className="h-10" />
      </div>
    </div>
  )
}

export default EventPage
