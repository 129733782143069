import { useQueryClient } from "@tanstack/react-query"

export const useInvalidate = () => {
  const queryClient = useQueryClient()

  const invalidate = async (queryKey: any[]) => {
    await queryClient.invalidateQueries({ queryKey })
  }

  return { invalidate }
}
