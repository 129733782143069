import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Badge, Button, Input } from "@runners/ui"
import DetailSection from "@/_components/detail-section"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import { useGetStoreQuery } from "../_hooks/use-get-query"
import { useUpdateStoreMutation } from "../_hooks/use-mutation"
import { SellerDetailFormType } from "../detail/admin-seller-detail-page"

const defaultValue = {
  title: "",
  owner_name: "",
  business_number: "",
  detail_address: "",
  phone: "",
  store_link: "",
}

const BusinessFormInfo = () => {
  // BusinessFormInfo 컴포넌트에서 사용되는 state (react-hook-form 대용)
  const [formInputs, setFormInputs] = useState<SellerDetailFormType>(defaultValue)
  const [resetFields, setResetFields] = useState(defaultValue)

  const { storeId: _storeId } = useParams()
  const storeId = Number(_storeId)
  const { data: storeDetail } = useGetStoreQuery({ storeId })

  useEffect(() => {
    if (!storeDetail) return
    const initialValues = {
      title: storeDetail.title,
      owner_name: storeDetail.owner_name,
      business_number: storeDetail.business_number,
      detail_address: storeDetail.detail_address,
      phone: storeDetail.phone,
      store_link: storeDetail?.store_link,
    }
    setFormInputs(initialValues)
    setResetFields(initialValues)
  }, [storeDetail])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormInputs({ ...formInputs, [name]: value })
  }

  const onReset = () => setFormInputs(resetFields)

  const modalStack = useModalStack()
  const toast = useToast()

  const { mutate } = useUpdateStoreMutation({
    onSuccess: () => {
      toast.success("변경되었습니다.")
    },
    onError: () => {
      toast.error("변경 편시하시겠습니까?")
    },
  })

  const onSubmit = () => {
    modalStack.open({
      Component: Alert,
      key: "patch-store-detail",
      componentProps: {
        title: "변경하시겠습니까?",
        onConfirm: () => {
          mutate({ storeId, payload: formInputs })
        },
      },
    })
  }

  if (!storeDetail) return <></>

  return (
    <div>
      <DetailSection
        title="스토어 정보"
        values={{
          아이디: storeDetail.id,
          "상호(인풋)": (
            <Input
              autoComplete="off"
              className="min-w-[400px]"
              name="title"
              value={formInputs.title}
              onChange={handleChange}
            />
          ),
          "사업자 형태": (
            <Badge color={storeDetail?.business_type === "INDIVIDUAL" ? "green" : "purple"}>
              {storeDetail?.business_type === "INDIVIDUAL" ? "개인사업자" : "법인사업자"}
            </Badge>
          ),
          "대표자 이름(인풋)": (
            <Input
              autoComplete="off"
              className="min-w-[400px]"
              name="owner_name"
              value={formInputs.owner_name}
              onChange={handleChange}
            />
          ),
          "사업장 주소": storeDetail.address,
          "상세주소(인풋)": (
            <Input
              autoComplete="off"
              className="min-w-[400px]"
              name="detail_address"
              value={formInputs.detail_address}
              onChange={handleChange}
            />
          ),
          "연락처(인풋)": (
            <Input
              autoComplete="off"
              className="min-w-[400px]"
              name="phone"
              value={formInputs.phone}
              onChange={handleChange}
            />
          ),
          업태: storeDetail.industry_type,
          종목: storeDetail.sub_industry_type,
          홈페이지: (
            <Input
              autoComplete="off"
              className="min-w-[400px]"
              name="store_link"
              value={formInputs.store_link}
              onChange={handleChange}
            />
          ),
        }}
      />
      <div className="h-6" />

      <div className="flex min-w-[600px] max-w-[900px] items-center justify-end gap-4">
        <Button color="gray" className="px-6" size="40" onClick={onReset}>
          초기화
        </Button>
        <Button color="primary" className="px-6" size="40" onClick={onSubmit}>
          저장
        </Button>
      </div>
    </div>
  )
}

export default BusinessFormInfo
