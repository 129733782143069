/**
 * 정산 상태
 */
export enum SettlementStatus {
  /** 정산대기중 */
  PENDING = "PENDING",
  /** 정산실패 */
  FAILED = "FAILED",
  /** 정산완료 */
  COMPLETED = "COMPLETED",
}

export interface SettlementStoreDto {
  id: number
  title: string
  commission_info: string
  seller_email: string | null
  seller_phone: string | null
}

export interface SettlementGroupDto {
  id: number
  store: SettlementStoreDto
  count: number
  total_settlement_amount: number
  status: SettlementStatus
  expected_date: string | null
}
