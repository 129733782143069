import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "@runners/ui"
import { TAB_MENU, findMenuItemByUrl } from "@/_constants/router-tab-menu"

interface AdminTitleProps {
  count: number
  label?: string

  create?: boolean
  onDelete?: () => void
  onUpdate?: () => void
}

/**
 * 생성 페이지를 제외한 모든 페이지에서 사용되는 공용 컴포넌트 admin-page-layout 의 조각 컴포넌트
 */
const AdminTitle = (props: AdminTitleProps) => {
  const { count, label, onDelete, onUpdate, create = false } = props

  const nav = useNavigate()
  const { pathname } = useLocation()
  const isDetailPage = pathname.split("/").length > 3
  const title = isDetailPage ? "상세페이지" : findMenuItemByUrl(TAB_MENU, pathname)?.name || "관리"

  /**
   * 리스트 페이지에서는 생성 라우트 이동,
   * 상세페이지에서는 삭제 함수 실행
   */
  const handleButtonClick = () => {
    if (isDetailPage) {
      onDelete?.()
    } else {
      nav("create")
    }
  }

  return (
    <div className="bg-gray-200 p-6">
      <p>breadcrumb</p>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h1 className="text-30 font-bold">{title}</h1>

          {count > 0 && (
            <span className="text-20 text-gray-600">
              총 {count}
              {label || "개"}
            </span>
          )}
        </div>

        <div className="flex items-center gap-2">
          {onDelete && (
            <Button className="px-5" color={isDetailPage ? "dark" : "primary"} size="40" onClick={handleButtonClick}>
              삭제
            </Button>
          )}

          {onUpdate && (
            <Button className="px-5" color="primary" size="40" onClick={onUpdate}>
              저장
            </Button>
          )}

          {create && (
            <Button className="px-5" color={isDetailPage ? "dark" : "primary"} size="40" onClick={handleButtonClick}>
              등록
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminTitle
