/** ------------------------------------------------------------------------------
 * 
 * 센스 유저정보 password 를 SHA-256 알고리즘으로 hash하는 함수
 * 
 ------------------------------------------------------------------------------ */
export const hashPassword = async (password: string) => {
  // window에 crypto.subtle 객체가 없다면 평문으로 리턴
  if (!globalThis.window?.crypto?.subtle) {
    console.warn(`crypto is not defined!`)
    return password
  }

  // 비밀번호를 UTF-8 문자열로 인코딩
  const encoder = new TextEncoder()
  const data = encoder.encode(password)
  // const data = encoder.encode(password + PASSWORD_SALT)

  // SHA-256 알고리즘을 사용하여 데이터를 해시
  const hash = await window.crypto.subtle.digest("SHA-256", data)

  // 해시된 데이터를 16진수 문자열로 변환
  const hashArray = Array.from(new Uint8Array(hash))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("")

  return hashHex
}
