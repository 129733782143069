import { DjangoSearchOrderingParams } from "@runners/interface/api/django-request"

const productGroupQueryKey = {
  productGroup: () => ["productGroup"],
  adminProductGroup: () => ["admin", "productGroup"],

  productGroupList: (params: DjangoSearchOrderingParams) => [
    ...productGroupQueryKey.adminProductGroup(),
    "list",
    params,
  ],
  detail: ({ productGroupId }: { productGroupId: number }) => [
    ...productGroupQueryKey.adminProductGroup(),
    "detail",
    productGroupId,
  ],
}

export default productGroupQueryKey
