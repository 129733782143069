import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { CampaignDisplayType } from "@/_api/campaign.type"
import { AdminProductListDto } from "@/_api/product.type"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import CampaignForm, { AdminCampaignFormType } from "../_components/campaign-form"
import { usePostCampaignMutation } from "../_hooks/use-mutation"

const AdminCampaignCreatePage = () => {
  const methods = useForm<AdminCampaignFormType>()

  const toast = useToast()
  const nav = useNavigate()
  const { mutate } = usePostCampaignMutation({
    onSuccess: () => {
      toast.success("등록되었습니다.")
      nav(-1)
    },
    onError: e => {
      console.error("error:", e)
      toast.error("에러!")
    },
  })

  const onSubmit: SubmitHandler<AdminCampaignFormType> = data => {
    // eslint-disable-next-line no-console
    console.log(`submit`, data)

    if (!data.display_type) {
      return toast.warning("유형값이 없습니다")
    }

    const payload = {
      ...data,
      products: data.products.map((v: AdminProductListDto) => v.id),
      order: Number(data.order),
      main_order: Number(data.main_order),
      display_type: data.display_type!.value as CampaignDisplayType,
    }

    mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        {/* <div className="flex items-center justify-end"></div> */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CampaignForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminCampaignCreatePage
