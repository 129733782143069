import { useMutation } from "@tanstack/react-query"
import recommendProductApi from "@/_api/recommend-product"
import { MutationOptionsType } from "@/_types/react-query.type"

export const usePostRecommendProductGroupMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: recommendProductApi.postRecommendProductGroup,
    ...options,
  })
}

export const usePatchRecommendProductGroupMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: recommendProductApi.patchRecommendProductGroup,
    ...options,
  })
}
