import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import ReactSelect from "react-select"
import { Button, Input } from "@runners/ui"
import { AdminProductListDto } from "@/_api/product.type"
import { RecommendProductGroup } from "@/_api/recommend-product.type"
import AdminFormField from "@/_components/admin-form-field"
import Divider from "@/_components/layout/divider"
import ActiveControl from "@/_components/react-hook-form/active-control"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"
import AdminCampaignProducts from "../../campaign/_components/fields/admin-campaign-products"

type ItemProps = {
  label: string
  value: RecommendProductGroup
}

export type RecommendProductFormType = {
  tag: string
  title: string
  background_image_url: string
  type: ItemProps
  products: AdminProductListDto[]
  is_active: boolean
  text_color?: string
}

const RecommendProductForm = () => {
  const { register } = useFormContext()

  const { recommendProductId } = useParams()
  const { text_color } = useWatch()

  return (
    <div className="flex flex-col gap-4">
      <AdminFormField label="아이디">
        <p>{recommendProductId ?? "-"}</p>
      </AdminFormField>

      <AdminFormField label="태그" required>
        <Input
          {...register("tag")}
          style={{
            color: text_color || "#000",
          }}
          placeholder="#태그"
        />
        <Input {...register("text_color")} type="color" />
        {!recommendProductId && <span className="text-12">#은 직접 넣어야합니당</span>}
      </AdminFormField>

      <AdminFormField label="타이틀" required>
        <Input {...register("title")} placeholder="포장까지 예쁜 선물" />
      </AdminFormField>

      <ThumbnailInput className="" name="background_image_url" label="배경 이미지" />

      <AdminFormField label="유형" required>
        <Controller
          name="type"
          render={({ field }) => (
            <ReactSelect {...field} className="min-w-[400px]" options={[{ label: "MAIN_HOME", value: "MAIN_HOME" }]} />
          )}
        />
      </AdminFormField>

      <Divider />

      <AdminCampaignProducts type="table" isRanking />
      <Divider />

      <ActiveControl />

      <div className="mt-10 flex items-center justify-end">
        <Button type="submit" size="48" className="flex-1">
          등록
        </Button>
      </div>
    </div>
  )
}

export default RecommendProductForm
