import { useMutation } from "@tanstack/react-query"
import postApi from "@/_api/post"
import { MutationOptionsType } from "@/_types/react-query.type"

export const usePostActivateMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: postApi.postPostActivate,
    ...options,
  })
}

export const usePostDeactivateMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: postApi.postPostDeactivate,
    ...options,
  })
}

export const usePostPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: postApi.postPost,
    ...options,
  })
}

export const usePatchPostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: postApi.patchPost,
    ...options,
  })
}

export const useDeletePostMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: postApi.deletePost,
    ...options,
  })
}
