import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import rankingPostQueryKey from "@/_api/_query-key/ranking-post"
import { AdminPostListParams } from "@/_api/post.type"
import { RankingPostListDto } from "@/_api/ranking-post.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableActivateCurry } from "@/_components/table/table-activate"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetRankingPostListQuery } from "@/_pages/service/ranking-post/_hooks/use-get-query"
import {
  usePostActivateRankingPostMutation,
  usePostDeactivateRankingPostMutation,
} from "@/_pages/service/ranking-post/_hooks/use-mutation"

const AdminRankingPostPage = () => {
  const { Table, page, pageSize } = usePaginationTable<RankingPostListDto>()
  const { SearchInput, search } = useTableSearch()

  /**
   * 선택 활성화/비활성화
   */
  const queryClient = useQueryClient()
  const { mutate: activate } = usePostActivateRankingPostMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: rankingPostQueryKey.rankingPostList.all() })
    },
  })
  const { mutate: deactivate } = usePostDeactivateRankingPostMutation({
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: rankingPostQueryKey.rankingPostList.all() })
    },
  })

  const tableDropdownList: TableDropdownList = [
    {
      label: "선택 비활성화",
      fn: ids => {
        deactivate({ ranking_post_ids: ids })
      },
    },
    {
      label: "선택 활성화",
      fn: ids => {
        activate({ ranking_post_ids: ids })
      },
    },
  ]

  /**
   * 테이블 그리기 위한 data fetch
   */
  const params: AdminPostListParams = {
    page,
    page_size: pageSize,
    search,
  }

  const { data: postList, totalCount, isLoading } = useGetRankingPostListQuery(params)

  return (
    <AdminPageLayout totalCount={totalCount} create>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 제목"
        placeholder="검색어 입력"
      />
      <p className="text-14">
        현재 검색어: <span className="font-bold">{search}</span>
      </p>
      <Table
        isLoading={isLoading}
        totalCount={totalCount}
        data={postList}
        activeCheck
        href={value => {
          return `/service/ranking-post/${value?.id}`
        }}
        className="min-w-[1600px]"
        title={["아이디", "섬네일", "타이틀", "태그", "수정일", "상태"]}
        widths={[1, 2, 3, 4, 2, 1]}
        values={{
          아이디: value => {
            return value?.id
          },
          섬네일: value => {
            return (
              <div className="flex items-center justify-center">
                <Image size="375" className="h-10 w-32 rounded-1 object-cover" src={value?.thumbnail_media_url} />
              </div>
            )
          },
          타이틀: value => {
            return (
              <div>
                <p className="text-14">{value?.title}</p>
              </div>
            )
          },
          태그: value => {
            return value?.tag_titles
          },
          수정일: value => {
            return dayjs(value?.modified).format("YY.MM.DD")
          },
          상태: TableActivateCurry(),
        }}
        tableDropdownList={tableDropdownList}
      />

      <div className="h-10" />
    </AdminPageLayout>
  )
}

export default AdminRankingPostPage
