import { create } from "zustand"

/**
 * 렌더링 포지션
 */
export const POPUP_POSITION = {
  BOTTOM_CENTER: "BOTTOM_CENTER",
  TOP_CENTER: "TOP_CENTER",
  BOTTOM_LEFT: "BOTTOM_LEFT",
  TOP_LEFT: "TOP_LEFT",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
  TOP_RIGHT: "TOP_RIGHT",
}

type WithModalStackCloseReturn<T> = (props: T & { close: () => void | Promise<void> }) => JSX.Element | null

type RelativePortalOptions = {
  position?: keyof typeof POPUP_POSITION
  gap?: number
  clickOutsideContainTarget?: boolean // close 조건에 해당 타겟을 포함시킬것인지
}

export const defaultOptions = {
  position: POPUP_POSITION.BOTTOM_CENTER as keyof typeof POPUP_POSITION,
  gap: 4,
  clickOutsideContainTarget: true,
}

export interface RelativePortalState<T> {
  Component: WithModalStackCloseReturn<T> | null
  componentProps: T
  targetRef: HTMLElement | null
  options?: RelativePortalOptions
}

export interface RelativePortalStore<T> {
  Component: WithModalStackCloseReturn<T> | null
  componentProps: T
  targetRef: HTMLElement | null
  options: RelativePortalOptions
  open: <T>(args: RelativePortalState<T>) => void
  curryOpen: <T>(
    args: Omit<RelativePortalState<T>, "targetRef">,
  ) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  clear: () => void
  updateProps: (props: T) => void
}

const _curry = <T>(fn: (args: RelativePortalState<T>) => void) => {
  return (args: Omit<RelativePortalState<T>, "targetRef">) => {
    return (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      return fn({ ...args, targetRef: e.currentTarget })
    }
  }
}

export const relativePortalStore = create<RelativePortalStore<any>>(set => ({
  Component: null,
  componentProps: {},
  targetRef: null,
  options: defaultOptions,
  open: (args: RelativePortalState<any>) => {
    set({
      targetRef: args.targetRef,
      Component: args.Component,
      componentProps: args.componentProps,
      options: { ...defaultOptions, ...args.options },
    })
  },
  curryOpen: _curry(<T>(args: RelativePortalState<T>) => {
    set({
      targetRef: args.targetRef,
      Component: args.Component,
      componentProps: args.componentProps,
      options: { ...defaultOptions, ...args.options },
    })
  }),
  clear: () => set({ targetRef: null, Component: null, componentProps: {}, options: defaultOptions }),
  updateProps: props => set(() => ({ componentProps: props })),
}))

/**
 * RelativePortal 컴포넌트에서 사용되는 훅
 */
export const useRelativePortalState = () =>
  relativePortalStore(state => ({
    targetRef: state.targetRef,
    Component: state.Component,
    componentProps: state.componentProps,
    clear: state.clear,
    options: state.options,
  }))

/**
 * 실제 컴포넌트단에서 사용되는 훅
 */
export const useRelativeModal = () =>
  relativePortalStore(state => ({
    open: state.open,
    curryOpen: state.curryOpen,
    clear: state.clear,
    updateProps: state.updateProps,
  }))
