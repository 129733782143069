import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Input, TagChip } from "@runners/ui"
import { useKeydown } from "@/_hooks/use-keydown"

const AdminPostTags = () => {
  const { setValue, watch } = useFormContext()
  const tags: string[] = watch("tags") || []

  const [tagInput, setTagInput] = useState("")

  const { onKeydownForTag } = useKeydown({
    onSubmit: () => {
      const newTag = tagInput.trim()
      if (newTag === "") return

      const findExist = tags.some(t => t === tagInput)
      if (findExist) return

      setValue("tags", [...tags, tagInput])
      clear()
    },

    onCancel: () => {
      clear()
    },
  })

  const clear = () => {
    setTagInput("")
  }

  const removeTag = (index: number) => {
    const newTags = [...tags]
    newTags.splice(index, 1)
    setValue("tags", newTags)
  }

  return (
    <div className="flex items-center gap-5">
      <p className="w-[240px] shrink-0 text-14 font-bold">태그</p>

      <div className="">
        <div className="flex flex-wrap gap-2">
          {tags.length === 0 && <div className="h-8" />}

          {tags.map((v, i) => {
            return (
              <TagChip key={v} size="32" color="dark" className="" onClick={() => removeTag(i)}>
                {v}
              </TagChip>
            )
          })}
        </div>

        <div className="mt-4 flex w-full">
          <Input
            className="flex-1"
            placeholder="여기에 태그 입력"
            value={tagInput}
            onChange={e => setTagInput(e.target.value)}
            onKeyDown={onKeydownForTag}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminPostTags
