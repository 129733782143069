/**
 * 배너의 타입은 백엔드기준 7종류가 있습니다.
 */
export enum BannerType {
  NOTICE = "NOTICE",
  EVENT = "EVENT",
  POST = "POST",
  STORY = "STORY",
  PRODUCT = "PRODUCT",
  ADVERTISEMENT = "ADVERTISEMENT",
  ETC = "ETC",
}

/**
 * 배너의 디스플레이 타입은 백엔드 기준 3가지가 있습니다.
 */
export enum BannerDisplayType {
  POST = "POST",
  SHOPPING = "SHOPPING",
  STORE = "STORE",
}

/**
 * 백엔드 배너 타입에 따른 label 이 추가된 객체배열
 */
export const BANNER_TYPE = [
  { label: "광고", value: BannerType.ADVERTISEMENT },
  { label: "게시글", value: BannerType.POST },
  { label: "이벤트", value: BannerType.EVENT },
  { label: "공지", value: BannerType.NOTICE },
  { label: "상품", value: BannerType.PRODUCT },
  { label: "스토리", value: BannerType.STORY },
  { label: "기타", value: BannerType.ETC },
]

/**
 * 백엔드 디스플레이 타입에 따른 label 추가된 객체배열
 */
export const BANNER_DISPLAY_TYPE = [
  { label: "게시글", value: BannerDisplayType.POST },
  { label: "쇼핑", value: BannerDisplayType.SHOPPING },
  { label: "스토어", value: BannerDisplayType.STORE },
]
