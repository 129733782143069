import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import useToast from "@/_hooks/use-toast"
import ProductGroupForm from "../_components/product-group-form"
import { usePostProductGroupMutation } from "../_hooks/use-mutation"

const AdminProductGroupCreatePage = () => {
  const methods = useForm()

  const toast = useToast()
  const { mutate } = usePostProductGroupMutation({
    onSuccess: () => {
      toast.success("등록되었습니다.")
      history.back()
    },
  })

  const onSubmit: SubmitHandler<any> = data => {
    // eslint-disable-next-line no-console
    console.log(`data`, data)

    const payload = {
      ...data,
      product_ids: (data.products || []).map((v: any) => v.id),
      order: Number(data.order) || 0,
    }

    mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
          <ProductGroupForm />
        </AdminCreateLayout>
      </form>
    </FormProvider>
  )
}

export default AdminProductGroupCreatePage
