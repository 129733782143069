import { useFormContext } from "react-hook-form"
import { CampaignDisplayType } from "@/_api/campaign.type"
import ThumbnailInput from "@/_components/react-hook-form/thumbnail-input"
import { cn } from "@/_styles/cn"

/**
 * 유형에 따라 이미지 비율이 바뀜!
 */
const AdminCampaignThumbnails = () => {
  const { watch } = useFormContext()

  const currentType = watch("display_type")

  if (!currentType) return <>유형 선택시 열림</>

  const isB01 = currentType.value === CampaignDisplayType["쇼핑-메인-B-01"]

  return (
    <div className="flex gap-10">
      <ThumbnailInput
        name="thumbnail_image_url"
        label="웹 이미지"
        className={cn({
          "w-[352px] h-[140px]": isB01,
          "w-[241px] h-[320px]": !isB01,
        })}
      />
      <ThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        className={cn({
          "w-[280px] h-[100px]": isB01,
          "w-[200px] h-[280px]": !isB01,
        })}
      />
    </div>
  )
}

export default AdminCampaignThumbnails
