import { useQuery } from "@tanstack/react-query"
import emailQueryKey from "@/_api/_query-key/email"
import { emailApi } from "@/_api/email"
import { AdminEmailTemplateListParams } from "@/_api/email.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetTemplateList = (params: AdminEmailTemplateListParams) =>
  useInfiniteData({
    queryKey: emailQueryKey.emailTemplateList.params(params),
    queryFn: ({ pageParam = 1 }) => emailApi.getEmailTemplateList({ page: pageParam, ...params }),
  })

export const useGetTemplate = (id: number) =>
  useQuery({
    queryKey: emailQueryKey.emailTemplateDetail.id(id),
    queryFn: () => emailApi.getEmailTemplateDetail(id),
    select: response => response.data,
  })
