import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Input } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import emailQueryKey from "@/_api/_query-key/email"
import { AdminEmailTemplatePayload } from "@/_api/email.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { navigate } from "@/_constants/navigate"
import EmailEditorComponent from "../_components/email-editor-component"
import { useGetTemplate } from "../_hooks/queries"
import { usePatchEmailTemplate } from "../_hooks/template-mutations"

const AdminEmailTemplateDetailPage = () => {
  const { templateId: _templateId } = useParams()
  const templateId = Number(_templateId)

  const { data: template } = useGetTemplate(templateId)
  const { mutate: editTemplate } = usePatchEmailTemplate()

  const methods = useForm()

  const { register, setValue, handleSubmit } = methods

  const qc = useQueryClient()
  const nav = useNavigate()

  const onSubmit = (payload: FieldValues) => {
    editTemplate(
      { id: templateId, payload: payload as AdminEmailTemplatePayload },
      {
        onSuccess: () => {
          qc.invalidateQueries({ queryKey: emailQueryKey.emailTemplateList.all() })
          qc.invalidateQueries({ queryKey: emailQueryKey.emailTemplateDetail.id(templateId) })
          nav(navigate.template())
        },
      },
    )
  }

  const handleEditorChange = (data: string) => {
    setValue("content", data)
  }

  if (!template) return null

  return (
    <FormProvider {...methods}>
      <div className="bg-gray-100">
        <AdminPageLayout onUpdate={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center">
            <div className="mb-4 w-full max-w-[720px]">
              <p className="mb-2 text-20 font-bold">템플릿 이름</p>
              <Input
                className="w-full"
                defaultValue={template.title}
                {...register("title")}
                placeholder="템플릿 이름을 입력해 주세요."
              />
            </div>
            <EmailEditorComponent onChangeEditor={handleEditorChange} initialValues={template.content} />
          </div>
        </AdminPageLayout>
      </div>
    </FormProvider>
  )
}

export default AdminEmailTemplateDetailPage
