import withModalStackProps from "@/_hoc/with-modal-stack-props"
import { CloseIcon } from "@/assets"

interface ModalHeaderProps {
  title: string
}

const ModalHeader = withModalStackProps<ModalHeaderProps>(({ close, title }) => {
  return (
    <>
      <div className="flex items-center justify-between p-4">
        <p className="text-24 font-bold">{title}</p>
        <CloseIcon className="size-6 hover:cursor-pointer" onClick={close} />
      </div>
      <hr />
    </>
  )
})

export default ModalHeader
