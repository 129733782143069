export const parseErrorDetails = (details: { [key: string]: string }): { [key: string]: string | undefined } => {
  const regex = /string='([^']+)'/

  const parsedDetails: { [key: string]: string | undefined } = {}

  for (const key in details) {
    if (details.hasOwnProperty(key)) {
      const match = details[key].match(regex)
      parsedDetails[key] = match ? match[1] : undefined
    }
  }

  return parsedDetails
}
