import { cn } from "@/_styles/cn"
import { InstagramIcon, SenseLogo, YoutubeIcon } from "@/assets"

interface FooterProps {}

const Divider = () => {
  return <div className="h-3 w-px bg-gray-300" />
}

const Footer = (props: FooterProps) => {
  const {} = props
  return (
    // Gitbook Layer
    <div className="">
      <div className="mx-auto box-border flex items-center justify-center gap-6 border-t border-gray-300 py-2 text-gray-700">
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">회사소개</p>
        <Divider />
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">서비스 소개</p>
        <Divider />
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">개인정보 처리방침</p>
        <Divider />
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">이용약관</p>
        <Divider />
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">환불정책</p>
        <Divider />
        <p className="cursor-pointer py-[3px] text-12 hover:text-gray-500">제휴/입점문의</p>
      </div>

      {/* footer */}
      <div className={cn("flex items-center justify-center gap-6 border-t border-gray-300 py-4")}>
        <div className="flex max-w-5xl flex-1 items-center">
          <SenseLogo className="h-[38px] self-start text-gray-300" />

          <div className="w-8" />

          <div className="flex flex-1 flex-col gap-1.5">
            <div className="flex gap-[7px] text-gray-500">
              <p className="text-12">주식회사 러너스</p>
              <Divider />
              <p className="text-12">대표 : 김선욱</p>
              <Divider />
              <p className="text-12">사업자등록번호 : 840-81-03234</p>
              <Divider />
              <p className="text-12">통신판매업 신고번호 : 2023-성남분당A-1153</p>
              <Divider />
              <p className="text-12">ⓒ2024 Runners, Inc</p>
            </div>

            <div className="flex gap-[7px] text-gray-500">
              <p className="text-12">대표전화 : 070-7178-8777</p>
              <Divider />
              <p className="text-12">사업장 주소 : 경기도 성남시 분당구 판교역로192번길 16 (삼평동) 8층 806호</p>
              <Divider />
              <p className="text-12">이메일 : support@runners.im</p>
            </div>
          </div>

          <div className="w-6" />

          <div className="flex shrink-0 items-center gap-3">
            <div className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 p-1.5">
              <InstagramIcon className="text-white" />
            </div>

            <div className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 p-1.5">
              <YoutubeIcon className="text-white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
